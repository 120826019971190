var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c(
        "b-field",
        { attrs: { label: "שאלה" } },
        [
          _c("ckeditor", {
            attrs: { editor: _vm.editor, config: _vm.editorConfig },
            on: { input: _vm.onChange },
            model: {
              value: _vm.model.question,
              callback: function($$v) {
                _vm.$set(_vm.model, "question", $$v)
              },
              expression: "model.question"
            }
          })
        ],
        1
      ),
      _c("FieldInlineText", {
        attrs: { label: "הערות עורך" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.editorNotes,
          callback: function($$v) {
            _vm.$set(_vm.model, "editorNotes", $$v)
          },
          expression: "model.editorNotes"
        }
      }),
      _c(
        "b-checkbox",
        {
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.isDelta,
            callback: function($$v) {
              _vm.$set(_vm.model, "isDelta", $$v)
            },
            expression: "model.isDelta"
          }
        },
        [_vm._v("דלתא ")]
      ),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-checkbox",
            {
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.isInExam,
                callback: function($$v) {
                  _vm.$set(_vm.model, "isInExam", $$v)
                },
                expression: "model.isInExam"
              }
            },
            [_vm._v("שאלת אמת ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }