import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/admin',
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
    redirect: '/admin/profiles',
    children: [
      {
        path: '/admin/profiles',
        name: 'AdminCourses',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminCourses.vue'),
      },
      {
        path: '/admin/users',
        name: 'AdminUsers',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUsers.vue'),
      },
      {
        path: '/admin/questionsbank',
        name: 'AdminQuestionsBank',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminQuestionsBank.vue'),
      },
      {
        path: '/admin/videosbank',
        name: 'AdminVideosBank',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVideosBank.vue'),
      },
      {
        path: '/admin/booksbank',
        name: 'AdminBooksBank',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminBooksBank.vue'),
      },
      {
        path: '/admin/coupons',
        name: 'AdminCoupons',
        component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Coupons.vue'),
      },
      {
        path: '/admin/action/updateexerciselaws',
        name: 'AdminUpdateExerciseLaws',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUpdateExerciseLaws.vue'),
      },
      {
        path: '/admin/action/updateVideoContent',
        name: 'AdminUpdateVideoContent',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUpdateVideoContent.vue'),
      },
      {
        path: '/admin/action/updatelawcollections',
        name: 'AdminUpdateLawCollections',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUpdateLawCollections.vue'),
      },
      {
        path: '/admin/action/unitReplication',
        name: 'AdminUnitReplication',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUnitReplication.vue'),
      },
      {
        path: '/admin/action/verdictReplication',
        name: 'AdminVerdictReplication',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVerdictReplication.vue'),
      },
      {
        path: '/admin/action/createAnswersFiles',
        name: 'AdminCreateAnswersFiles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminCreateAnswersFiles.vue'),
      },
      {
        path: '/admin/action/verdictscollections',
        name: 'AdminVerdictsCollections',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVerdictsCollections.vue'),
      },
      {
        path: '/admin/action/manageLawsCollections',
        name: 'AdminManageLawsCollections',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminManageLawsCollections.vue'),
      },
    ],
  },
  {
    path: '/users',
    name: 'CourseUsers',
    component: () => import(/* webpackChunkName: "CourseUsers" */ '@/views/CourseUsers.vue'),
  },
  {
    path: '/courseMessages',
    name: 'CourseMessages',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseMessages.vue'),
  },
  {
    path: '/reports',
    name: 'CourseReports',
    component: () => import(/* webpackChunkName: "CourseReports" */ '@/views/CourseReports.vue'),
  },
  {
    path: '/requests',
    name: 'CourseRequests',
    component: () => import(/* webpackChunkName: "CourseRequests" */ '@/views/CourseRequests.vue'),
  },
  {
    path: '/exercise/:id',
    name: 'Exercise',
    component: () => import(/* webpackChunkName: "ExerciseEntity" */ '@/views/ExerciseEntity.vue'),
  },
  {
    path: '/question/:id',
    name: 'Question',
    component: () => import(/* webpackChunkName: "QuestionEntity" */ '@/views/QuestionEntity.vue'),
  },
  {
    path: '/lawCollection/:id',
    name: 'LawCollection',
    component: () => import(/* webpackChunkName: "QuestionEntity" */ '@/views/LawCollectionEntity.vue'),
  },
  {
    path: '/newQuestion',
    name: 'NewQuestion',
    component: () => import(/* webpackChunkName: "QuestionEntity" */ '@/views/NewQuestion.vue'),
  },
  {
    path: '/lessons/:id',
    name: 'Lesson',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LessonEntity.vue'),
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Videos.vue'),
  },
  {
    path: '/books',
    name: 'Books',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Books.vue'),
  },
  {
    path: '/exercises',
    name: 'Exercises',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Exercises.vue'),
  },
  {
    path: '/laws',
    name: 'Laws',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Laws.vue'),
  },
  {
    path: '/verdicts',
    name: 'Verdicts',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Verdicts.vue'),
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Groups.vue'),
  },
  {
    path: '/group/:id',
    name: 'GroupEntityPage',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/GroupEntity.vue'),
  },
  {
    path: '/exerciseAnswers',
    name: 'ExerciseAnswers',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExerciseAnswers.vue'),
  },
  {
    path: '/lecturesLearningTracks',
    name: 'LecturesLearningTracks',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesLearningTracks.vue'),
  },
  {
    path: '/lecturesRegions',
    name: 'LecturesRegions',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesRegions.vue'),
  },
  {
    path: '/lecturesHalls',
    name: 'LecturesHalls',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesHalls.vue'),
  },
  {
    path: '/lecturesTeachers',
    name: 'LecturesTeachers',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesTeachers.vue'),
  },
  {
    path: '/lecturesFeedbacksStatements',
    name: 'LecturesFeedbacksStatements',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesFeedbacksStatements.vue'),
  },
  {
    path: '/lectures/:id?',
    name: 'Lectures',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Lectures.vue'),
  },
  {
    path: '/lecturesLearningTrack/:id/Subjects',
    name: 'LectureTrackSubjects',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LectureTrackSubjects.vue'),
  },
  {
    path: '/course/:courseId/exercisePrint/:exerciseId',
    name: 'ExercisePrint',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExercisePrint.vue'),
  },
  {
    path: '/expoChapters',
    name: 'ExpoChapters',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExpoChapters.vue'),
  },
  {
    path: '/expoChapter/:id',
    name: 'ExpoChapterPage',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExpoChapterEntity.vue'),
  },
  {
    path: '/expoChapter/:id/questions',
    name: 'ExpoChapterQuestions',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExpoChapterQuestions.vue'),
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import(/* webpackChunkName: "UserEntity" */ '@/views/UserEntity.vue'),
  },
  {
    path: '/lessons',
    name: 'Lessons',
    component: () => import(/* webpackChunkName: "Lessons" */ '@/views/Lessons.vue'),
  },
  {
    path: '/course',
    name: 'Course',
    redirect: '/course/general',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Course.vue'),
    children: [
      {
        path: 'general',
        name: 'CourseGeneral',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseGeneral.vue'),
      },
      // {
      //   path: 'messages',
      //   name: 'CourseMessages',
      //   component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseMessages.vue'),
      // },
      {
        path: 'navigation',
        name: 'CourseNavigation',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseNavigation.vue'),
      },
      {
        path: 'calendar',
        name: 'CourseCalendar',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseCalendar.vue'),
      },
      {
        path: 'admincourserequests',
        name: 'AdminCourseRequests',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/AdminCourseRequests.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      layout: 'layout-full',
    },
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const adminRouteNames = [
  "Login", "AdminCourses", "AdminCoupons", "AdminQuotes", "AdminBooksBank", "AdminVideosBank", "AdminQuestionsBank", "AdminUsers", "AdminUpdateExerciseLaws",
  "AdminUpdateVideoContent", "AdminUpdateLawCollections", "AdminUnitReplication", "AdminVerdictReplication", "AdminCreateAnswersFiles", "AdminVerdictsCollections",
  "AdminManageLawsCollections", "Question", "NewQuestion", "LawCollection"
];

router.beforeEach((to, from, next) => {
  if (to.meta.ignoreLogin) {
    return next();
  }
  if (!adminRouteNames.includes(to.name)) {
    if (!store.state.auth.course?.id) {
      router.replace('admin');
    }
  }
  if (to.name === 'Login') { // check if "to"-route is "callback" and allow access
    if (!router.app.$auth.isAuthenticated()) {
      next();
    } else {
      next(false);
    }
  } else if (router.app.$auth.isAuthenticated()) { // if authenticated allow access
    next();
  } else { // trigger auth login
    router.app.$auth.login()
      .then(() => {
        next();
      })
      .catch(() => {
        router.push({ name: 'Login' });
      });
  }
  return false;
});

export default router;
