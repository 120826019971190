<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ !row ? 'הוספת' : 'עדכון' }} נושא לפנייה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="שם">
                        <b-input v-model="model.displayName"></b-input>
                    </b-field>
                    <b-field label="תיאור">
                        <b-input v-model="model.description"></b-input>
                    </b-field>
                    <b-field label="סדר">
                        <b-input type="number" v-model="model.sortOrder"></b-input>
                    </b-field>
                    <b-field label="ימי פעילות">
                        <b-select v-model="model.toDayOfWeek" placeholder="" expanded>
                            <option :value="1">יום ראשון</option>
                            <option :value="2">יום שני</option>
                            <option :value="3">יום שלישי</option>
                            <option :value="4">יום רביעי</option>
                            <option :value="5">יום חמישי</option>
                            <option :value="6">יום שישי</option>
                        </b-select>
                    </b-field>
                    <b-field label="שעת סיום פעילות">
                        <b-input v-model="model.toHour" v-mask="'##:##'"></b-input>
                    </b-field>
                    <b-field label="סטטוס">
                        <b-checkbox v-model="model.status" label="" type="is-link is-light" class="checkbox">
                            {{ model.status ? 'פעיל' : 'לא פעיל' }}
                        </b-checkbox>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import { ToastProgrammatic as Toast } from "buefy";

export default {
    name: "DialogMessageType",
    props: ["store", 'row'],
    components: {
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id;
        },
    },
    created() {
        if (this.row) {
            this.model = this.row;
            if (!this.row.toHour) return null;
            else {
                const time = this.row.toHour.toString().split('.');
                const hours = +time[0] > 9 ? +time[0] : `0${time[0]}`;
                if (!time[1]) {
                    this.row.toHour = `${time[0]}`;
                } else {
                    this.model = {
                        ...this.model,
                        toHour: `${hours}:${+time[1] * 60 / 10}`
                    }
                }
            }
        }

    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                displayName: null,
                description: null,
                status: null,
                sortOrder: null,
                toDayOfWeek: null,
                toHour: null,
                appLocation: 'צור קשר'
            },
        };
    },
    methods: {
        save() {
            this.isLoading = true;
            if (!this.row) {
                AdminService.addCourseMessageType(this.courseId, {
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            } else {
                let formattedTime = null;
                if (this.model.toHour) {
                    const time = this.model.toHour.split(':');
                    if (!time[1]) time[1] = "0";
                    formattedTime = +time[0] + (+time[1] / 60);
                }
                AdminService.editCourseMessageType(this.courseId, this.row.id, {
                    ...this.model, toHour: formattedTime?.toFixed(2)
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() { }
    },
};
</script>

<style lang="scss">
.select:not(.is-multiple):not(.is-loading)::after {
    margin-right: 92%;
}

.select select:not([multiple]) {
    padding-right: 10px;
}
</style>
