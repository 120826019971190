<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>נתוני תשובות לתרגולים בקורס {{ course.name }}</template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import LessonService from "@/services/LessonService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import ExerciseAnswerEntity from "@/views/ExerciseAnswerEntity.vue";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    courseId() {
      return this.$store.state.auth.course.id;
    },
    course() {
      return this.$store.state.auth?.course;
    },
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("exerciseAnswers");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: ExerciseAnswerEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "נתוני תרגיל",
        recordUrl: "lessons",
        hideRecord: true,
        recordTitle: "הצג יחידת לימוד"
      };
      const fields = [
        { ...lessonsFields.UserName, checked: true },
        { ...lessonsFields.ExerciseName, checked: true },
        { ...lessonsFields.ReportUrl, checked: true },
        { ...lessonsFields.Grade, checked: true },
        { ...lessonsFields.MadadTakinEditor, checked: true },
        { ...lessonsFields.StarPercent, checked: true },
        { ...lessonsFields.StarGrade, checked: true },
        { ...lessonsFields.GrayPercent, checked: true },
        { ...lessonsFields.GrayGrade, checked: true },
        { ...lessonsFields.Ahuzon, checked: true },
        { ...lessonsFields.EndDateTime, checked: true },
        { ...lessonsFields.ExerciseQuestionsLink, checked: true },
        { ...lessonsFields.FieldExerciseAnswers, checked: true },
        { ...lessonsFields.WasResearched, checked: true },
        { ...lessonsFields.FieldExerciseReset, checked: true },
      ];

      this.tabs = [
        {
          id: "AllExerciseAnswers",
          isPrimary: true,
          title: "כל התשובות לתרגולים",
          getDataAsync: this.getExerciseAnswers,
          perPage: 100,
          fields,
          filters: [
          { ...reportsfilters.Users, selected: this.$route.params.UserIds },
          { ...reportsfilters.Exercises, selected: this.$route.params.ExerciseIds },
          { ...reportsfilters.Groups, selected: this.$route.params.GroupIds },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewEntity,
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getExerciseAnswers({ ...rest }) {
      return LessonService.getExerciseAnswers(this.courseId, rest, rest.cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #00427e;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
