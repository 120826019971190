<template>
  <div class="Entity__View">
    <div class="Entity__View__Head">
      <slot name="header">
        <div class="Entity__View__Head__Right">
          <div class="Entity__View__Head__Title">
            <slot name="title"></slot>
          </div>
          <div class="Entity__View__Head__Counter">
            <slot name="counter" v-bind:tab="selectedTab">{{
              selectedTab
            }}</slot>
          </div>
        </div>
        <div class="Entity__View__Head__Left">
          <slot name="button"></slot>
        </div>
      </slot>
    </div>
    <div class="Entity__View__Tabs">
      <div class="Entity__View__Tabs__Menu">
        <div class="Entity__View__Tabs__Menu__Item" v-for="tab in tabs" :key="tab.id"
          :class="{ selected: isSelected(tab) }" @click="selectTab(tab)">
          <i class="Entity__View__Tabs__Menu__Item__Icon" :class="{ pinned: pinned }" @click="pinned = !pinned">
            <img src="@/assets/pin.svg" alt="" />
          </i>
          <span class="Entity__View__Tabs__Menu__Item__Icon__Text">{{
            tab.title
          }}</span>
          <span v-if="tab.count" class="Entity__View__Tabs__Menu__Item__Icon__Count">{{
            tab.count
          }}</span>
        </div>
      </div>
      <div class="Entity__View__Tabs__Content">
        <div class="Entity__View__Table is-flex-direction-column">
          <div class="
              Entity__View__Table__Filters
              is-flex is-justify-content-flex-end
              mb-3
            ">
            <div class="is-flex">
              <div class="mr-2">
                <b-tooltip label="בחירת עמודות">
                  <b-dropdown aria-role="menu" scrollable max-height="400" trap-focus multiple class="dropdown-fields"
                    v-model="selectedTab.selectedFields">
                    <template #trigger>
                      <b-button>
                        <b-icon icon="table-settings"> </b-icon>
                      </b-button>
                    </template>

                    <b-dropdown-item class="search-sticky" custom aria-role="listitem" focusable>
                      <b-input autofocus v-model="columnField" placeholder="חפש שדה..." />
                    </b-dropdown-item>

                    <b-dropdown-item v-show="!item.isHidden" paddingless v-for="item of filteredPageList" :value="item.Id"
                      :key="item.Id" aria-role="listitem">
                      <label class="drop-down-field-item" @click.prevent>
                        <div>
                          <input type="checkbox" :checked="isCheckedColumn(item.Id)" />
                          <span class="checkmark"></span>
                        </div>
                        {{ item.Display }}
                      </label>
                    </b-dropdown-item>

                    <b-dropdown-item class="search-sticky-bottom filters_actions" custom aria-role="listitem" focusable>
                      <b-button @click="resetColumns" type="is-info is-light">איפוס עמודות</b-button>
                      <b-button @click="saveColumns" type="is-info">שמור</b-button>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-tooltip>
              </div>
              <div v-if="selectedTab.excelExport" class="mr-2">
                <b-loading :is-full-page="true" v-model="selectedTab.exportIsLoading" :can-cancel="false"></b-loading>
                <b-tooltip label="פעולות">
                  <b-dropdown :disabled="selectedTab.exportIsLoading" aria-role="list"
                    class="Entity__View__Table__Filters__Actions__Button">
                    <template #trigger>
                      <b-button>
                        <b-icon icon="ellipsis-v" pack="fas"> </b-icon>
                      </b-button>
                    </template>

                    <b-dropdown-item v-for="action in selectedTab.excelExport" @click="
                      action.action({
                        loading: setExcelLoading,
                        query: fullQuery,
                        getData: getData,
                        setFields: setFields
                      })
                      " :key="action.title" aria-role="listitem">
                      {{ action.title }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-tooltip>
              </div>
            </div>
            <div class="Entity__View__Table__Filters__Actions is-flex">
              <div v-if="selectedTab.tableActions">
                <b-dropdown :disabled="!haveSelectedRows" aria-role="list"
                  class="Entity__View__Table__Filters__Actions__Button">
                  <template #trigger="{ active }">
                    <b-button type="is-primary" :icon-right="active ? 'menu-up' : 'menu-down'">
                      <span>
                        פעולות על שורות מסומנות
                        <span v-show="selectedTab.selectedRows.length">
                          ({{ selectedTab.selectedRows.length }})
                        </span>
                      </span>
                    </b-button>
                  </template>

                  <b-dropdown-item v-for="action in selectedTab.tableActions" @click="
                    onTableActionClick(action, selectedTab.selectedRows)
                    " :key="action.Id" aria-role="listitem">
                    <b-icon :icon="action.icon" />
                    {{ action.Label }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div :class="{ 'mr-5': selectedTab.tableActions }"
                class="Entity__View__Table__Filters__Actions__FastSearch">
                <b-input v-if="selectedTab.fastSearch" custom-class="fast-search-input" icon="magnify"
                  icon-right="close-circle" icon-right-clickable icon-clickable @keyup.enter.native="searchFastIconClick"
                  @icon-click="searchFastIconClick" @icon-right-click="clearFastSearch" v-model="fastSearch" :placeholder="selectedTab.fastSearchOptions &&
                    selectedTab.fastSearchOptions.label
                    ? selectedTab.fastSearchOptions.label
                    : 'חיפוש מהיר...'
                    "></b-input>
                <div class="mr-2 is-flex is-align-items-center" v-if="selectedTab.tableFilters">
                  <b-tooltip label="סינון טבלה לפי פרמטרים נוספים">
                    <a class="is-flex is-align-items-center mr-5" href="" @click.prevent="showSearchMenu()">
                      <b-icon size="is-small" icon="tune"> </b-icon>
                      מסננים נוספים
                    </a>
                  </b-tooltip>
                  <a v-if="selectedTab.searched || queryExists" :disabled="selectedTab.loading" class="is-flex mr-5"
                    href="" @click="clearSearch()">נקה חיפוש</a>
                </div>
              </div>
            </div>
          </div>
          <b-table ref="table" :data="selectedTab.items" :loading="selectedTab.loading" :total="selectedTab.total"
            :checkable="selectedTab.checkableRows" :checkbox-position="'left'"
            :checked-rows.sync="selectedTab.selectedRows" :is-row-checkable="() => selectedTab.checkableRows"
            backend-sorting sticky-checkbox :sticky-header="true" :default-sort-direction="selectedTab.sortOrder"
            :default-sort="selectedTab.sortField" :sort-icon="'arrow-down'" :row-class="(row, index) => addRowClass(row)"
            @sort="onSort" @click="onRowClick" :paginated="true" :per-page="selectedTab.items.length">
            <b-table-column v-for="field in selectedFields" :visible="isColumnVisible(field)"
              :key="`${selectedTab.id}_${field.Id}`" :width="field.Width" :field="field.Id" :label="field.Display"
              :custom-key="field.Id" :centered="field.centeredHeader" :sortable="field.Sortable">
              <template v-slot="props">
                <div :is="field.Type" :items="selectedTab.items" :search="() => getData(selectedTab)" :data="props" :item="mapField(props.row, field.mapItem)"
                  :value="mapField(props.row[field.Id], field.map, props.row)" :field="field" :align="field.align" />
              </template>
            </b-table-column>
            <template #bottom-left v-if="selectedTab.OriginalAmountSum">
              <div class="indicators">
                <div>
                  <b>סכום מקור:</b>
                  ₪{{ selectedTab.OriginalAmountSum | currency }}
                </div>
                <div>
                  <b>יתרה לתשלום</b>
                  ₪{{ selectedTab.CurrentAmountSum | currency }}
                </div>
                <div v-if="haveSelectedRows" class="marked">
                  <b>סכום מקור:</b>
                  ₪{{ originalAmount | currency }}
                </div>
                <div v-if="haveSelectedRows" class="marked">
                  <b>יתרה לתשלום</b>
                  ₪{{ currentAmount | currency }}
                </div>
              </div>
            </template>
          </b-table>
          <div v-if="!noPaging" class="Entity__View__Table__Pagination">
            <b-pagination :total="selectedTab.total" v-model="selectedTab.page" :range-before="2" :range-after="2"
              @change="onPageChange" :order="'is-centered'" :size="'is-small'" :per-page="selectedTab.perPage"
              aria-next-label="דף הבא" aria-previous-label="דף קודם" aria-page-label="דף" aria-current-label="דף נוכחי">
            </b-pagination>
            <div class="Items__Per__Page">
              <b-dropdown position="is-top-left" aria-role="menu">
                <template #trigger>
                  <div class="Items__Per__Page__Button">
                    {{ selectedTab.perPage }} פריטים בעמוד
                    <span><b-icon icon="menu-down"></b-icon></span>
                  </div>
                </template>

                <b-dropdown-item @click="changePerPage(20)" aria-role="listitem">20 פריטים בעמוד</b-dropdown-item>
                <b-dropdown-item @click="changePerPage(50)" aria-role="listitem">50 פריטים בעמוד</b-dropdown-item>
                <b-dropdown-item @click="changePerPage(100)" aria-role="listitem">100 פריטים בעמוד</b-dropdown-item>
                <b-dropdown-item @click="changePerPage(500)" aria-role="listitem">500 פריטים בעמוד</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Overlay :open="showNewEntityMenu">
      <SideMenu>
        <component :is="selectedTab.newEntityComponent" @onSave="onNewEntitySave" @onCancel="onNewEntityCancel"
          :isAdminArea="selectedTab.isAdminArea" />
      </SideMenu>
    </Overlay>
    <Overlay :open="searchMenuVisible">
      <SideMenu>
        <div class="SideMenu__Header">
          <h1>מסננים</h1>
          <button class="SideMenu__Header__Close__Icon" @click="searchMenuVisible = false">
            <b-icon icon="close" size="is-small"> </b-icon>
          </button>
        </div>
        <div class="SideMenu__Content">
          <div v-for="(filter, index) in selectedTab.tableFilters" :key="`${selectedTab.Title}_${filter.Id}_${index}`">
            <component v-if="!filter.isHidden" :is="filter.Type" :clearable="filter.AllowEmpty" :options="filter.Options"
              :isManagedOnly="filter.isManagedOnly" :options-async="filter.OptionsAsync"
              :isTransferOnly="filter.isTransferOnly" :filter="filter" v-model="filter.model">
              <span :class="{ 'is-danger': filter.IsNot }">{{
                filter.Display
              }}</span>
            </component>
          </div>
        </div>
        <div class="SideMenu__Footer">
          <button @click="searchMenuVisible = false" class="close" type="">
            סגור
          </button>
          <button @click="search()" class="create" type="">חפש</button>
        </div>
      </SideMenu>
    </Overlay>
    <Overlay :open="showRowEntity || showExtrasEntity" :transparent="true"
      v-if="selectedTab.sideViewEntity || extrasEntity">
      <SideMenu :width="'500px'" v-if="showRowEntity" class="RowEntity">
        <div class="SideMenu__Header">
          <h1>{{ selectedTab.sideViewEntity.title }}</h1>
          <button class="SideMenu__Header__Close__Icon" @click="closeRowEntity">
            <b-icon icon="close" size="is-small"> </b-icon>
          </button>
        </div>
        <div class="SideMenu__Content Entity-Preview" v-if="selectedItem">
          <component :is="selectedTab.sideViewEntity.component" :bookLessonId="selectedItem.lessonId || null" :preview="selectedTab.sideViewEntity.preview"
            :read-only="selectedTab.sideViewEntity.readOnly" :id="mapSideViewEntityId(selectedItem)"
            @onSave="onNewEntitySave" @emit-log="emitLog" />
        </div>
        <div class="SideMenu__Footer">
          <b-button @click="closeRowEntity" class="close" type="">
            סגור
          </b-button>
          <b-button v-if="selectedTab.sideViewEntity.deletable" :loading="isDeletionInProgress" type="is-danger" @click="
            selectedTab.sideViewEntity.deletable.action({
              model: selectedItem,
              loading: setDeletionLoading,
              close: closeRowEntity,
            })
            ">
            מחק
          </b-button>
          <router-link v-if="!selectedTab.sideViewEntity.hideRecord && mapSideViewEntityId(selectedItem)"
            class="show-entity-btn" :to="selectedTab.sideViewEntity.customRecordUrl ||
              `/${selectedTab.sideViewEntity.recordUrl}/${mapSideViewEntityId(selectedItem)}`">{{
    selectedTab.sideViewEntity.recordTitle || "הצג רשומה" }}</router-link>
          <b-button @click="openModal(selectedTab.sideViewEntity.modal, selectedItem)" class="show-entity-btn" v-if="selectedTab.sideViewEntity.modal">{{ selectedTab.sideViewEntity.recordTitle }}</b-button>
        </div>
      </SideMenu>
      <SideMenu :width="'500px'" v-if="showExtrasEntity" class="RowEntity">
        <div class="SideMenu__Header">
          <h1>{{ extrasEntity.title }}</h1>
          <button class="SideMenu__Header__Close__Icon" @click="hideExtrasEntity">
            <b-icon icon="close" size="is-small"> </b-icon>
          </button>
        </div>
        <div class="SideMenu__Content Entity-Preview">
          <component :is="extrasEntity.component" :preview="extrasEntity.preview" :read-only="extrasEntity.readOnly"
            :id="extrasEntity.Id" />
        </div>
        <div class="SideMenu__Footer">
          <button @click="hideExtrasEntity" class="close" type="">סגור</button>
          <router-link v-if="!extrasEntity.hideRecord" class="show-record" :to="extrasEntity.customRecordUrl ||
            `/${extrasEntity.recordUrl}/${extrasEntity.Id}`
            ">{{ extrasEntity.recordTitle || "הצג רשומה" }}</router-link>
        </div>
      </SideMenu>
    </Overlay>
  </div>
</template>
<script>
import Overlay from "@/components/Overlay.vue";
import SideMenu from "@/components/SideMenu.vue";
import moment from "moment";
import axios from "axios";
import * as fields from "@/components/Fields";
import * as filters from "@/components/Filters";
import { ModalProgrammatic as Modal, ToastProgrammatic as Toast } from "buefy";

export default {
  name: "EntityLayout",
  components: {
    ...fields.default,
    ...filters.default,
    Overlay,
    SideMenu,
  },
  props: {
    noPaging: Boolean,
    tabs: Array,
    namespace: {
      type: String,
      default: "reports",
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    currentAmount() {
      if (this.haveSelectedRows) {
        return this.selectedTab.selectedRows
          .map((i) => i.CurrentAmount)
          .reduce((a, b) => a + b, 0);
      }
      return 0;
    },
    originalAmount() {
      if (this.haveSelectedRows) {
        return this.selectedTab.selectedRows
          .map((i) => i.OriginalAmount)
          .reduce((a, b) => a + b, 0);
      }
      return 0;
    },
    queryExists() {
      return Object.keys(this.$route.query).length;
    },
    fullQuery() {
      // eslint-disable-next-line no-nested-ternary
      const terms = !this.fastSearch
        ? {}
        : this.selectedTab.fastSearchOptions &&
          this.selectedTab.fastSearchOptions.propName
          ? {
            [this.selectedTab.fastSearchOptions.propName]: this.fastSearch,
          }
          : {
            SearchTerm: this.fastSearch,
          };
      return {
        ...this.query,
        ...terms,
        ...this.permanentQuery,
      };
    },
    entity() {
      return this;
    },
    filters() {
      if (this.selectedTab && this.selectedTab.filters) {
        return this.selectedTab.filters.map((i) => ({ ...i }));
      }
      return null;
    },
    haveSelectedRows() {
      return this.selectedTab && this.selectedTab.selectedRows.length;
    },
    fieldsNames() {
      return this.selectedTab.fields.map((i) => i.Id);
    },
    pageFields() {
      if (this.selectedTab) {
        return this.selectedTab.fields.map((i) => ({ ...i }));
      }
      return [];
    },
    filteredPageList() {
      let fieldSorted = this.pageFields;
      if (this.columnField) {
        fieldSorted = this.pageFields.filter(
          (i) => i.Display.indexOf(this.columnField) > -1
        );
      }
      return fieldSorted.sort((a, b) => (b.Display > a.Display ? -1 : 1));
    },
    selectedFields() {
      if (this.selectedTab) {
        return this.pageFields.slice(0).sort((a, b) => {
          if (a.Order === undefined) return 1;
          if (b.Order === undefined) return -1;
          return a.Order - b.Order;
        });
      }
      return [];
    },
    query() {
      const queries = this.selectedTab.tableFilters?.map((t) => {
        if (
          typeof t.model === "object" &&
          t.model !== null &&
          !Array.isArray(t.model) &&
          t.IsComplex
        ) {
          const option = t.Options.find((i) => i.Value === t.model.Value);
          return {
            [option.fromField]: t.model.fromDate,
            [option.toField]: t.model.toDate,
          };
        }
        return { [t.Query]: t.model };
      });
      return queries?.reduce(
        (obj, item) =>
          Object.assign(
            obj,
            {
              [Object.keys(item)[0]]:
                Object.values(item)[0]?.Value ?? Object.values(item)[0],
            },
            {
              [Object.keys(item)[1]]:
                Object.values(item)[1]?.Value ?? Object.values(item)[1],
            }
          ),
        {}
      );
    },
  },
  data() {
    /* eslint 'object-curly-newline': "off", max-len: 'off', prefer-template: 'off' */
    return {
      fastSearch: null,
      columnField: "",
      pinned: false,
      searchMenuVisible: false,
      showNewEntityMenu: false,
      selectedTab: null,
      selectedItem: null,
      permanentQuery: {},
      showRowEntity: false,
      extrasEntity: false,
      showExtrasEntity: false,
      isDeletionInProgress: false,
    };
  },
  created() {
    this.initTabs(this.tabs);
  },
  methods: {
    addRowClass(row) {
      let str = "";
      if (row.status === false || row.isActive === false) str += "not-activated-row";
      else if (row.isHidden) str = "hidden-row";
      return str;
    },
    emitLog() {
    },
    showEntity(selectedItem) {
      this.$router.push(
        this.selectedTab.sideViewEntity.customRecordUrl ||
        `/${this.selectedTab.sideViewEntity.recordUrl
        }/${this.mapSideViewEntityId(selectedItem)}`
      );
    },
    setDeletionLoading(value) {
      this.isDeletionInProgress = value;
    },
    hideExtrasEntity() {
      this.showExtrasEntity = false;
      this.extrasEntity = null;
    },
    setExtraSideMenu(options) {
      this.extrasEntity = options;

      return {
        show: () => {
          this.showExtrasEntity = true;
        },
        hide: this.hideExtrasEntity,
      };
    },
    mapSideViewEntityId(item) {
      if (item) {
        if (this.selectedTab.sideViewEntity.key) {
          return item[this.selectedTab.sideViewEntity.key];
        }
        if (this.selectedTab.sideViewEntity.map) {
          return this.selectedTab.sideViewEntity.map(item);
        }
        return item.bookId || item.id;
      }
      return null;
    },
    openModal(modal, row = null) {
      Modal.open({
        component: modal,
        customClass: "entity-modal",
        props: {
          onSuccess: this.onNewEntitySave,
          store: this.$store,
          row,
          router: this.$router
        },
        width: this.selectedTab.id === 'AllRequests' || this.selectedTab.id === 'RequestsTaggedByMe' ? 1200 : 960,
        canCancel: false,
        trapFocus: true,
      });
    },
    setPermanentQuery(query) {
      this.permanentQuery = query;
    },
    isColumnVisible(field) {
      return !!this.selectedTab.selectedFields.find((i) => i === field.Id);
    },
    clearSearch() {
      this.$router.replace({ query: null });
      this.$set(
        this.selectedTab,
        "tableFilters",
        this.filters.map((i) => {
          this.$set(i, "model", i.selected);
          return i;
        })
      );
      this.$set(this.selectedTab, "searched", false);
      this.getData(this.selectedTab);
    },
    async searchFastIconClick() {
      const tab = this.selectedTab;
      if (typeof tab.fastSearch === "function") {
        const items = (tab.originItems || tab.items).slice(0);
        this.$set(tab, "originItems", items);
        const data = await tab.fastSearch({
          setSearchLoading: this.setSearchLoading,
          data: items.slice(0),
          term: this.fastSearch,
        });
        return this.localData(data);
      }
      return this.getData(this.selectedTab);
    },
    clearFastSearch() {
      this.fastSearch = null;
      this.searchFastIconClick();
    },
    restoreSaveColumns() {
      const tab = this.selectedTab;
      const columns = localStorage.getItem(`${tab.id}`);
      if (columns) {
        return JSON.parse(columns);
      }
      return null;
    },
    saveColumns() {
      const tab = this.selectedTab;
      localStorage.setItem(`${tab.id}`, JSON.stringify(tab.selectedFields));

      Toast.open({
        type: "is-info",
        message:
          "העמודות נשמרו בהצלחה. מעתה בכל כניסה העמודות שבחרת יטענו באופן אוטומטי.",
        position: "is-bottom",
        duration: 5000,
      });
    },
    resetColumns() {
      const tab = this.selectedTab;
      const selectedFields = tab.fields
        .filter((f) => f.checked)
        .map((i) => i.Id);
      this.$set(tab, "selectedFields", selectedFields);
    },
    rand() {
      return Math.floor(Math.random() * 100000000) + 1;
    },
    isCheckedColumn(fieldId) {
      if (this.selectedTab.selectedFields) {
        return this.selectedTab.selectedFields.find((i) => i === fieldId);
      }
      return false;
    },
    setExcelLoading(value) {
      this.$set(this.selectedTab, "exportIsLoading", value);
    },
    setSearchLoading(value) {
      this.$set(this.selectedTab, "loading", value);
    },
    mapField(value, map, raw) {
      if (map) {
        return map(value, raw);
      }
      return value;
    },
    changePerPage(number) {
      this.selectedTab.perPage = number;
      this.getData(this.selectedTab);
    },
    onRowClick(row) {
      this.showRowEntity = true;
      if (this.selectedTab.sideViewEntity) {
        this.selectedItem = null;
        setTimeout(() => {
          this.selectedItem = row;
        });
      } else if (this.selectedTab.sideViewModal) {
        this.openModal(this.selectedTab.sideViewModal, row);
      } else {
        return null;
      }
    },
    closeRowEntity() {
      this.selectedItem = null;
      this.showRowEntity = false;
    },
    search() {
      this.$set(this.selectedTab, "searched", true);
      this.searchMenuVisible = false;
      this.getData(this.selectedTab);
    },
    onPageChange(page) {
      this.selectedTab.page = page;
      this.getData(this.selectedTab);
    },
    onTableActionClick(action, selectedRows) {
      action.click(selectedRows, () => this.executeClick(selectedRows));
    },
    executeClick(selectedRows) {
      const tab = this.selectedTab;
      this.getData(tab).then(() => {
        const selectedIds = selectedRows.map((i) => i.Id);
        selectedRows.map((i) => i.Id);
        this.$set(
          tab,
          "selectedRows",
          tab.items.filter((i) => selectedIds.includes(i.Id))
        );
      });
    },
    onSort(field, order) {
      this.$set(this.selectedTab, "sortField", field);
      this.$set(this.selectedTab, "sortOrder", order);
      this.getData(this.selectedTab, {
        Page: this.selectedTab.perPage,
        PageNumber: this.selectedTab.page || 1,
        SortedBy: this.selectedTab.sortField,
        IsAscending: this.selectedTab.sortOrder === "asc",
      });
    },
    selectTab(tab) {
      this.showRowEntity = false;
      this.selectedTab = tab;
      this.$router.replace({ path: this.$route.path, query: { tab: tab.id } });
      this.loadTab(this.selectedTab);
    },
    isSelected(tab) {
      return this.selectedTab === tab;
    },
    showSearchMenu() {
      this.showRowEntity = false;
      this.searchMenuVisible = true;
    },
    newEntityMenu() {
      this.showNewEntityMenu = true;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    initTabs(tabs) {
      const { tab: tabFromQuery } = this.$route.query;
      if (tabs.length) {
        let initialTab;
        if (tabFromQuery) {
          initialTab = tabs.find((t) => t.id === tabFromQuery);
        }
        if (!initialTab) {
          initialTab = tabs.find((t) => t.isPrimary);
        }
        if (!initialTab) {
          [initialTab] = tabs;
        }
        this.selectedTab = initialTab;
        return this.loadTab(this.selectedTab);
      }
      return Promise.reject();
    },
    localData(data) {
      const tab = this.selectedTab;
      this.$set(tab, "total", data.length);
      this.$set(tab, "items", data);
      this.setSearchLoading(false);
    },
    loadTab(tab) {
      const savedColumns = this.restoreSaveColumns(tab.id);
      this.$set(
        this.selectedTab,
        "tableFilters",
        this.filters?.map((i) => {
          if (typeof i.selected === "function") {
            (function (context, v) {
              v.selected().then((r) => {
                context.$set(v, "model", r);
              });
            })(this, i);
          } else {
            this.$set(i, "model", i.selected);
          }
          return i;
        })
      );
      this.$set(tab, "items", []);
      this.$set(
        tab,
        "selectedFields",
        savedColumns ||
        tab.selectedFields ||
        tab.fields.filter((f) => f.checked).map((i) => i.Id)
      );
      this.$set(tab, "exportIsLoading", false);
      return this.getData(tab, this.$route.query);
    },
    setFields(fields) {
      this.selectedTab.selectedFields = fields;
    },
    getData(tab, query = {}) {
      if (tab.cancelTokenSource) {
        tab.cancelTokenSource.cancel();
      }
      this.$set(tab, "loading", true);
      this.$set(tab, "selectedRows", []);
      this.$set(tab, "items", []);
      this.$set(tab, "cancelTokenSource", axios.CancelToken.source());
      this.$set(tab, "OriginalAmountSum", 0);
      this.$set(tab, "total", 0);
      this.$set(tab, "CurrentAmountSum", 0);
      this.setPermanentQuery({});
      if (this.$route.name === 'CourseRequests') this.getMyRequestsCount();

      return tab
        .getDataAsync({
          PageSize: tab.perPage,
          PageNumber: tab.page || 1,
          SortedBy: tab.sortField,
          // Terms: tab.Terms || '',
          IsAscending: true,
          ...this.fullQuery,
          ...query,
          ResultType: 3,
          cancelToken: tab.cancelTokenSource,
          setPermanentQuery: this.setPermanentQuery,
        })
        .then((r) => {
          const { data } = r;
          const items = data.Items.slice(0);
          this.$set(tab, "total", data.TotalResults);
          // items.forEach((i) => {
          //   if (!i.status) {
          //     i.IsWarning = true;
          //   }
          // });
          if (this.isAdmin) {
            items.forEach((i) => {
              if (i?.LastPayDate) {
                const today = new Date().getTime();
                const lastPayDateTimestamp = new Date(i.LastPayDate).getTime();
                const weekAfterToday = today + 14 * 24 * 60 * 60 * 1000; // 14 days
                if (!i.IsPaid && lastPayDateTimestamp <= weekAfterToday) {
                  i.IsWarning = true;
                  if (
                    i.TransferStatus === "ההסבה הושלמה" &&
                    (i.DriverTransferStatus === "הסבה הושלמה" ||
                      i.DriverTransferStatus === "לא מוסב")
                  ) {
                    i.IsWarning = false;
                  }
                }
              }
            });
          }
          this.$set(tab, "items", items);
          // this.$set(tab, "OriginalAmountSum", data.OriginalAmountSum);
          // this.$set(tab, "CurrentAmountSum", data.CurrentAmountSum);
          this.$set(tab, "loading", false);
          // this.getAdditionalData(tab);
        })
        .catch((error) => {
          this.$set(tab, "items", []);
          this.total = 0;

          throw error;
        });
    },
    getAdditionalData(tab, query = {}) {
      tab
        .getDataAsync({
          PageSize: tab.perPage,
          PageNumber: tab.page || 1,
          SortedBy: tab.sortField,
          IsAscending: tab.sortOrder !== "desc",
          ...this.fullQuery,
          ...query,
          ResultType: 12,
          cancelToken: tab.cancelTokenSource,
          setPermanentQuery: this.setPermanentQuery,
        })
        .then((r) => {
          const { data } = r;
          this.$set(tab, "OriginalAmountSum", data.OriginalAmountSum);
          this.$set(tab, "CurrentAmountSum", data.CurrentAmountSum);
        })
        .catch((error) => {
          throw error;
        });
    },
    async getMyRequestsCount() {
      for (let i = 0; i < this.tabs.length; i += 1) {
        if (!this.selectedTab.count && this.tabs[i].defaultQuery) {
          /* eslint-disable no-await-in-loop */
          const res = await this.tabs[i].getDataAsync({
            ResultType: 3,
            // PageSize: this.tabs[i].perPage,
            TaggedAdmin: this.$store.state.auth.user.email,
            cancelToken: this.tabs[i].cancelTokenSource || axios.CancelToken.source(),
            setPermanentQuery: this.setPermanentQuery(),
          });
          this.tabs[i].count = res.data.TotalResults;
          this.$forceUpdate();
        }
      }
    },
    onNewEntityCancel() {
      this.showNewEntityMenu = false;
    },
    onNewEntitySave(data) {
      this.getData(this.selectedTab);
      this.$emit("onNewEntitySave", data);
    },
  },
  watch: {
    tabs(newValue, oldValue) {
      if (!oldValue) {
        this.initTabs(newValue);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .level-right {
  display: none !important;
}

::v-deep .is-sticky {
  position: sticky;
  top: 0;
  right: 0;
}

.Entity__View {
  padding: 15px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Entity__View__Tabs__Menu__Item__Icon__Count {
  display: flex;
  background-color: #00427e;
  border-radius: 50%;
  align-items: center;
  width: 25px;
  padding: 10px;
  height: 25px;
  position: relative;
  justify-content: center;
  color: white;
}

.Entity__View__Tabs {
  flex: 1;
  display: flex;
  flex-direction: column;

  .Entity__View__Tabs__Content {
    flex: 1;
  }
}

.Entity__View__Tabs__Menu {
  border-bottom: 1px solid #ccd6e8;
  display: flex;
  padding: 0 40px;

  .dropdown-menu {
    right: initial !important;
    left: 0;
  }

  .Entity__View__Tabs__Menu__Item {
    padding: 8px;
    border: 1px solid #ccd6e8;
    gap: 5px;
    border-right: none;
    position: relative;
    z-index: 2;
    background: white;
    display: flex;
    top: 1px;
    cursor: pointer;

    &:not(.selected) {
      background-color: #f5f8fa;

      &:active {
        transform: scale(0.98);
      }

      &:hover {
        color: #4a4a4a;
      }

      .Entity__View__Tabs__Menu__Item__Icon {
        opacity: 0.5;
      }
    }

    &:not(.selected):hover {
      background: #f0f8fd;
    }

    &.selected {
      border-bottom: 1px solid white;
    }

    &:last-child {
      border-top-left-radius: 3px;
    }

    &:first-child {
      border-top-right-radius: 3px;
      border-right: 1px solid #ccd6e8;
    }

    .Entity__View__Tabs__Menu__Item__Icon {
      width: 10px;
      margin: 0 5px;
      cursor: pointer;

      &.pinned {
        transform: rotateZ(-45deg);
      }
    }
  }
}

.Entity__View__Table {
  margin: 10px 40px 0 40px;
  height: 100%;
  display: flex;

  .filters_actions {
    button {
      padding: 4px 16px;
      height: unset;
      font-size: 14px;
    }
  }

  .Entity__View__Table__Filters {
    justify-content: space-between !important;
    direction: ltr;

    &>div {
      direction: rtl;
    }

    .Entity__View__Table__Filters__Actions {
      .Entity__View__Table__Filters__Actions__FastSearch {
        display: flex;
        align-items: center;
        font-weight: 600;

        .fast-search-input {
          color: black;
        }
      }

      .Entity__View__Table__Filters__Actions__Button {
        ::v-deep {
          >div>button {
            display: flex;

            .icon {
              margin: 0;
            }
          }
        }
      }
    }
  }

  ::v-deep .b-table {
    flex: 1;

    .is-payment-approved {
      background-color: #dcffd9;
    }

    .not-activated-row {
      background-color: LightGrey;

      a {
        color: black;
      }
    }
    .hidden-row {
      background-color: Beige;

      a {
        color: black;
      }
    }

    .table-wrapper {
      min-height: 100%;
      overflow-y: auto;
    }

    .table {
      border-collapse: collapse;

      .checkbox-cell {
        min-width: 0;
      }
    }
  }

  ::v-deep table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    border: 1px solid #dfe3eb;

    &.table-sticky-header {
      th {
        position: sticky;
        top: 0px;
      }
    }

    th,
    td {
      text-align: right;
      font-weight: normal !important;
      padding: 10px;
      cursor: default;
    }

    th {
      border: 1px solid #dfe3eb;
      min-width: 100px;

      &.selected {
        background: #eaf0f6;
      }

      &.is-current-sort {
        background-color: #eaf0f6 !important;
      }
    }

    thead th {
      background: #f5f8fa !important;

      &:hover {
        background-color: rgb(229, 245, 248) !important;
      }
    }

    tbody tr {
      border: 1px solid #dfe3eb;

      &:hover {
        background: #e0ebff;
        cursor: pointer;
      }
    }
  }
}

.Entity__View__Head {
  padding: 0px 20px 10px 20px;
  display: flex;
  justify-content: space-between;

  .Entity__View__Head__Title {
    color: #00afd8;
    font-size: 25px;
    font-weight: 600;
  }

  .Entity__View__Head__Counter {
    font-size: 19px;
  }

  .Entity__View__Head__Left {
    margin-top: 13px;

    button {
      background: #00427e;
      border: none;
      border-radius: 3px;
      padding: 14px 25px;
      color: white;
      cursor: pointer;

      &:hover {
        background: #0c2949;
      }
    }
  }
}

.Pagination-Box {
  display: flex;
  list-style-type: none;

  li {
    width: 40px;
    padding: 5px;
    box-sizing: content-box;
    text-align: center;

    a {
      display: block;
    }
  }
}

.Items__Per__Page {
  margin-inline-start: 20px;

  .Items__Per__Page__Button {
    display: flex;
    cursor: pointer;
    color: #00afd8;
    font-weight: 500;

    &:hover {
      color: #5e8dbb;
    }
  }
}

.Entity__View__Table__Pagination {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .pagination {
    justify-content: center;
    direction: ltr;
    margin: 0;

    .pagination-list {
      flex-grow: unset;
    }
  }
}

::v-deep .table-wrapper {
  border: 1px solid #e6e6e6;
}

::v-deep .table-wrapper:not(:last-child) {
  margin-bottom: 0.5rem !important;
}

::v-deep .b-table .table-wrapper.has-sticky-header tr:first-child th {
  border: 1px solid rgb(223, 227, 235);
  // background-color: #F5F8FA !important;
  border-top: none;

  .th-wrap .is-relative {
    font-weight: bold;
    width: max-content;
    margin-left: 2em;
    font-size: 16px;
  }

  .sort-icon {
    right: 100%;
    left: unset;
  }
}

::v-deep .loading-overlay {
  z-index: 99 !important;
}

.indicators {
  display: flex;

  &>div {
    margin: 0 2px;
    background-color: #dbdbdb;
    padding: 1px 10px;
  }

  .marked {
    background: #dbdbdb;
  }
}

::v-deep .dropdown-fields {
  .dropdown-content {
    padding: 0;

    .search-sticky {
      position: sticky;
      z-index: 2;
      top: 0;
      background-color: white;
      padding: 10px 13px 5px 13px;
      right: auto;

      input {
        height: unset;
        font-size: 14px;
        padding: 5px 10px;

        &:focus {
          border-color: #167df0;
          box-shadow: 0 0 0 0.11em #167df080;
        }
      }
    }

    .search-sticky-bottom {
      position: sticky;
      z-index: 2;
      bottom: 0;
      background-color: white;
      display: flex;
      justify-content: space-evenly;
      right: auto;

      .button.is-link {
        padding: 0;
        height: 30px;
        padding: 0px 10px;
        flex-grow: 1;
        width: 50%;
        margin: 0 3px;
        font-size: 15px;
      }
    }
  }

  .dropdown-menu {
    min-width: 15rem;
    max-width: 32rem;
    right: initial;
    left: 0;
  }

  a {
    outline: none;
    padding: 5px 0px !important;

    label {
      cursor: pointer;
    }

    &.is-active {
      background-color: inherit !important;
      color: black !important;
      position: relative;
    }

    &.dropdown-item {
      &:hover {
        background-color: whitesmoke !important;
      }

      & div {
        position: relative;

        input[type="checkbox"] {
          opacity: 0;
        }

        .checkmark {
          height: 20px;
          width: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          position: relative;
          border: 2px solid #9c9c9c;
          border-radius: 2px;
        }

        &:hover input~.checkmark {
          background-color: #e6e6e6 !important;
        }

        input:checked~.checkmark {
          background-color: white;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        input:checked~.checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid #0494ff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}

::v-deep .drop-down-field-item {
  display: flex;
  align-items: center;

  a {
    border-bottom: 1px solid silver;
  }
}

.Entity__View__Table__Filters__Actions__Button {
  .dropdown-content {
    padding: 10px 0;
  }

  .dropdown-item {
    padding: 10px 20px;

    &:hover {
      background: #c9eef7;
    }
  }
}
</style>
<style>
.Entity__View__Table__Filters__Actions__Button .dropdown-content {
  padding: 10px 0 !important;
}

.Entity__View__Table__Filters__Actions__Button .dropdown-menu {
  right: initial;
}

.Entity__View__Table__Filters__Actions__Button .dropdown-item {
  padding: 0;
  display: flex;
  align-items: center;
}

.Entity__View__Table__Filters__Actions__Button .dropdown-item .icon {
  margin-inline-end: 10px;
}

.Entity__View__Table__Filters__Actions__Button .dropdown-item .mdi::before {
  font-size: 20px;
}

.RowEntity {
  display: flex;
  justify-content: space-between;
  background-color: white;
}
</style>
