<template>
  <div class="Field__Component">
    <label for="">
      <span><slot></slot></span>
      <b-dropdown
        aria-role="menu"
        scrollable
        max-height="400"
        trap-focus
        multiple
        class="dropdown-fields"
        v-model="selectedItems"
        position="is-bottom-right"
      >
        <template #trigger>
          <b-button>
            {{ getItemNames() }}
          </b-button>
        </template>

        <b-dropdown-item
          class="search-sticky"
          custom
          aria-role="listitem"
          focusable
        >
          <b-input autofocus v-model="columnField" placeholder="חפש שדה..." />
        </b-dropdown-item>

        <b-dropdown-item
          paddingless
          v-for="item of filteredPageList"
          :value="item.Value"
          :key="item.Value"
          @click="setSelected(item)"
          aria-role="listitem"
        >
          <label class="drop-down-field-item" @click.prevent>
            <div>
              <input type="checkbox" :checked="isCheckedColumn(item.Value)" />
              <span class="checkmark"></span>
              {{ item.Text }}
            </div>
          </label>
        </b-dropdown-item>

        <b-dropdown-item
          class="search-sticky-bottom filters_actions"
          custom
          aria-role="listitem"
          focusable
        >
          <b-button @click="resetColumns" type="is-info is-light"
            >בטל הכל</b-button
          >
          <b-button @click="selectAll" type="is-info">בחר הכל</b-button>
          <b-button v-if="isTransferOnly" @click="TransferOnly" type="is-primary is-light">הסבה בלבד</b-button>
          <b-checkbox v-if="isManagedOnly" v-model="isManaged" type="is-info"
            >מנוהלים בלבד</b-checkbox
          >
        </b-dropdown-item>
      </b-dropdown>
    </label>
  </div>
</template>

<script>
export default {
  name: "FilterMultiDropdown",
  props: ["value", "options", "optionsAsync", "clearable", "isManagedOnly", "isTransferOnly"],
  computed: {
    filteredPageList() {
      if (this.data) {
        let fieldSorted = this.data;
        if (this.columnField) {
          fieldSorted = this.data.filter(
            (i) => i.Text.indexOf(this.columnField) > -1
          );
        }
        if (this.isManaged) {
          fieldSorted = this.data.filter((i) => i.IsManaged);
        }
        return fieldSorted.slice(); // not sorted
        // return fieldSorted.slice().sort((a, b) => (b.Text > a.Text ? -1 : 1));
      }
      return [];
    },
  },
  data() {
    return {
      columnField: "",
      data: [],
      selectedItems: [],
      isManaged: false,
    };
  },
  created() {
    if (this.optionsAsync) {
      this.optionsAsync().then((r) => {
        this.data = r;
      });
    } else {
      this.data = this.options.slice(0);
    }
    if (this.value && this.value.length) {
      this.selectedItems = this.value.slice();
    }
  },
  methods: {
    isCheckedColumn(fieldId) {
      if (this.selectedItems) {
        return this.selectedItems.some((i) => i === fieldId);
      }
      return false;
    },
    getItemNames() {
      if (
        !this.selectedItems ||
        !this.selectedItems.length ||
        !this.data ||
        !this.data.length
      ) {
        return "בחר";
      }
      let result = "";
      for (let i = 0; i < this.selectedItems.length; i += 1) {
        const selectedItem = this.data.find(
          (item) => item.Value === this.selectedItems[i]
        );
        if (selectedItem) {
          if (i) {
            result += " | ";
          }
          result += `${selectedItem.Text}`;
        }
      }
      return result;
    },
    resetColumns() {
      this.selectedItems = [];
      this.setSelected();
    },
    selectAll() {
      this.selectedItems = this.filteredPageList.map((i) => i.Value);
      this.setSelected();
    },
    TransferOnly() {
      const transferOnlyProfiles = this.filteredPageList.filter((i) => !i.ForbidTransfer);
      this.selectedItems = transferOnlyProfiles.map((i) => i.Value);
      this.setSelected();
    },
    setSelected() {
      if (this.selectedItems && this.selectedItems.length) {
        this.$emit("input", this.selectedItems);
      } else {
        this.$emit("input", null);
      }
    },
    sort(a, b) {
      return a.Text - b.Text;
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .checkbox {
  flex-direction: row !important;
  .control-label {
    padding-left: 0;
    padding-right: calc(0.75em - 1px) !important;
  }
}
::v-deep .dropdown {
  .dropdown-trigger {
    width: 100%;

    button {
      width: 100%;
      justify-content: start;

      span {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
  .dropdown-menu {
    right: 0 !important;
    left: initial !important;
  }

  .drop-down-field-item {
    display: flex;
    align-items: start;
  }
  .dropdown-content {
    padding: 10px 0;
  }
  .dropdown-item {
    padding: 10px 20px;

    &:hover {
      background: #c9eef7;
    }
  }
}
</style>
