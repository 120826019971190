var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", {}, [
        _vm.loading
          ? _c(
              "span",
              { staticClass: "loading" },
              [
                _c("ClipLoader", {
                  attrs: { color: "#2e3f50", loading: _vm.loading, size: 20 }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.groupStudentsModel && _vm.groupStudentsModel.length
          ? _c(
              "div",
              { staticClass: "StudentGroups__Container" },
              _vm._l(_vm.groupStudentsModel, function(group) {
                return _c(
                  "div",
                  {
                    key: group.id,
                    staticClass: "StudentGroupsWidget__Component"
                  },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(group.groupName) +
                          " בקורס: דד " +
                          _vm._s(group.courseName)
                      )
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm.groupStudentsModel && _vm.groupStudentsModel.length
          ? _c("div", { staticClass: "link-btn" }, [
              _c("a", { on: { click: _vm.showAll } }, [_vm._v("הצג הכל")])
            ])
          : _vm._e(),
        !_vm.loading && !_vm.groupStudentsModel.length
          ? _c("div", [_vm._v("אין קבוצות משויכות למשתמש")])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }