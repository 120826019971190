import FieldBoolean from '@/components/Fields/FieldBoolean.vue';
import FieldStandard from '@/components/Fields/FieldStandard.vue';
import FieldRequestSubject from '@/components/Fields/FieldRequestSubject.vue';
import FieldRequestStatus from '@/components/Fields/FieldRequestStatus.vue';
import FieldRequestOpenChat from '@/components/Fields/FieldRequestOpenChat.vue';
import FieldDateTime from '@/components/Fields/FieldDateTime.vue';
import FieldDateHour from '@/components/Fields/FieldDateHour.vue';
import FieldEntity from '@/components/Fields/FieldEntity.vue';
import FieldSortOrder from '@/components/Fields/FieldSortOrder.vue';
import FieldExerciseQuestions from '@/components/Fields/FieldExerciseQuestions.vue';
import FieldExerciseAnswers from '@/components/Fields/FieldExerciseAnswers.vue';
import FieldExerciseResearch from '@/components/Fields/FieldExerciseResearch.vue';
import FieldExerciseReset from '@/components/Fields/FieldExerciseReset.vue';
import FieldLessonType from '@/components/Fields/FieldLessonType.vue';
import FieldExerciseType from '@/components/Fields/FieldExerciseType.vue';
import FieldCustomerLink from '@/components/Fields/FieldCustomerLink.vue';
import FieldImage from '@/components/Fields/FieldImage.vue';
import FieldExpoType from '@/components/Fields/FieldExpoType.vue';
import FieldHtml from '@/components/Fields/FieldHtml.vue';
import FieldColor from '@/components/Fields/FieldColor.vue';
import FieldPreviewQuestion from '@/components/Fields/FieldPreviewQuestion.vue';

export default {
    FieldBoolean,
    FieldStandard,
    FieldRequestSubject,
    FieldRequestStatus,
    FieldRequestOpenChat,
    FieldDateTime,
    FieldDateHour,
    FieldEntity,
    FieldSortOrder,
    FieldExerciseQuestions,
    FieldExerciseAnswers,
    FieldExerciseResearch,
    FieldExerciseReset,
    FieldLessonType,
    FieldExerciseType,
    FieldCustomerLink,
    FieldImage,
    FieldExpoType,
    FieldHtml,
    FieldColor,
    FieldPreviewQuestion
};
