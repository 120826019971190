var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadExercise()
            }
          }
        },
        [_vm._v(" עריכה ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 2 },
          on: {
            click: function($event) {
              return _vm.loadExerciseQuestions()
            }
          }
        },
        [_vm._v(" שאלות בתרגול ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 3 },
          on: {
            click: function($event) {
              return _vm.loadExerciseStatistics()
            }
          }
        },
        [_vm._v(" סטטיסטיקה ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c("div", [
              _c(
                "div",
                { staticClass: "Activity__Component__TabsContent__Tasks" },
                [
                  _vm.exercise
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Activity__Component__TabsContent__Tasks__Content"
                        },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              { staticClass: "exercise-buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    on: {
                                      click: _vm.getPrintExerciseAnswersUrl
                                    }
                                  },
                                  [_vm._v("חוברת בחינה (פתרון)")]
                                ),
                                _c(
                                  "b-button",
                                  { on: { click: _vm.getPrintExerciseUrl } },
                                  [_vm._v("חוברת בחינה")]
                                ),
                                _c("b-button", { attrs: { disabled: "" } }, [
                                  _vm._v("ייצוא לWORD")
                                ]),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { type: "is-danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.reCalculateGrades()
                                      }
                                    }
                                  },
                                  [_vm._v("עדכון נתוני תרגול")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { type: "is-info" },
                                    on: { click: _vm.exportExerciseQuestions }
                                  },
                                  [_vm._v("ייצוא שאלות לאקסל")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              { staticStyle: { display: "flex", gap: "10px" } },
                              [
                                _c(
                                  "b-field",
                                  { attrs: { label: "שם התרגול" } },
                                  [
                                    _c("b-input", {
                                      staticStyle: { "min-width": "300px" },
                                      model: {
                                        value: _vm.exercise.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.exercise, "name", $$v)
                                        },
                                        expression: "exercise.name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-field",
                                  { attrs: { label: "סדר" } },
                                  [
                                    _c("b-input", {
                                      model: {
                                        value: _vm.exercise.sortOrder,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "sortOrder",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.sortOrder"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-field",
                                  { attrs: { label: "קטגוריה" } },
                                  [
                                    _c("v-select", {
                                      staticStyle: { "min-width": "240px" },
                                      attrs: {
                                        dir: "rtl",
                                        filterable: true,
                                        clearable: true,
                                        reduce: function(item) {
                                          return item.id
                                        },
                                        getOptionLabel: function(item) {
                                          return "" + item.name
                                        },
                                        options: _vm.categories,
                                        searchable: true,
                                        "close-on-select": true,
                                        placeholder: "בחר ערך מהרשימה"
                                      },
                                      model: {
                                        value: _vm.exercise.exerciseCategoryId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "exerciseCategoryId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.exerciseCategoryId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-field",
                                  { attrs: { label: "סוג תרגול" } },
                                  [
                                    _c("v-select", {
                                      staticStyle: { "min-width": "240px" },
                                      attrs: {
                                        dir: "rtl",
                                        filterable: true,
                                        clearable: true,
                                        reduce: function(item) {
                                          return item.id
                                        },
                                        getOptionLabel: function(item) {
                                          return "" + item.name
                                        },
                                        options: _vm.exerciseTypes,
                                        searchable: true,
                                        "close-on-select": true,
                                        placeholder: "בחר ערך מהרשימה"
                                      },
                                      model: {
                                        value: _vm.exercise.exerciseTypeId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "exerciseTypeId",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.exerciseTypeId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-field",
                                  { attrs: { label: "סטטוס" } },
                                  [
                                    _c(
                                      "b-switch",
                                      {
                                        staticClass: "ltr",
                                        model: {
                                          value: _vm.exercise.status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.exercise,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "exercise.status"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.exercise.status
                                              ? "פעיל"
                                              : "לא פעיל"
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "חוקים להצגה" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dir: "rtl",
                                      filterable: true,
                                      clearable: true,
                                      reduce: function(item) {
                                        return { id: item.id, name: item.name }
                                      },
                                      getOptionLabel: function(item) {
                                        return "" + item.name
                                      },
                                      options: _vm.laws,
                                      multiple: true,
                                      searchable: true,
                                      "close-on-select": false,
                                      placeholder: "בחר ערך מהרשימה"
                                    },
                                    model: {
                                      value: _vm.exercise.displayLawIds,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.exercise,
                                          "displayLawIds",
                                          $$v
                                        )
                                      },
                                      expression: "exercise.displayLawIds"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.insertMethodOnline,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "insertMethodOnline",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.insertMethodOnline"
                                      }
                                    },
                                    [_vm._v("הזנה אונליין")]
                                  ),
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.insertMethodOffline,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "insertMethodOffline",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.insertMethodOffline"
                                      }
                                    },
                                    [_vm._v("הזנה בדיעבד")]
                                  )
                                ],
                                1
                              ),
                              _c("small", [
                                _vm._v("*יש לבחור לפחות שיטת הזנה אחת")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value:
                                          _vm.exercise.displaySeperateLawText,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "displaySeperateLawText",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.displaySeperateLawText"
                                      }
                                    },
                                    [_vm._v("הצג כל חוק בנפרד")]
                                  )
                                ],
                                1
                              ),
                              _c("small", [
                                _vm._v("רלוונטי לתרגול חקיקה דינאמי בלבד")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.disableBackButton,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "disableBackButton",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.disableBackButton"
                                      }
                                    },
                                    [_vm._v('הסתרת כפתור "הקודם" בזמן תרגול')]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-bottom": "5px",
                                  gap: "5px"
                                }
                              },
                              [
                                _c("span", { staticClass: "bold-text" }, [
                                  _vm._v("נתיב של האייקון:")
                                ]),
                                _c("b-input", {
                                  model: {
                                    value: _vm.exercise.iconUrl,
                                    callback: function($$v) {
                                      _vm.$set(_vm.exercise, "iconUrl", $$v)
                                    },
                                    expression: "exercise.iconUrl"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      attrs: { dir: "" },
                                      model: {
                                        value:
                                          _vm.exercise.isAllowedToPrintSolution,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "isAllowedToPrintSolution",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.isAllowedToPrintSolution"
                                      }
                                    },
                                    [_vm._v("האם לאפשר פתרון להדפסה")]
                                  ),
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { dir: "" },
                                      model: {
                                        value: _vm.exercise.isAllowedToPrint,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "isAllowedToPrint",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.isAllowedToPrint"
                                      }
                                    },
                                    [_vm._v("האם לאפשר שאלון להדפסה")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value:
                                          _vm.exercise
                                            .isTofillStudendFormInOfflineMode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "isTofillStudendFormInOfflineMode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.isTofillStudendFormInOfflineMode"
                                      }
                                    },
                                    [_vm._v("מילוי תשובות נכונות בהזנה בדיעבד")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c("div", { staticClass: "bold-text" }, [
                                _vm._v(
                                  "הנחיות (יופיעו לתלמיד לפני התרגול ובמהלכו) "
                                )
                              ]),
                              _c("ckeditor", {
                                attrs: {
                                  editor: _vm.editor,
                                  config: _vm.editorConfig
                                },
                                model: {
                                  value: _vm.exercise.disclaimerText,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.exercise,
                                      "disclaimerText",
                                      $$v
                                    )
                                  },
                                  expression: "exercise.disclaimerText"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.showAvgAndStdev,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "showAvgAndStdev",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.showAvgAndStdev"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        'הצגת ממוצע אמת, סטיית תקן ומספר מזינים בדו"ח תוצאות לתלמיד'
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value:
                                          _vm.exercise.isToShowAndCalcTimeCol,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "isToShowAndCalcTimeCol",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.isToShowAndCalcTimeCol"
                                      }
                                    },
                                    [_vm._v('הצגת עמודת זמן בדו"ח תרגול')]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-bottom": "5px",
                                  gap: "5px"
                                }
                              },
                              [
                                _c("span", { staticClass: "bold-text" }, [
                                  _vm._v("מדד תקין עורך:")
                                ]),
                                _c("b-input", {
                                  model: {
                                    value: _vm.exercise.madadTakinEditor,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.exercise,
                                        "madadTakinEditor",
                                        $$v
                                      )
                                    },
                                    expression: "exercise.madadTakinEditor"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-bottom": "5px",
                                  gap: "5px"
                                }
                              },
                              [
                                _c("span", { staticClass: "bold-text" }, [
                                  _vm._v("סיסמה לתרגול:")
                                ]),
                                _c("b-input", {
                                  model: {
                                    value: _vm.exercise.enterPassword,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.exercise,
                                        "enterPassword",
                                        $$v
                                      )
                                    },
                                    expression: "exercise.enterPassword"
                                  }
                                }),
                                _c("small", [
                                  _vm._v(
                                    "(*עד 6 תווים. *שדה הזנת הסיסמה יופיע לתלמיד בהנחיות לפני התרגול)"
                                  )
                                ])
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.isHidden,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "isHidden",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.isHidden"
                                      }
                                    },
                                    [_vm._v("האם מוסתר?")]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      "תלמידים יוכלו לגשת לתרגול אך ורק באמצעות לינק ישיר."
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.showInLawCenter,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "showInLawCenter",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.showInLawCenter"
                                      }
                                    },
                                    [_vm._v("האם להציג במרכז חקיקה?")]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      "אם התרגול מוסתר אבל רוצים שהחוק יוצג בכל זאת במרכז חקיקה."
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.isNoshem,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "isNoshem",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.isNoshem"
                                      }
                                    },
                                    [_vm._v("האם תרגול נושם?")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value:
                                          _vm.exercise
                                            .hideExerciseStatisticsPage,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "hideExerciseStatisticsPage",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.hideExerciseStatisticsPage"
                                      }
                                    },
                                    [_vm._v('האם להסתיר דו"ח תוצאות?')]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      'לאחר סיום התרגול התלמידים לא יראו את דו"ח התוצאות.'
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm.exercise.hideExerciseStatisticsPage
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "margin-bottom": "5px",
                                        gap: "5px"
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "bold-text" }, [
                                        _vm._v(
                                          'טקסט להצגה במצב של דו"ח תוצאות מוסתר:'
                                        )
                                      ]),
                                      _c("b-input", {
                                        model: {
                                          value:
                                            _vm.exercise
                                              .hideExerciseStatisticsPageMsg,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.exercise,
                                              "hideExerciseStatisticsPageMsg",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exercise.hideExerciseStatisticsPageMsg"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.exercise.hideExerciseStatisticsPage
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "margin-bottom": "5px",
                                        gap: "5px"
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "bold-text" }, [
                                        _vm._v(
                                          'האם להציג ציון במצב של דו"ח תוצאות מוסתר?:'
                                        )
                                      ]),
                                      _c("b-checkbox", {
                                        model: {
                                          value:
                                            _vm.exercise
                                              .isInHideModeDisplayGrade,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.exercise,
                                              "isInHideModeDisplayGrade",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exercise.isInHideModeDisplayGrade"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.allowUserReset,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "allowUserReset",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.allowUserReset"
                                      }
                                    },
                                    [_vm._v("האם לאפשר למשתמשים לאפס תרגול?")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value:
                                          _vm.exercise.allowImmediateFeedback,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "allowImmediateFeedback",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.allowImmediateFeedback"
                                      }
                                    },
                                    [_vm._v("האם לתת פידבק מידי לתשובה?")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "5px",
                                    gap: "5px"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "bold-text" }, [
                                    _vm._v("מקבץ חקיקה מספר:")
                                  ]),
                                  _c("b-input", {
                                    model: {
                                      value: _vm.exercise.lawCollectionId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.exercise,
                                          "lawCollectionId",
                                          $$v
                                        )
                                      },
                                      expression: "exercise.lawCollectionId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.hideExpansions,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "hideExpansions",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.hideExpansions"
                                      }
                                    },
                                    [_vm._v("האם להסתיר הרחבות??")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value:
                                          _vm.exercise
                                            .areExpansionsClosedByDefault,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "areExpansionsClosedByDefault",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.areExpansionsClosedByDefault"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "האם להציג הרחבות סגורות כברירת מחדל?"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.scrollToLawClause,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "scrollToLawClause",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.scrollToLawClause"
                                      }
                                    },
                                    [_vm._v("האם לגלול לסעיף חוק בשאלה?")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.hideTimeInExercise,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "hideTimeInExercise",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "exercise.hideTimeInExercise"
                                      }
                                    },
                                    [_vm._v("האם להסתיר זמן בתרגול?")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.exercise.hideAreYouSure,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.exercise,
                                            "hideAreYouSure",
                                            $$v
                                          )
                                        },
                                        expression: "exercise.hideAreYouSure"
                                      }
                                    },
                                    [_vm._v("האם להסתיר שאלה האם אתה בטוח?")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "exercise-save-buttons" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "new flex-2",
                                  attrs: { type: "is-info" },
                                  on: { click: _vm.saveExercise }
                                },
                                [_vm._v("שמירת שינויים")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "new flex-2",
                                  attrs: { type: "is-success", disabled: true },
                                  on: { click: _vm.newExercise }
                                },
                                [_vm._v("מחולל הבחינה")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "flex-1",
                                  on: { click: _vm.duplicateExercise }
                                },
                                [_vm._v("שכפול תרגול")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          : _vm._e(),
        _vm.tab === 2
          ? _c(
              "div",
              { staticClass: "questions__tab" },
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _vm.exerciseQuestions.length
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                gap: "10px",
                                "margin-bottom": "10px"
                              }
                            },
                            [
                              _c("div", { staticClass: "bold-text" }, [
                                _vm._v("שאלות בתרגול")
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticStyle: { height: "25px" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setHideAnswers(true)
                                    }
                                  }
                                },
                                [_vm._v("הסתר חלופות")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticStyle: { height: "25px" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setHideAnswers(false)
                                    }
                                  }
                                },
                                [_vm._v("הצג חלופות")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "new bold-text" },
                        [
                          _vm.isReOrderColumn
                            ? _c(
                                "b-button",
                                {
                                  attrs: { type: "is-info" },
                                  on: { click: _vm.saveNewQuestionsOrder }
                                },
                                [_vm._v("שמור")]
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.isReOrderColumn = !_vm.isReOrderColumn
                                }
                              }
                            },
                            [_vm._v("סדר מחדש")]
                          ),
                          _c("span", [
                            _vm._v(
                              " רמת קושי ממוצעת בתרגול: " +
                                _vm._s(_vm.averageDifficultyLevel || "---") +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "section-container" }, [
                    _c("small", [
                      _vm._v(
                        "מספר שאלות: " +
                          _vm._s(_vm.exerciseQuestions.length) +
                          " (*לא ניתן לסדר שאלות לתרגול פעיל)"
                      )
                    ]),
                    _vm.exerciseQuestions.length
                      ? _c(
                          "table",
                          { attrs: { id: "customers" } },
                          [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    width: "90px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("סדר")]
                              ),
                              _c("th", { staticStyle: { width: "100px" } }, [
                                _vm._v("ID שאלה")
                              ]),
                              _c("th", { staticStyle: { width: "780px" } }, [
                                _vm._v("שאלה")
                              ]),
                              _c("th", [_vm._v("הערות עורך")]),
                              _c("th", { staticStyle: { width: "80px" } }, [
                                _vm._v("רמת קושי")
                              ]),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    width: "55px"
                                  }
                                },
                                [_vm._v("עריכה")]
                              ),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    width: "55px"
                                  }
                                },
                                [_vm._v("מחיקה")]
                              ),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    width: "55px"
                                  }
                                },
                                [_vm._v("תצוגה")]
                              ),
                              _vm.isReOrderColumn
                                ? _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "80px"
                                      }
                                    },
                                    [_vm._v("סדר חדש")]
                                  )
                                : _vm._e()
                            ]),
                            _vm._l(_vm.exerciseQuestions, function(
                              question,
                              idx
                            ) {
                              return _c(
                                "tr",
                                { key: idx, staticClass: "row" },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "column-item",
                                      staticStyle: { width: "90px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "column-item flex",
                                          staticStyle: {
                                            "justify-content": "center"
                                          }
                                        },
                                        [
                                          !_vm.exercise.status
                                            ? _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setQuestionSortOrder(
                                                        question,
                                                        idx,
                                                        1
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-icon",
                                                    {
                                                      staticClass:
                                                        "sort__order__button",
                                                      attrs: {
                                                        size: "is-small",
                                                        icon: "arrow-down"
                                                      }
                                                    },
                                                    [_vm._v("+")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "sort__order__value"
                                            },
                                            [_vm._v(_vm._s(question.sortOrder))]
                                          ),
                                          !_vm.exercise.status
                                            ? _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setQuestionSortOrder(
                                                        question,
                                                        idx,
                                                        -1
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-icon",
                                                    {
                                                      staticClass:
                                                        "sort__order__button",
                                                      attrs: {
                                                        size: "is-small",
                                                        icon: "arrow-up"
                                                      }
                                                    },
                                                    [_vm._v("-")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "column-item",
                                      staticStyle: { width: "100px" }
                                    },
                                    [
                                      _vm._v(_vm._s(question.questionId) + " "),
                                      question.tooltipMessage &&
                                      !_vm.exercise.isArchived
                                        ? _c(
                                            "span",
                                            { staticClass: "exercise-tooltip" },
                                            [
                                              _c(
                                                "b-tooltip",
                                                {
                                                  attrs: {
                                                    type: "is-white",
                                                    label:
                                                      question.tooltipMessage
                                                  }
                                                },
                                                [
                                                  _c("b-icon", {
                                                    staticStyle: {
                                                      color: "#a94442"
                                                    },
                                                    attrs: {
                                                      icon: "info-circle",
                                                      pack: "fas"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c("td", {
                                    staticClass: "column-item ellipsis-div",
                                    staticStyle: { width: "780px" },
                                    domProps: {
                                      innerHTML: _vm._s(question.questionName)
                                    }
                                  }),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "column-item align-justify ellipsis-div"
                                    },
                                    [_vm._v(_vm._s(question.editorNotes))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "column-item align-justify",
                                      staticStyle: { width: "80px" }
                                    },
                                    [_vm._v(_vm._s(question.difficultyLevel))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "55px"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "הגדרת שאלה"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#0091ae"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.goToQuestion(
                                                    question.questionId
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "pen" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "55px"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "מחק שאלה"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "red" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeQuestionFromExercise(
                                                    question.questionId
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "delete" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "55px"
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "updateItem",
                                          staticStyle: { color: "gray" },
                                          on: {
                                            click: function($event) {
                                              return _vm.previewQuestion(
                                                question.questionId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "eye" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm.isReOrderColumn
                                    ? _c(
                                        "td",
                                        {
                                          staticClass: "column-item",
                                          staticStyle: {
                                            width: "80px",
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: question.newSortOrder,
                                                expression:
                                                  "question.newSortOrder"
                                              }
                                            ],
                                            staticStyle: { width: "25px" },
                                            attrs: { type: "number" },
                                            domProps: {
                                              value: question.newSortOrder
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  question,
                                                  "newSortOrder",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("b-field", { attrs: { label: "בחר שאלה" } }, [
                        _c(
                          "div",
                          { staticClass: "flex", staticStyle: { gap: "15px" } },
                          [
                            _c("b-input", {
                              attrs: { placeholder: "חיפוש חופשי" },
                              model: {
                                value: _vm.filterSearch.Terms,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterSearch, "Terms", $$v)
                                },
                                expression: "filterSearch.Terms"
                              }
                            }),
                            _c(
                              "b-button",
                              {
                                staticStyle: { height: "34px" },
                                attrs: { type: "is-info" },
                                on: {
                                  click: function($event) {
                                    _vm.isFiltersShown = !_vm.isFiltersShown
                                  }
                                }
                              },
                              [_vm._v("מתקדם")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticStyle: { height: "34px" },
                                on: { click: _vm.onSearchNewQuestions }
                              },
                              [_vm._v("חיפוש")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm.isFiltersShown
                        ? _c("div", { staticClass: "filters" }, [
                            _c(
                              "div",
                              { staticClass: "flex space-between" },
                              [
                                _c("b-input", {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    placeholder: "עונים מינימלית",
                                    type: "number"
                                  },
                                  model: {
                                    value:
                                      _vm.filterSearch.MinimumStudentAnswers,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filterSearch,
                                        "MinimumStudentAnswers",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filterSearch.MinimumStudentAnswers"
                                  }
                                }),
                                _c(
                                  "b-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.filterSearch.HasOpenRequestsOnly,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filterSearch,
                                          "HasOpenRequestsOnly",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filterSearch.HasOpenRequestsOnly"
                                    }
                                  },
                                  [_vm._v("שאלות עם פניות פתוחות")]
                                ),
                                _c(
                                  "b-checkbox",
                                  {
                                    model: {
                                      value: _vm.filterSearch.IsActive,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filterSearch,
                                          "IsActive",
                                          $$v
                                        )
                                      },
                                      expression: "filterSearch.IsActive"
                                    }
                                  },
                                  [_vm._v("פעילות בלבד")]
                                ),
                                _c(
                                  "b-checkbox",
                                  {
                                    model: {
                                      value: _vm.filterSearch.IsInactive,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filterSearch,
                                          "IsInactive",
                                          $$v
                                        )
                                      },
                                      expression: "filterSearch.IsInactive"
                                    }
                                  },
                                  [_vm._v("לא פעילות בלבד")]
                                ),
                                _c(
                                  "b-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.filterSearch.HasEditorNotesOnly,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filterSearch,
                                          "HasEditorNotesOnly",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filterSearch.HasEditorNotesOnly"
                                    }
                                  },
                                  [_vm._v("שאלות עם הערות עורך")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex space-between",
                                staticStyle: { "margin-top": "10px" }
                              },
                              [
                                _c("FieldInlineSelect", {
                                  staticClass: "search-select",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "hide-hover": true,
                                    label: "",
                                    clearable: true,
                                    filterable: false,
                                    optionsAsync: _vm.getQuestionCategories,
                                    placeholder: "קטגוריות"
                                  },
                                  on: { onChange: _vm.onChange },
                                  model: {
                                    value: _vm.filterSearch.QuestionCategoryIds,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filterSearch,
                                        "QuestionCategoryIds",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filterSearch.QuestionCategoryIds"
                                  }
                                }),
                                _c("FieldInlineSelect", {
                                  staticClass: "search-select",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "hide-hover": true,
                                    label: "",
                                    clearable: true,
                                    filterable: false,
                                    optionsAsync: _vm.getAcademicInstitude,
                                    placeholder: "מוסד אקדמי"
                                  },
                                  on: { onChange: _vm.onChange },
                                  model: {
                                    value: _vm.filterSearch.AcademicInstituteId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filterSearch,
                                        "AcademicInstituteId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filterSearch.AcademicInstituteId"
                                  }
                                }),
                                _c("FieldInlineSelect", {
                                  staticClass: "search-select",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "hide-hover": true,
                                    label: "",
                                    clearable: true,
                                    filterable: false,
                                    optionsAsync: _vm.getAuthors,
                                    placeholder: "מחבר"
                                  },
                                  on: { onChange: _vm.onChange },
                                  model: {
                                    value: _vm.filterSearch.AuthorId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filterSearch,
                                        "AuthorId",
                                        $$v
                                      )
                                    },
                                    expression: "filterSearch.AuthorId"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.newQuestionIds.length
                        ? _c(
                            "div",
                            { staticClass: "new-questions" },
                            _vm._l(_vm.newQuestionIds, function(question) {
                              return _c(
                                "div",
                                {
                                  key: question.id,
                                  staticClass: "new-question flex"
                                },
                                [
                                  _c("b-checkbox", {
                                    model: {
                                      value: question.isChecked,
                                      callback: function($$v) {
                                        _vm.$set(question, "isChecked", $$v)
                                      },
                                      expression: "question.isChecked"
                                    }
                                  }),
                                  _c("div", [_vm._v(_vm._s(question.id))]),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(question.question)
                                    }
                                  }),
                                  _c("div", [
                                    _vm._v(_vm._s(question.editorNotes))
                                  ])
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.newQuestionsChecked.length
                        ? _c(
                            "b-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              on: { click: _vm.addNewQuestionsToExercise }
                            },
                            [_vm._v(" הוסף שאלות לתרגול ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 3
          ? _c("div", [
              _vm.exerciseStatistics
                ? _c("div", { staticClass: "exercise-statistics" }, [
                    _c(
                      "div",
                      { staticClass: "exercise-statistics-query" },
                      [
                        _c("FieldInlineDate", {
                          attrs: { label: "" },
                          on: { onChange: _vm.onChange },
                          model: {
                            value: _vm.exerciseStatisticsFrom,
                            callback: function($$v) {
                              _vm.exerciseStatisticsFrom = $$v
                            },
                            expression: "exerciseStatisticsFrom"
                          }
                        }),
                        _c("FieldInlineDate", {
                          attrs: { label: "" },
                          on: { onChange: _vm.onChange },
                          model: {
                            value: _vm.exerciseStatisticsTo,
                            callback: function($$v) {
                              _vm.exerciseStatisticsTo = $$v
                            },
                            expression: "exerciseStatisticsTo"
                          }
                        }),
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-info" },
                            on: { click: _vm.loadExerciseStatistics }
                          },
                          [_vm._v("חפש")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "exercise-statistics-content" }, [
                      _c("div", { staticClass: "item" }, [
                        _vm._v(
                          " מספר מתרגלים שסיימו " +
                            _vm._s(_vm.exerciseStatistics.studentsExercise) +
                            " מתוך " +
                            _vm._s(_vm.exerciseStatistics.totalStudents) +
                            " "
                        ),
                        _c("div", [
                          _c("progress", {
                            attrs: {
                              dir: "ltr",
                              id: "file",
                              max: _vm.exerciseStatistics.totalStudents
                            },
                            domProps: {
                              value: _vm.exerciseStatistics.studentsExercise
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _vm._v(
                          " ממוצע ציונים: " +
                            _vm._s(
                              _vm.exerciseStatistics.grade
                                ? _vm.exerciseStatistics.grade.toFixed(2)
                                : 0
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _vm._v(
                          " זמן תרגול ממוצע: " +
                            _vm._s(_vm.exerciseStatistics.averageTime) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _vm._v(
                          " המזהה לשאלה עם כמות השגיאות הגדולה ביותר: " +
                            _vm._s(_vm.exerciseStatistics.maxErrorQuestion) +
                            " "
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  position: "is-bottom",
                                  multilined: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "content",
                                      fn: function() {
                                        return [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.exerciseStatistics
                                                  .questionDescription
                                              )
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3193756037
                                )
                              },
                              [
                                _c("b-button", {
                                  attrs: { label: "לפרטים", dashed: "" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._m(0),
                      _c("div", { staticClass: "item" }, [
                        _vm._v(
                          " אחוז הזנה אונליין: " +
                            _vm._s(_vm.exerciseStatistics.onlineInsertPercent) +
                            " "
                        ),
                        _c("div", [
                          _vm._v(
                            " אחוז הזנה בדיעבד: " +
                              _vm._s(
                                _vm.exerciseStatistics.offlineInsertPercent
                              ) +
                              " "
                          )
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _vm._v(" ייצוא נתונים לאקסל "),
      _c("div", [_c("button", [_vm._v("יצוא")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }