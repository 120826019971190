<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>לוח הודעות</template>
      <template #button>
        <a @click="addCourseMessage" class="link-button">
          הוספת מודעה
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import EntityListLayout from "@/components/EntityListLayout.vue";
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";
import CoursesService from "@/services/CoursesService";
import FilterService from "@/services/FilterService";
import AdminService from "../services/AdminService";
import DialogCourseMessage from "../components/DialogCourseMessage.vue";
import TableActions from "@/services/TableActionsService";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("courseMessages");
      const reportsfilters = FilterService.list();

      // const sideViewEntity = {
      //   component: CourseMessageEntity,
      //   preview: true,
      //   readOnly: false,
      //   title: "פרטי הודעה",
      //   recordUrl: "user",
      //   hideRecord: true
      // };

      // const excelExport = [
      //   {
      //     title: "יצוא טבלה",
      //     action: this.export,
      //   },
      // ];
      const fields = [
        { ...reportsFields.messageTitle, checked: true },
        { ...reportsFields.messageBody, checked: true },
        { ...reportsFields.messageAuthor, checked: true },
        { ...reportsFields.displayFromDate, checked: true },
        { ...reportsFields.displayToDate, checked: true },
        { ...reportsFields.readCount, checked: true },
        { ...reportsFields.showToGroupId, checked: true },
        { ...reportsFields.groupName, checked: true },
        { ...reportsFields.status, checked: true },
      ];

      this.tabs = [
        {
          id: "AllCourseMessages",
          title: "כל ההודעות",
          getDataAsync: this.getAllCourseMessages,
          perPage: 100,
          fields,
          filters: [
            {
              ...reportsfilters.SearchFilterType,
              ...reportsfilters.Groups,
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: false,
          // tableActions: [
          //   TableActions.deleteReports,
          // ],
          // excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש הודעה",
          },
          // sideViewEntity,
          sideViewModal: DialogCourseMessage,
          isAdminArea: true,
          defaultQuery: false
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.getData(this.tabs[0])
    },
    // export({ loading, query }) {
    //   loading(true);
    //   CoursesService.exportUsers(this.$store.state.auth.course?.id, query)
    //     .then((r) => {
    //       this.saveFile(r.data, "יצוא משתמשים ממערכת SimAdmin");
    //     })
    //     .finally(() => {
    //       loading(false);
    //     });
    // },
    getAllCourseMessages({ ...rest }) {
      return AdminService.getAdminCourseMessages(this.$store.state.auth.course?.id, rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    // saveFile(data, filename) {
    //   const blob = new Blob([data], { type: this.excelMimeType });
    //   saveAs(blob, `${filename}.xlsx`);
    // },
    loadCourseMessages() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    addCourseMessage() {
      Modal.open({
        component: DialogCourseMessage,
        props: {
          store: this.$store,
          onSuccess: this.onNewEntitySave
        },
        events: {
          customEvent: () => {
            this.loadCourseMessages();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #00427e;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
