<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>תקצירי פסיקה</template>
      <template #button>
        <a @click="addVerdictSummary" class="link-button">
           תקציר פסיקה חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import VerdictSummaryEntity from "@/views/VerdictSummaryEntity.vue";
import DialogAddVerdictSummary from '@/components/DialogAddVerdictSummary.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("verdicts");

      const sideViewEntity = {
        component: VerdictSummaryEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי תקציר פסיקה",
        recordUrl: "lessons",
        recordTitle: "הצג תקציר פסיקה",
        hideRecord: true
      };
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.Name, checked: true },
      ];

      this.tabs = [
        {
          id: "AllVerdictsReplication",
          isPrimary: true,
          title: "כל התקצירים",
          getDataAsync: this.getAllVerdictSummaries,
          perPage: 100,
          fields,
          filters: null,
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewEntity,
        },
      ];
    },
    addVerdictSummary() {
      Modal.open({
        component: DialogAddVerdictSummary,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllVerdictSummaries({ ...rest }) {
      return AdminService.getAdminVerdictSummaries(rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style lang="scss">
.Reports__View {
  height: 100%;
}
.link-button {
  background: #00427e;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
.not-activated-row {
  background-color: #fff !important;
}
</style>
