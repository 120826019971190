<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>בנק וידאו</template>
      <template #button>
        <a @click="addVideoToBank" class="link-button">
          וידאו חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import DialogAddVideoToBank from '@/components/DialogAddVideoToBank.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
// import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import VideoBankEntity from "./VideoBankEntity.vue";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("videos");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: VideoBankEntity,
        preview: true,
        readOnly: false,
        title: "פרטי וידאו",
        recordTitle: 'הצג שאלה',
        hideRecord: true,
        recordUrl: "videoBank",
      };
      const fields = [
        { ...reportsFields.Id, checked: false },
        { ...reportsFields.Name, checked: true },
        { ...reportsFields.Link1, checked: true },
        { ...reportsFields.ExerciseCategory, checked: true },
        { ...reportsFields.VideoType, checked: true },
        { ...reportsFields.Status, checked: true },
      ];

      this.tabs = [
        {
          id: "AllVideosAdmin",
          title: "כל הוידאוים",
          getDataAsync: this.getAllVideos,
          perPage: 100,
          fields,
          filters: [
          { ...reportsfilters.ExerciseCategoryIds },
          { ...reportsfilters.VideoTypeIds },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש סרטון",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllVideos({ cancelToken, ...rest }) {
      return AdminService.getVideosInBank(
        {
          ...rest,
          IsAscending: false
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    addVideoToBank() {
      Modal.open({
        component: DialogAddVideoToBank,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #00427e;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
