<template>
  <div class="Activity__Component">
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadUserExercises()" :class="{ active: tab === 1 }">
        תרגולים
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadUserGroups()" :class="{ active: tab === 2 }">
        קבוצות
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadUserRemarks()" :class="{ active: tab === 3 }">
        יומן מלווה לתלמיד <span v-if="remarksCount" class="remarks__count">{{ remarksCount }}</span>
      </div>
    </div>
    <div class="Activity__Component__TabsContent">
      <!-- <new-activity ref="noteActivity" v-if="showNewNote" type="Note" @onClose="close" @onSave="saveNote" />
      <new-activity ref="taskActivity" v-if="showNewTask" type="Task" show-title="true" @onClose="closeTask"
        @onSave="saveTask" /> -->
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="new" @click="openSimulator">הפניה לסימולטור</button>
          </div>
          <div v-if="!isLoading && exercises && exercises.length" class="section-container">
            <h1>התרגולים של <b>{{ userName }}</b> בקורס {{ course.name }}</h1>
            <div v-if="lessonsWithExercises.length" class="exercise__lessons">
              <div @click="filterExercisesByLessonId(exerciseLesson.id)" class="exercise_lesson_btn" :style="{ backgroundColor: getLessonColor(exerciseLesson.btnColor) }" v-for="exerciseLesson, idx in lessonsWithExercises" :key="idx">
                <div>{{ exerciseLesson.name }} <span class="badge">{{ exerciseLesson.exerciseCount }}</span></div>
              </div>
              <div style="background-color: white; margin-right: auto;" @click="filterExercisesByLessonId(null)" class="exercise_lesson_btn">
                <div>לכל התרגולים בכל היחידות</div>
              </div>
            </div>
            <span style="margin-right: auto;">{{ exercises.length }} רשומות סה"כ</span>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  תרגול
                </div>
                <div class="column">
                  ציון
                </div>
                <div class="column">
                  טווח תקין
                </div>
                <div class="column">
                  אחוז כוכביות
                </div>
                <div class="column">
                  ציון כוכביות
                </div>
                <div class="column">
                  אחוז אפורות
                </div>
                <div class="column">
                  ציון אפורות
                </div>
                <div class="column">
                  אחוזון
                </div>
                <div class="column">
                  תאריך
                </div>
                <div class="column">
                  פתרון
                </div>
                <div class="column">
                  שאלון
                </div>
                <div class="column">
                  תוחקר
                </div>
                <div class="column">
                  איפוס
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div class="row" v-for="(exercise, idx) in exercises" :key="idx">
                <div class="column-item">{{ exercise.exerciseName }}</div>
                <div class="column-item">{{ Math.floor(exercise.grade) }}</div>
                <div class="column-item">{{ exercise.madadTakinEditor }}</div>
                <div class="column-item">{{ exercise.starPercent }}</div>
                <div class="column-item">{{ exercise.starGrade }}</div>
                <div class="column-item">{{ exercise.grayPercent }}</div>
                <div class="column-item">{{ exercise.grayGrade }}</div>
                <div class="column-item">{{ exercise.ahuzon }}</div>
                <div class="column-item">{{ parseDate(exercise.endDateTime) }}</div>
                <div @click="getPrintExerciseUrl(exercise.exerciseId, true)">
                  <b-tooltip type="is-white" label="הדפס פתרון">
                    <a style="color: green;" class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
                <div @click="getPrintExerciseUrl(exercise.exerciseId)">
                  <b-tooltip type="is-white" label="הדפס שאלון">
                    <a class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
                <div class="column-item"><input @click="updateExercise(exercise, exercise.exerciseId)"
                    v-model="exercise.wasResearched" type="checkbox"></div>
                <div @click="resetUserExercise(exercise)">
                  <b-tooltip type="is-white" label="איפוס תרגול">
                    <a style="color: red;" class="updateItem">
                      <b-icon icon="delete"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 2">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <!-- <button class="new" @click="newRemark">הוספת הערה</button> -->
            <b-button type="is-primary" class="new" @click="editAllStudentsExpirationDate(studentGroups)">עריכת תאריך תפוגה לתלמידים</b-button>
          </div>
          <div v-if="!isLoading && studentGroups && studentGroups.length" class="section-container">
            <h1>קבוצות המשויכות לתלמיד {{ userName }} בקורס {{ course.name }}</h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  שם קבוצה
                </div>
                <div class="column">
                  תאריך תפוגה
                </div>
                <div style="text-align: center;" class="column">
                  מחיקת קבוצה מתלמיד
                </div>
                <div style="text-align: center;" class="column">
                  עריכת תאריך תפוגה
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div class="row" v-for="(group, idx) in studentGroups" :key="idx">
                <div class="column-item">{{ group.groupName }}</div>
                <div class="column-item">{{ parseExpirationDate(group.groupStudentExpirationDate) }}</div>
                <div style="text-align: center;" @click="removeStudentFromGroup(group)">
                  <b-tooltip type="is-white" label="מחיקה">
                    <a style="color: red;" class="updateItem">
                      <b-icon icon="delete"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
                <div style="text-align: center;" @click="editStudentExpirationDate(group)">
                  <b-tooltip type="is-white" label="עריכה">
                    <a style="color: blue;" class="updateItem">
                      <b-icon icon="pen"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 3">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="new" @click="newRemark">הוספת הערה</button>
          </div>
          <div v-if="!isLoading && studentRemarks && studentRemarks.length" class="section-container">
            <h1>יומן מלווה לתלמיד {{ userName }} בקורס {{ course.name }}</h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  על ידי
                </div>
                <div class="column">
                  נושא
                </div>
                <div class="column">
                  תאריך
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div class="row" v-for="(remark, idx) in studentRemarks" :key="idx">
                <div class="column-item">{{ remark.byUserName }}</div>
                <div v-html="remark.contentToDisplay" class="column-item"></div>
                <div class="column-item">{{ parseDate(remark.dateCreated) }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { ModalProgrammatic as Modal, LoadingProgrammatic as Loading } from "buefy";
import DialogAddRemark from "./DialogAddRemark.vue";
import DialogAddLaw from "./DialogAddLaw.vue";
import DialogAddLessonVideo from "./DialogAddLessonVideo.vue";
import UserService from "../services/UserService";
import DialogAddVerdict from "./DialogAddVerdict.vue";
import LessonService from "../services/LessonService";
import DialogEditGroupInStudent from "./DialogEditGroupInStudent.vue";

export default {
  name: "Activity",
  components: {
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  // updated() {
  //   const params = window.location.search.substring(1);
  //   const lastIdx = params.lastIndexOf("=");
  //   const taskId = params.slice(lastIdx + 1);
  //   const task = this.$refs[`taskElement${taskId}`][0];
  //   task.isOpen = true;
  //   this.$nextTick(() => {
  //     task.$el.scrollIntoView({ behavior: "smooth" });
  //   });
  // },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    lesson() {
      return this.$store.state.lesson;
    },
    userName() {
      return `${this.$store.state.user.privateName} ${this.$store.state.user.lastName}`;
    },
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      isLoading: false,
      exercises: null,
      origData: [],
      lessonsWithExercises: [],
      studentRemarks: null,
      studentGroups: null,
      remarksCount: null
    };
  },
  created() {
    this.loadUserExercises();
    this.getUserRemarksCount();
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    getLessonColor(value) {
      if (value == null) return '#fff';
      else return value.split(';')[0];
    },
    filterExercisesByLessonId(id) {
      if (!id) this.exercises = this.origData;
      else {
        this.exercises = this.origData.filter(e => e.lessonId === id);
      }
    },
    getPrintExerciseUrl(id, isShowAnswers) {
      if (isShowAnswers) window.open(`/course/${this.course.id}/exercisePrint/${id}?showAnswers=true`, '_blank');
      else window.open(`/course/${this.course.id}/exercisePrint/${id}`, '_blank');
    },
    removeStudentFromGroup(group) {
      this.isLoading = true;
      LessonService.deleteStudentInGroup(group.groupId, this.entityId)
        .then(() => {
          this.loadUserGroups();
        }).catch(() => {
        }).finally(() => {
          this.isLoading = false;
        });
    },
    editStudentExpirationDate(group) {
      Modal.open({
        component: DialogEditGroupInStudent,
        customClass: "entity-modal",
        props: {
          student: this.$store.state.user,
          group: group
        },
        events: {
          customEvent: () => {
            this.loadUserGroups();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    editAllStudentsExpirationDate(groups) {
      Modal.open({
        component: DialogEditGroupInStudent,
        customClass: "entity-modal",
        props: {
          groupIds: groups.map((g) => g.groupId),
          student: this.$store.state.user,
        },
        events: {
          customEvent: () => {
            this.loadUserGroups();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    parseExpirationDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : null;
    },
    resetUserExercise(exercise) {
      this.$buefy.dialog.confirm({
        title: 'איפוס תרגול',
        message: 'האם לאפס את התרגול למשתמש?',
        confirmText: 'כן',
        cancelText: 'לא',
        type: 'is-danger',
        closeOnConfirm: false,
        hasIcon: true,
        onConfirm: (a, instance) => {
          const loading = Loading.open();
          LessonService.resetUserExercise(exercise.exerciseId, exercise.userId).then(() => {
            instance.close();
            this.$buefy.toast.open({
              type: "is-success",
              message: `התרגול אופס בהצלחה!`,
              duration: 4000,
            });
          }).catch(() => {
            this.$buefy.toast.open({
              type: "is-danger",
              message: `הפעולה נכשלה!`,
              duration: 8000,
            });
          }).finally(() => {
            loading.close();
          });
        },
      });
    },
    openSimulator() {
      window.open(`${process.env.VUE_APP_URL}?StudentId=${this.entityId}`, '_blank');
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    newRemark() {
      Modal.open({
        component: DialogAddRemark,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          onSuccess: this.loadUserRemarks
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonLaw() {
      Modal.open({
        component: DialogAddLaw,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonVerdict() {
      Modal.open({
        component: DialogAddVerdict,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonVideo() {
      Modal.open({
        component: DialogAddLessonVideo,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    loadUserExercises() {
      this.isLoading = true;
      LessonService.getExerciseAnswers(this.course.id, {
        UserIds: [this.entityId],
        PageSize: 999
      }).then((r) => {
        this.exercises = r.data.items;
        this.origData = [...r.data.items];
        this.lessonsWithExercises = r.data.lessonsWithExercises;
      })
        .finally(() => {
          this.tab = 1;
          this.isLoading = false;
        });
    },
    // loadUserExercises() {
    //   this.isLoading = true;
    //   UserService.getUserExercises(this.entityId, this.course.id).then((r) => {
    //     this.exercises = r.data;
    //     this.origData = [...r.data];
    //     // this.lessonsWithExercises = r.data.lessonsWithExercises;
    //   })
    //     .finally(() => {
    //       this.tab = 1;
    //       this.isLoading = false;
    //     });
    // },
    getUserRemarksCount() {
      UserService.getUserRemarksCount(this.course.id, this.entityId)
        .then((r) => {
          this.remarksCount = r.data;
        })
    },
    updateExercise(exercise, exerciseId) {
      this.isLoading = true;
      UserService.updateUserExercise(this.entityId, exerciseId, this.course.id)
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: `התרגול תוחקר בהצלחה!`,
            duration: 4000,
          });
          exercise.wasResearched = !exercise.wasResearched;
        }).catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: `הפעולה נכשלה!`,
            duration: 8000,
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadUserGroups() {
      this.isLoading = true;
      const courseId = this.$store.state.auth.course ? this.$store.state.auth.course.id : null;
      UserService.getUserGroups(this.entityId, courseId)
        .then((r) => {
          this.studentGroups = r.data;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadUserRemarks() {
      this.isLoading = true;
      UserService.getUserRemarks(this.course.id, this.entityId)
        .then((r) => {
          this.studentRemarks = r.data;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      UserService.getAdminVerdicts(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped lang="scss">
.remarks__count {
  background: #0c2949;
  border-radius: 50%;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  position: relative;
  bottom: 10px;
}
.exercise__lessons {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  gap: 10px;
  flex-wrap: wrap;
}
.exercise_lesson_btn {
  // padding: 5px;
  cursor: pointer;
  width: 323px;
}
.exercise_lesson_btn :hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
.exercise_lesson_btn div {
  padding: 5px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.badge {
  display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}
.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #eef1f3;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
