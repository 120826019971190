var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("מופעים לוידאו: " + _vm._s(_vm.name))
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _vm.appearances.length
          ? _c(
              "table",
              { attrs: { id: "customers" } },
              [
                _vm._m(0),
                _vm._l(_vm.appearances, function(appearance, idx) {
                  return _c("tr", { key: idx, staticClass: "row" }, [
                    _c("td", { staticClass: "column-item" }, [
                      _vm._v(_vm._s(appearance.courseName))
                    ]),
                    _c("td", { staticClass: "column-item" }, [
                      _vm._v(_vm._s(appearance.lessonName))
                    ])
                  ])
                })
              ],
              2
            )
          : _c("div", [_vm._v("אין מופעים לסרטון זה")])
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("קורס")]),
      _c("th", [_vm._v("יחידת לימוד")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }