var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(_vm._s(!_vm.row ? "הוספת" : "עדכון") + " הודעה ללוח")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body", staticStyle: { overflow: "auto" } },
        [
          _c(
            "div",
            { staticClass: "DialogReportPaid__Component__Loading" },
            [
              _c(
                "b-field",
                { attrs: { label: "הזן כותרת" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.model.messageTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "messageTitle", $$v)
                      },
                      expression: "model.messageTitle"
                    }
                  })
                ],
                1
              ),
              _c("FieldInlineDateTime", {
                attrs: {
                  label: "מתאריך",
                  "hide-hover": true,
                  position: "is-bottom-left"
                },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.displayFromDate,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "displayFromDate", $$v)
                  },
                  expression: "model.displayFromDate"
                }
              }),
              _c("FieldInlineDateTime", {
                attrs: {
                  label: "עד תאריך",
                  "hide-hover": true,
                  position: "is-bottom-left"
                },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.displayToDate,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "displayToDate", $$v)
                  },
                  expression: "model.displayToDate"
                }
              }),
              _c(
                "b-field",
                { attrs: { label: "הזן מחבר" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.model.messageAuthor,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "messageAuthor", $$v)
                      },
                      expression: "model.messageAuthor"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "יוצג רק לקבוצה מספר" } },
                [
                  _c("FieldInlineSelect", {
                    attrs: {
                      filterable: true,
                      clearable: true,
                      optionsAsync: _vm.getGroups
                    },
                    on: { onChange: _vm.onChange },
                    model: {
                      value: _vm.model.showToGroupId,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "showToGroupId", $$v)
                      },
                      expression: "model.showToGroupId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "תוכן" } },
                [
                  _c("ckeditor", {
                    attrs: { editor: _vm.editor, config: _vm.editorConfig },
                    model: {
                      value: _vm.model.messageBody,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "messageBody", $$v)
                      },
                      expression: "model.messageBody"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "סטטוס" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      staticClass: "checkbox",
                      staticStyle: { gap: "10px" },
                      attrs: { label: "", type: "is-link is-light" },
                      model: {
                        value: _vm.model.status,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "status", $$v)
                        },
                        expression: "model.status"
                      }
                    },
                    [_vm._v(" פעיל ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              disabled: !_vm.isValidForm,
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _vm.row
            ? _c("b-button", {
                attrs: {
                  loading: _vm.isLoading,
                  label: "שכפל",
                  type: "is-info"
                },
                on: { click: _vm.duplicate }
              })
            : _vm._e(),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }