var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "DialogReportPaid__Component",
      staticStyle: { height: "100%" }
    },
    [
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v("ניהול פנייה " + _vm._s(_vm.row.id))
          ]),
          _c("button", {
            staticClass: "delete",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ]),
        _c(
          "section",
          { staticClass: "modal-card-body", staticStyle: { overflow: "auto" } },
          [
            _vm.isLoading
              ? [
                  _c(
                    "div",
                    { staticClass: "loading" },
                    [
                      _c("b-loading", {
                        attrs: { "is-full-page": false, "can-cancel": false },
                        model: {
                          value: _vm.isLoading,
                          callback: function($$v) {
                            _vm.isLoading = $$v
                          },
                          expression: "isLoading"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c("div", { staticClass: "DialogChat__Component__Loading" }, [
              _c("div", { staticStyle: { width: "50%" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", gap: "10px" } },
                  [
                    _c("div", [
                      _c("strong", [_vm._v("מספר פנייה:")]),
                      _vm._v(" " + _vm._s(_vm.row.id))
                    ]),
                    _c("div", [
                      _c("strong", [_vm._v("תאריך פתיחה:")]),
                      _vm._v(" " + _vm._s(_vm.parseDate(_vm.row.createdDate)))
                    ]),
                    _vm.studentRemarks.length
                      ? _c(
                          "b-tooltip",
                          {
                            staticStyle: { "margin-right": "auto" },
                            attrs: {
                              position: "is-bottom",
                              type: "is-white",
                              label: _vm.studentRemarks
                            }
                          },
                          [
                            _c("b-icon", {
                              staticStyle: { color: "#a94442" },
                              attrs: { icon: "info-circle", pack: "fas" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticStyle: { display: "flex", gap: "10px" } }, [
                  _c("div", [
                    _c("strong", [_vm._v("שם התלמיד:")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          "text-decoration": "underline",
                          color: "#0065ff",
                          cursor: "pointer"
                        },
                        attrs: {
                          href: "user/" + _vm.row.userId,
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.row.fullname))]
                    )
                  ]),
                  _c("div", [
                    _c("strong", [_vm._v("טלפון:")]),
                    _vm._v(" " + _vm._s(_vm.row.phone))
                  ]),
                  _c("div", [
                    _c("strong", [_vm._v("מייל:")]),
                    _vm._v(" " + _vm._s(_vm.row.email))
                  ]),
                  _vm.groupsIndication
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.groupsIndication.isInEscort ||
                                _vm.groupsIndication.isInInterval,
                              expression:
                                "groupsIndication.isInEscort || groupsIndication.isInInterval"
                            }
                          ],
                          staticClass: "group_indication"
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.groupsIndication.isInEscort,
                                  expression: "groupsIndication.isInEscort"
                                }
                              ],
                              staticClass: "group_box",
                              staticStyle: { "border-left": "1px solid black" }
                            },
                            [_vm._v("ל")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.groupsIndication.isInInterval,
                                  expression: "groupsIndication.isInInterval"
                                }
                              ],
                              staticClass: "group_box"
                            },
                            [_vm._v("א")]
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _c("div", { staticStyle: { display: "flex", gap: "10px" } }, [
                  _c("div", [
                    _c("strong", [_vm._v("כמות פניות במענה המקצועי השבוע:")]),
                    _vm._v(_vm._s(_vm.lastWeekRequestsCount))
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      gap: "10px",
                      "align-items": "center",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c("div", [
                      _c("strong", [_vm._v("מקור הפנייה:")]),
                      _vm._v(" " + _vm._s(_vm.row.sentFromPage))
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          gap: "5px"
                        }
                      },
                      [
                        _c("strong", [_vm._v("סטטוס:")]),
                        _c("FieldInlineSelect", {
                          staticClass: "input_select",
                          attrs: {
                            label: "",
                            hideHover: true,
                            filterable: false,
                            clearable: false,
                            options: [
                              { Value: 1, Text: "חדשה" },
                              { Value: 2, Text: "בטיפול" },
                              { Value: 3, Text: "הסתיימה" }
                            ]
                          },
                          on: { onChange: _vm.onChange },
                          model: {
                            value: _vm.row.status,
                            callback: function($$v) {
                              _vm.$set(_vm.row, "status", $$v)
                            },
                            expression: "row.status"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "margin-top": "5px",
                      gap: "5px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("בנושא:")]),
                    _c("FieldInlineSelect", {
                      staticClass: "input_select",
                      staticStyle: { width: "100%" },
                      attrs: {
                        hideHover: true,
                        filterable: false,
                        clearable: false,
                        optionsAsync: _vm.getRequestMessageTypes
                      },
                      on: { onChange: _vm.onChange },
                      model: {
                        value: _vm.row.messagesInCourseTypesId,
                        callback: function($$v) {
                          _vm.$set(_vm.row, "messagesInCourseTypesId", $$v)
                        },
                        expression: "row.messagesInCourseTypesId"
                      }
                    })
                  ],
                  1
                ),
                _c("p", [
                  _vm._v(
                    "* התלמיד יכול להגיב רק על פנייה בסטטוס בטיפול שהתגובה האחרונה היא של צוות הקורס"
                  )
                ]),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "responses" },
                    _vm._l(_vm.responses, function(response, idx) {
                      return _c(
                        "div",
                        {
                          key: response.id,
                          staticClass: "response",
                          class: response.isAdminResponse
                            ? "admin-response"
                            : "user-response"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "response-seen" },
                            [
                              response.isViewedByStudent
                                ? _c("b-icon", {
                                    attrs: {
                                      title: "נצפה",
                                      pack: "fa",
                                      icon: "eye"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.isEditModal !== idx
                            ? _c("div", [
                                _c("div", {
                                  domProps: { innerHTML: _vm._s(response.text) }
                                }),
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "14px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseDate(response.createdDate)
                                      )
                                    )
                                  ]
                                )
                              ])
                            : _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: response.text,
                                    expression: "response.text"
                                  }
                                ],
                                attrs: { type: "text", name: "", id: "" },
                                domProps: { value: response.text },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      response,
                                      "text",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("div", { staticClass: "response-buttons" }, [
                            response.isAdminResponse
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteMessage(response.id)
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "delete",
                                      attrs: {
                                        title: "מחק",
                                        pack: "fa",
                                        icon: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            response.isAdminResponse
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openEditModal(idx)
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "pen",
                                      attrs: {
                                        title: "ערוך",
                                        pack: "fa",
                                        icon: "pen"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isEditModal === idx
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-right": "auto"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editResponse(
                                          response.id,
                                          response.text
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "save",
                                      attrs: {
                                        title: "ערוך",
                                        pack: "fa",
                                        icon: "save"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "ActivityComment__Component__Box__Content" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "ActivityComment__Component__Box__Content__Edit",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            gap: "10px"
                          }
                        },
                        [
                          _c("ckeditor", {
                            attrs: {
                              editor: _vm.editor,
                              placeholder: "'חומרי עזר'",
                              config: _vm.editorConfig
                            },
                            model: {
                              value: _vm.newMessage,
                              callback: function($$v) {
                                _vm.newMessage = $$v
                              },
                              expression: "newMessage"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                loading: _vm.isLoading,
                                type: "is-primary"
                              },
                              on: { click: _vm.save }
                            },
                            [_vm._v("שלח")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ]),
              _c(
                "div",
                { staticStyle: { width: "50%" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("strong", [_vm._v("תיוג צוות פניות:")]),
                      _c(
                        "v-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: {
                            dir: "rtl",
                            "append-to-body": "",
                            label: "Text",
                            options: _vm.adminUsers,
                            clearable: true,
                            multiple: true,
                            reduce: function(item) {
                              return item.Value
                            },
                            getOptionLabel: function(item) {
                              return "" + item.Text
                            },
                            searchable: true,
                            "close-on-select": false,
                            placeholder: "בחר ערך מהרשימה"
                          },
                          on: { input: _vm.onChange },
                          model: {
                            value: _vm.row.taggedAdmins,
                            callback: function($$v) {
                              _vm.$set(_vm.row, "taggedAdmins", $$v)
                            },
                            expression: "row.taggedAdmins"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options"
                            },
                            [_vm._v("לא נמצאו ערכים דומים")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("FieldInlineTextArea", {
                        staticStyle: { flex: "1" },
                        attrs: { label: "כתיבת תגובה פנימית" },
                        model: {
                          value: _vm.newComment,
                          callback: function($$v) {
                            _vm.newComment = $$v
                          },
                          expression: "newComment"
                        }
                      }),
                      _c("b-button", { on: { click: _vm.sendNewComment } }, [
                        _vm._v("שמור")
                      ])
                    ],
                    1
                  ),
                  _vm.requestHistoryModel && _vm.requestHistoryModel.length
                    ? _c(
                        "div",
                        { staticClass: "DialogChat__Container" },
                        _vm._l(_vm.requestHistoryModel, function(history) {
                          return _c(
                            "div",
                            {
                              key: history.id,
                              staticClass: "StudentGroupsWidget__Component"
                            },
                            [
                              _c("p", [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [
                                    _vm._v(
                                      "בתאריך " +
                                        _vm._s(
                                          _vm.parseDate(history.createDate)
                                        )
                                    )
                                  ]
                                ),
                                _vm._v(
                                  ": " +
                                    _vm._s(history.byUser) +
                                    " " +
                                    _vm._s(history.text)
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "b-checkbox",
                    {
                      staticStyle: { padding: "10px 0" },
                      attrs: { "true-value": true, "false-value": false },
                      on: { input: _vm.onChange },
                      model: {
                        value: _vm.row.allowedPublication,
                        callback: function($$v) {
                          _vm.$set(_vm.row, "allowedPublication", $$v)
                        },
                        expression: "row.allowedPublication"
                      }
                    },
                    [_vm._v(" לפרסום ")]
                  )
                ],
                1
              )
            ])
          ],
          2
        ),
        _c(
          "footer",
          { staticClass: "modal-card-foot" },
          [
            _c("b-button", {
              attrs: { disabled: _vm.isLoading, label: "סגור" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("היסטוריית טיפול:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }