var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadLessons()
            }
          }
        },
        [_vm._v(" יחידות לימוד ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 2 },
          on: {
            click: function($event) {
              return _vm.loadExercises()
            }
          }
        },
        [_vm._v(" תלמידים ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 3 },
          on: {
            click: function($event) {
              return _vm.loadLaws()
            }
          }
        },
        [_vm._v(" מלווים ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 4 },
          on: {
            click: function($event) {
              return _vm.loadVerdicts()
            }
          }
        },
        [_vm._v(" תרגולים ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c("div", [
              _c(
                "div",
                { staticClass: "Activity__Component__TabsContent__Tasks" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.newLessonToGroup }
                        },
                        [_vm._v("שיוך יחידות לימוד לקבוצה")]
                      )
                    ]
                  ),
                  _vm.videos && _vm.videos.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Activity__Component__TabsContent__Tasks__Content"
                        },
                        [
                          _c("div", { staticClass: "section-container" }, [
                            _c("h1", [
                              _vm._v(
                                "יחידות לימוד בקבוצה: " +
                                  _vm._s(_vm.group.name) +
                                  " "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "document-table" },
                              [
                                _vm._m(0),
                                _vm._l(_vm.videos, function(video, idx) {
                                  return _c(
                                    "div",
                                    { key: idx, staticClass: "row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [_vm._v(_vm._s(video.id))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [_vm._v(_vm._s(video.name))]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b-tooltip",
                                            {
                                              attrs: {
                                                type: "is-white",
                                                label: "צפייה מקדימה"
                                              }
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "updateItem",
                                                  staticStyle: { color: "red" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteLessonInGroup(
                                                        video
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: { icon: "delete" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          : _vm._e(),
        _vm.tab === 2
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "new",
                          attrs: { type: "is-primary" },
                          on: {
                            click: function($event) {
                              return _vm.editAllStudentsExpirationDate(
                                _vm.exercises
                              )
                            }
                          }
                        },
                        [_vm._v("עריכת תאריך תפוגה לתלמידים")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.newStudentsToGroup }
                        },
                        [_vm._v("שיוך תלמידים לקבוצה")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-info" },
                          on: { click: _vm.assignGroupStudentsToTrack }
                        },
                        [_vm._v("רישום חברי הקבוצה למסלול")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: !_vm.exercises.length,
                            type: "is-danger"
                          },
                          on: { click: _vm.unAssignGroupStudentsFromTrack }
                        },
                        [_vm._v("גריעת חברי הקבוצה ממסלול")]
                      )
                    ],
                    1
                  ),
                  !_vm.isLoading && _vm.exercises && _vm.exercises.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "רשימת תלמידים בקבוצה: " +
                              _vm._s(_vm.group.name) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(1),
                            _vm._l(_vm.exercises, function(exercise, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.id))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.userName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(exercise.privateName) +
                                        " " +
                                        _vm._s(exercise.lastName)
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseExpirationDate(
                                          exercise.groupStudentExpirationDate
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeStudentFromGroup(
                                            exercise
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "מחיקה"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "red" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "delete" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editStudentExpirationDate(
                                            exercise
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "עריכה"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "blue" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "pen" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 3
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.newEscortsToGroup }
                        },
                        [_vm._v("שיוך מלווים לקבוצה")]
                      )
                    ]
                  ),
                  !_vm.isLoading && _vm.laws && _vm.laws.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "רשימת מלווים בקבוצה: " +
                              _vm._s(_vm.group.name) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(2),
                            _vm._l(_vm.laws, function(law, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(law.id))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(law.userName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(law.privateName) +
                                        " " +
                                        _vm._s(law.lastName)
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.removeEscortFromGroup(law)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "מחיקה"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "red" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "delete" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 4
          ? _c(
              "div",
              [
                [
                  _c("div", {
                    staticClass:
                      "Activity__Component__TabsContent__Tasks__Header"
                  }),
                  !_vm.isLoading && _vm.verdicts && _vm.verdicts.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "רשימת תרגולים בקבוצה: " +
                              _vm._s(_vm.group.name) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(3),
                            _vm._l(_vm.verdicts, function(verdict, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.userName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.exerciseName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _c(
                                      "a",
                                      { attrs: { href: verdict.reportUrl } },
                                      [_vm._v("קישור לתרגול")]
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.grade))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.starPercent))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.starGrade))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.grayPercent))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.grayGrade))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseDate(verdict.startDateTime)
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "column-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            to: {
                                              name: "ExercisePrint",
                                              params: {
                                                courseId: _vm.course.id,
                                                exerciseId: verdict.exerciseId
                                              },
                                              query: { showAnswers: true }
                                            }
                                          }
                                        },
                                        [_vm._v("פתרון")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "column-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            to: {
                                              name: "ExercisePrint",
                                              params: {
                                                courseId: _vm.course.id,
                                                exerciseId: verdict.exerciseId
                                              }
                                            }
                                          }
                                        },
                                        [_vm._v("שאלון")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" ID ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מחיקת יחידת לימוד ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" מזהה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אימייל ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם תלמיד ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך תפוגה ")]),
      _c(
        "div",
        { staticClass: "column", staticStyle: { "text-align": "center" } },
        [_vm._v(" מחיקת תלמיד ")]
      ),
      _c(
        "div",
        { staticClass: "column", staticStyle: { "text-align": "center" } },
        [_vm._v(" עריכת תאריך תפוגה ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" מזהה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אימייל ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מחיקת מלווה ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" שם תלמיד ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם תרגול ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" קישור לדוח ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" טווח ציונים תקין ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוז כוכביות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון כוכביות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוז אפורות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון אפורות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך ביצוע ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" פתרון להדפסה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שאלון להדפסה ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }