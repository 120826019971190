<template>
  <div class="Report__Entity">
    <Entity ref="entity" entity-type="Report" :entity-id="bookId" :innerComponent="innerComponent"
      innerComponentTitle="עריכת ספר" title="ספר" back-button-text="רשימת יחידות לימוד" back-button-url="lessons"
      :widgets="widgets" :actions="actions" :preview="preview" :read-only="readOnly" :entityReady="entityReady"
      :activities="[]" @onFormSave="onFormSave" @onFormDiscard="onFormDiscard">
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר מזהה לספר:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ bookId }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">שם הספר:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ bookName }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">משויך לקורס:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ courseName }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">יוצר:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ bookAuthor }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">צפייה מקדימה:</span>
          <span @click="openBook" class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
              link
            ">קישור</span>
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import BookEntityDetails from "@/components/BookEntityDetails.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import LessonService from "@/services/LessonService";
import DialogVideoPreview from "@/components/DialogVideoPreview.vue";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
  ModalProgrammatic as Modal
} from "buefy";

const { mapFields } = createHelpers({
  getterType: "book/getField",
  mutationType: "book/updateField",
});

export default {
  components: {
    Entity,
  },
  props: ["id", "preview", "readOnly", "bookLessonId"],
  computed: {
    bookId() {
      return this.$route.params.id || this.id;
    },
    widgets() {
      const widgets = [];
      return widgets;
    },
    actions() {
      return null;
    },
    innerComponent() {
      return BookEntityDetails;
    },
    ...mapFields(["bookId", "bookName", "courseName", "bookAuthor", "link", 'lessonId']),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
      isEntityPage: false
    };
  },
  async created() {
    this.init();
    this.isEntityPage = !this.$route.params.id;
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.id,
          lessonId: this.bookLessonId
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      LessonService.updateCourseBook(this.bookId, this.lessonId, {
        ...this.$store.state.book,
        ...model,
      })
        .then(() => {
          // this.init(refresh);
          this.$emit('onSave');
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "book/loadBook",
    }),
    openBook() {
      Modal.open({
        component: DialogVideoPreview,
        customClass: "entity-modal",
        props: {
          link: this.link
        },
        canCancel: false,
        trapFocus: true,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.link {
  text-decoration: underline;
  cursor: pointer;
}

.flex-between {
  display: flex;
  justify-content: space-between;

  .Accident__Entity__Details__BaseDetails__Stage__Value {
    padding-right: 0;
  }

  ::v-deep .b-tooltip {
    margin-left: 10px;
  }

  .Pay__Icon {
    border-radius: 3px;
    color: #0091ae;
    position: relative;
    right: -5px;
    font-size: 14px;

    .payment-btn {
      color: white;
      position: absolute;
      left: 20px;
      background-color: #00427e;
      height: 45px;
      margin: 0 10px;
      border: none;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #0c2949;
      }
    }

    i {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
