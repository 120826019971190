<template>
    <div class="Field__Component">
        <div style="cursor: pointer; text-align: center; width: 70px;" @click.stop="previewQuestion">
            <slot>
                <b-icon pack="fa" icon="eye"></b-icon>
            </slot>
        </div>
    </div>
</template>

<script>
import {
  ModalProgrammatic as Modal,
} from "buefy";
import DialogQuestionPreview from "@/components/DialogQuestionPreview.vue";
export default {
    name: 'FieldStandard',
    props: ['value', 'field', 'item'],
    methods: {
        previewQuestion() {
            Modal.open({
                component: DialogQuestionPreview,
                customClass: "entity-modal",
                props: {
                    id: this.item.id,
                    store: this.$store
                },
                canCancel: false,
                trapFocus: true,
            });
        },
    }
};
</script>

<style scoped></style>
