<template>
  <div class="Report__Entity">
    <Entity ref="entity" entity-type="Report" :entity-id="verdictSummaryId" :innerComponent="innerComponent"
      innerComponentTitle="עריכת תקציר" title="תקציר" back-button-text="רשימת יחידות לימוד"
      back-button-url="lessons" :widgets="widgets" :actions="actions" :preview="preview" :read-only="readOnly"
      :entityReady="entityReady" :activities="[]" @onFormSave="onFormSave" @onFormDiscard="onFormDiscard">
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר מזהה לתקציר:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ verdictSummaryId }}</span>
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import moment from "moment";
import { mapActions } from "vuex";
import VerdictSummaryDetails from "@/components/VerdictSummaryDetails.vue";
import { createHelpers } from "vuex-map-fields";
import AdminService from "@/services/AdminService";

const { mapFields } = createHelpers({
  getterType: "verdictSummary/getField",
  mutationType: "verdictSummary/updateField",
});

export default {
  components: {
    Entity,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    verdictSummaryId() {
      return this.$route.params.id || this.id;
    },
    courseId() {
      return this.$store.state.auth.course.id;
    },
    widgets() {
      const widgets = [];
      return widgets;
    },
    actions() {
      return null;
    },
    innerComponent() {
      return VerdictSummaryDetails;
    },
    ...mapFields([]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
      isEntityPage: false
    };
  },
  async created() {
    this.init();
    this.isEntityPage = !this.$route.params.id;
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.verdictSummaryId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      AdminService.updateVerdictSummary(this.verdictSummaryId, {
        ...this.$store.state.verdictSummary,
        ...model,
      })
        .then(() => {
          // this.init(refresh);
          this.$emit('onSave');
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "verdictSummary/loadVerdictSummary",
    }),
  },
};
</script>

<style scoped lang="scss">
.flex-between {
  display: flex;
  justify-content: space-between;

  .Accident__Entity__Details__BaseDetails__Stage__Value {
    padding-right: 0;
  }

  ::v-deep .b-tooltip {
    margin-left: 10px;
  }

  .Pay__Icon {
    border-radius: 3px;
    color: #0091ae;
    position: relative;
    right: -5px;
    font-size: 14px;

    .payment-btn {
      color: white;
      position: absolute;
      left: 20px;
      background-color: #00427e;
      height: 45px;
      margin: 0 10px;
      border: none;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #0c2949;
      }
    }

    i {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
