var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "LayoutInApp" }, [
    !_vm.isExercisePrint
      ? _c(
          "div",
          { attrs: { id: "nav" } },
          [
            _c(
              "div",
              {
                staticClass: "logo",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  padding: "0 5px"
                }
              },
              [
                _c("img", {
                  staticClass: "img-responsive",
                  attrs: { src: require("@/assets/simLogo.png"), alt: "" }
                }),
                !_vm.isInAdminRoutes
                  ? _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.isSideMenu = !_vm.isSideMenu
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          staticStyle: { color: "white" },
                          attrs: { pack: "fas", icon: "bars" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm.user
              ? _c(
                  "div",
                  { staticClass: "links" },
                  [
                    _c("router-link", { attrs: { to: "/lessons" } }, [
                      _vm._v("יחידות לימוד")
                    ]),
                    _c("router-link", { attrs: { to: "/expoChapters" } }, [
                      _vm._v("פרקי שאלות חשיפה")
                    ]),
                    _c("router-link", { attrs: { to: "/exercises" } }, [
                      _vm._v("תרגולים")
                    ]),
                    _c("router-link", { attrs: { to: "/courseMessages" } }, [
                      _vm._v("לוח הודעות")
                    ]),
                    _c("router-link", { attrs: { to: "/users" } }, [
                      _vm._v("משתמשים")
                    ]),
                    _c("router-link", { attrs: { to: "/reports" } }, [
                      _vm._v("דוחות")
                    ]),
                    _c("router-link", { attrs: { to: "/requests" } }, [
                      _vm._v("מרכז פניות")
                    ]),
                    _c("router-link", { attrs: { to: "/groups" } }, [
                      _vm._v("קבוצות")
                    ]),
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "aria-role": "list",
                          animation: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function() {
                                return [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          "router-link-active": _vm.isRouteActive(
                                            "/lectures"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" ניהול הרצאות "),
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "chevron-down",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2586057268
                        )
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  exact: "",
                                  to: "/lecturesLearningTracks"
                                }
                              },
                              [_vm._v("מסלולים")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { exact: "", to: "/lecturesRegions" } },
                              [_vm._v("אזורים")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { exact: "", to: "/lecturesHalls" } },
                              [_vm._v("אולמות")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { exact: "", to: "/lecturesTeachers" } },
                              [_vm._v("מרצים")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  exact: "",
                                  to: "/lecturesFeedbacksStatements"
                                }
                              },
                              [_vm._v("הגדרות ומשובים")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { exact: "", to: "/lectures" } },
                              [_vm._v("ניהול הוראה וקורסים")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("router-link", { attrs: { to: "/exerciseAnswers" } }, [
                      _vm._v("תשובות לתרגולים")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-dropdown",
              {
                attrs: { "aria-role": "list", animation: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "trigger",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "profile" }, [
                            _vm.user
                              ? _c("div", { staticClass: "avatar" }, [
                                  _vm.profileIcon
                                    ? _c("div", { staticClass: "icon" }, [
                                        _c("img", {
                                          staticClass: "img-responsive",
                                          attrs: {
                                            src: _vm.profileIcon,
                                            alt: ""
                                          }
                                        })
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "chevron-down" }
                                      }),
                                      _vm._v(" " + _vm._s(_vm.user) + " ")
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            !_vm.user
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "is-flex is-align-items-center avatar-placeholder"
                                    },
                                    [
                                      _vm._v(" Admin "),
                                      _c("b-icon", {
                                        attrs: { icon: "chevron-down" }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1287941300
                )
              },
              [
                _vm.user
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { "aria-role": "listitem" },
                        on: { click: _vm.goToProfile }
                      },
                      [_vm._v("הגדרות")]
                    )
                  : _vm._e(),
                _vm.user
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { "aria-role": "listitem" },
                        on: { click: _vm.signoutProfile }
                      },
                      [_vm._v("צא מקורס")]
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { "aria-role": "listitem" },
                    on: { click: _vm.signout }
                  },
                  [_vm._v("יציאה")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "LayoutInApp__Component" },
      [_c("router-view")],
      1
    ),
    _vm.isSideMenu && !_vm.isInAdminRoutes
      ? _c("div", { staticClass: "side_menu_container" }, [
          _c(
            "div",
            { staticClass: "Side_Menu" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _c(
                  "div",
                  { staticClass: "Side_Menu__Menu" },
                  [
                    _c("div", { staticClass: "Side_Menu_Header" }, [
                      _c("h1", [_vm._v("אדמיניסטרציה")]),
                      _c(
                        "div",
                        {
                          staticStyle: { color: "black", cursor: "pointer" },
                          on: {
                            click: function($event) {
                              _vm.isSideMenu = false
                            }
                          }
                        },
                        [
                          _c("b-icon", { attrs: { pack: "fa", icon: "times" } })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "b-menu",
                      [
                        _c(
                          "b-menu-list",
                          [
                            _c("b-menu-item", {
                              attrs: {
                                label: "ניהול קורסים",
                                tag: "router-link",
                                to: "/admin/profiles"
                              }
                            }),
                            _c("b-menu-item", {
                              attrs: {
                                label: "ניהול משתמשים",
                                tag: "router-link",
                                to: "/admin/users"
                              }
                            }),
                            _c("b-menu-item", {
                              attrs: {
                                label: "בנק שאלות",
                                tag: "router-link",
                                to: "/admin/questionsbank"
                              }
                            }),
                            _c("b-menu-item", {
                              attrs: {
                                label: "בנק וידאו",
                                tag: "router-link",
                                to: "/admin/videosbank"
                              }
                            }),
                            _c("b-menu-item", {
                              attrs: {
                                label: "בנק ספרים",
                                tag: "router-link",
                                to: "/admin/booksbank"
                              }
                            }),
                            _c("b-menu-item", {
                              attrs: {
                                label: "קופונים",
                                tag: "router-link",
                                to: "/admin/coupons"
                              }
                            }),
                            _c(
                              "b-menu-item",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function(props) {
                                        return [
                                          _vm._v(" פעולות "),
                                          _c("b-icon", {
                                            staticClass: "is-pulled-left",
                                            attrs: {
                                              icon: props.expanded
                                                ? "menu-up"
                                                : "menu-down"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2658959275
                                )
                              },
                              [
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "עדכון חוקים חסרים לתרגולים",
                                    tag: "router-link",
                                    to: "/admin/action/updateexerciselaws"
                                  }
                                }),
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "עריכת מקבצי חקיקה",
                                    tag: "router-link",
                                    to: "/admin/action/updatelawcollections"
                                  }
                                }),
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "שכפול יחידה",
                                    tag: "router-link",
                                    to: "/admin/action/unitReplication"
                                  }
                                }),
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "ניהול תקצירי פסיקה",
                                    tag: "router-link",
                                    to: "/admin/action/verdictReplication"
                                  }
                                }),
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "יצירת קובץ תשובות",
                                    tag: "router-link",
                                    to: "/admin/action/createanswersfiles"
                                  }
                                }),
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "עריכת מקבצי פסיקה",
                                    tag: "router-link",
                                    to: "/admin/action/verdictscollections"
                                  }
                                }),
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "ניהול רשימת חוקים",
                                    tag: "router-link",
                                    to: "/admin/action/manageLawsCollections"
                                  }
                                }),
                                _c("b-menu-item", {
                                  attrs: {
                                    label: "עדכון אורכים ותמונות חסרות לוידאו",
                                    tag: "router-link",
                                    to: "/admin/action/updateVideoContent"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }