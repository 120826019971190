var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _vm.studentIds
          ? _c("p", { staticClass: "modal-card-title" }, [
              _vm._v(
                "עריכת תאריך תפוגה לכל התלמידים בקבוצה " +
                  _vm._s(_vm.group.name)
              )
            ])
          : _c("p", { staticClass: "modal-card-title" }, [
              _vm._v(
                "עריכת תאריך תפוגה ל" +
                  _vm._s(_vm.student.privateName) +
                  " בקבוצה " +
                  _vm._s(_vm.group.name) +
                  " "
              )
            ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c("FieldInlineDate", {
            attrs: { label: "" },
            model: {
              value: _vm.studentExpirationDate,
              callback: function($$v) {
                _vm.studentExpirationDate = $$v
              },
              expression: "studentExpirationDate"
            }
          })
        ],
        1
      ),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          }),
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }