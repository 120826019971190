<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="lawCollection" @onNewEntitySave="onNewEntitySave">
      <template #title>מקבצי חקיקה</template>
      <template #button>
        <a @click="addLawCollection" class="link-button">
           מקבץ חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import DialogAddLawCollection from '@/components/DialogAddLawCollection.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import LawCollectionEntity from "@/views/LawCollectionEntity.vue";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("verdicts");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: LawCollectionEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי מקבץ חקיקה",
        recordUrl: "lawcollection",
        recordTitle: "הצג מקבץ חקיקה",
        hideRecord: false
      };
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.Name, checked: true },
      ];

      this.tabs = [
        {
          id: "AllLaws",
          isPrimary: true,
          title: "כל המקבצים",
          getDataAsync: this.getAllLaws,
          perPage: 100,
          fields,
          filters: [
          { ...reportsfilters.IsActive },
          { ...reportsfilters.IsAcademy },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מקבץ חקיקה",
          },
          sideViewEntity,
          sideViewModal: DialogAddLawCollection,
        },
      ];
    },
    addLawCollection() {
      Modal.open({
        component: DialogAddLawCollection,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllLaws({ ...rest}) {
      return AdminService.getAdminLaws(rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style lang="scss">
.Reports__View {
  height: 100%;
}
.link-button {
  background: #00427e;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
// .not-activated-row {
//   background-color: #fff !important;
// }
</style>
