<template>
  <div class="" v-if="ready">
    <span v-if="loading" class="loading">
      <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
    </span>
    <div v-if="groupStudentsModel && groupStudentsModel.length" class="StudentGroups__Container">
      <div class="StudentGroupsWidget__Component" v-for="group in groupStudentsModel" :key="group.id">
        <p>{{ group.groupName }} בקורס: דד {{ group.courseName }}</p>
      </div>
    </div>
    <div v-if="groupStudentsModel && groupStudentsModel.length" class="link-btn">
      <a @click="showAll">הצג הכל</a>
    </div>
    <div v-if="!loading && !groupStudentsModel.length">אין קבוצות משויכות למשתמש</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import { ModalProgrammatic as Modal } from "buefy";
import UserService from "../../services/UserService";

export default {
  name: "ClientWidget",
  props: ["ready", "namespace", "fetch"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      groupStudentsModel: [],
      loading: false
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["id"]),
    };
  },
  methods: {
    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    showAll() {
      this.$router.push({
        name: "Groups",
        params: {
          UserIds: [this.id],
        },
      });
    },
    init() {
      if (this.id) {
        this.loading = true;
        const courseId = this.$store.state.auth.course ? this.$store.state.auth.course.id : null;
        UserService.getUserGroups(this.id, courseId)
          .then((res) => {
            this.groupStudentsModel = res.data;
          }).finally(() => {
            this.$emit('onReady', { titleCount: this.groupStudentsModel?.length });
            this.loading = false;
          });
          this.$emit("onReady", true);
      }
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StudentGroups__Container {
  max-height: 400px;
  padding: 0 10px;
  overflow: auto;
}

.StudentGroupsWidget__Component {
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
