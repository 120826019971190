<template>
  <div class="Activity__Component">
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadExpoQuestions()" :class="{ active: tab === 1 }">
        שאלות בפרק
      </div>
    </div>
    <div class="Activity__Component__TabsContent">
      <!-- <new-activity ref="noteActivity" v-if="showNewNote" type="Note" @onClose="close" @onSave="saveNote" />
      <new-activity ref="taskActivity" v-if="showNewTask" type="Task" show-title="true" @onClose="closeTask"
        @onSave="saveTask" /> -->
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="button-close" @click="createExerciseFromExpo">צור כתרגול רב ברירה</button>
            <button class="link-button" @click="addExpoChapterQuestion">הוספת שאלות חשיפה לפרק</button>
            <b-dropdown aria-role="list">
              <template #trigger="{ active }">
                <div class="Entity__Details__PageOptions__Actions">
                  <span>פעולות</span>
                  <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
                </div>
              </template>
              <b-dropdown-item class="dropdown-iteam-to-right" @click="exportQuestions" aria-role="listitem">יצוא
                טבלה</b-dropdown-item>
              <b-dropdown-item class="dropdown-iteam-to-right" @click="calculateUnitDisplay" aria-role="listitem">סדר
                תווית
                שאלות שאלות
                פעילות מחדש</b-dropdown-item>
              <b-dropdown-item class="dropdown-iteam-to-right" @click="deleteQuestions" aria-role="listitem">מחיקת כל
                שאלות
                הפרק</b-dropdown-item>
              <b-dropdown-item class="dropdown-iteam-to-right" @click="showStatistics" aria-role="listitem">הצג/הסתר
                סטטיסטיקה</b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="!isLoading && exercises && exercises.length" class="section-container">
            <h1> {{ exercises.length }} שאלות בפרק חשיפה {{ expoChapter.displayName }}</h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  מזהה
                </div>
                <div class="column">
                  תווית יחידה
                </div>
                <div class="column">
                  שאלה
                </div>
                <div class="column">
                  תשובה נכונה
                </div>
                <div class="column">
                  פתרון
                </div>
                <div class="column">
                  פעיל
                </div>
                <div class="column">
                  מספר עונים
                </div>
                <div class="column">
                  מענה נכונה
                </div>
                <div class="column">
                  עריכה
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div class="row" v-for="(exercise, idx) in exercises" :key="idx">
                <div class="column-item">{{ exercise.id }}</div>
                <div class="column-item">{{ exercise.unitDisplayName }}</div>
                <div v-html="exercise.question" class="column-item"></div>
                <div class="column-item">{{ exercise.answer ? 'כן' : 'לא' }}</div>
                <div v-html="exercise.detailedAnswer" class="column-item"></div>
                <div class="column-item">{{ exercise.status ? 'כן' : 'לא' }}</div>
                <div class="column-item">{{ exercise.count }}</div>
                <div class="column-item">{{ exercise.percent }}</div>
                <div @click="updateQuestion(exercise)">
                  <b-tooltip type="is-white" label="ערוך שאלת חשיפה">
                    <a style="color: #1eb2d0;" class="updateItem">
                      <b-icon icon="pen"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 2">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="new" @click="newRemark">הוספת הערה</button>
          </div>
          <div v-if="!isLoading && studentRemarks && studentRemarks.length" class="section-container">
            <h1>יומן מלווה לתלמיד {{ userName }} בקורס {{ course.name }}</h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  על ידי
                </div>
                <div class="column">
                  נושא
                </div>
                <div class="column">
                  תאריך
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div class="row" v-for="(remark, idx) in studentRemarks" :key="idx">
                <div class="column-item">{{ remark.byUserName }}</div>
                <div v-html="remark.contentToDisplay" class="column-item"></div>
                <div class="column-item">{{ parseDate(remark.dateCreated) }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import { ContentLoader } from "vue-content-loader";
// import UserService from "@/services/UserService";
import _ from "lodash";
import { ModalProgrammatic as Modal, LoadingProgrammatic as Loading } from "buefy";
import DialogCreateExerciseFromExpo from '@/components/DialogCreateExerciseFromExpo.vue';
import DialogUpdateExpoQuestion from '@/components/DialogUpdateExpoQuestion.vue';
import DialogAddExpoChapterQuestion from '@/components/DialogAddExpoChapterQuestion.vue';
import { saveAs } from "file-saver";
import DialogAddRemark from "./DialogAddRemark.vue";
import DialogAddLaw from "./DialogAddLaw.vue";
import DialogAddLessonVideo from "./DialogAddLessonVideo.vue";
import UserService from "../services/UserService";
import DialogAddVerdict from "./DialogAddVerdict.vue";
import CoursesService from "../services/CoursesService";

export default {
  name: "Activity",
  components: {
    DialogAddExpoChapterQuestion,
    DialogCreateExerciseFromExpo
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  // updated() {
  //   const params = window.location.search.substring(1);
  //   const lastIdx = params.lastIndexOf("=");
  //   const taskId = params.slice(lastIdx + 1);
  //   const task = this.$refs[`taskElement${taskId}`][0];
  //   task.isOpen = true;
  //   this.$nextTick(() => {
  //     task.$el.scrollIntoView({ behavior: "smooth" });
  //   });
  // },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    expoChapter() {
      return this.$store.state.expoChapter;
    },
    lesson() {
      return this.$store.state.lesson;
    },
    userName() {
      return `${this.$store.state.user.privateName} ${this.$store.state.user.lastName}`;
    },
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      isLoading: false,
      exercises: null,
      studentRemarks: null,
      isStatistics: false,
      expoChapterQuestionId: null
    };
  },
  created() {
    this.loadExpoQuestions();
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    addExpoChapterQuestion() {
      Modal.open({
        component: DialogAddExpoChapterQuestion,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    createExerciseFromExpo() {
      Modal.open({
        component: DialogCreateExerciseFromExpo,
        props: {
          store: this.$store,
          router: this.$router
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    exportQuestions() {
      this.isLoading = true;
      CoursesService.exportExpoChapterQuestions(this.$route.params.id, {
        Test: 1
      })
        .then((r) => {
          this.saveFile(r.data, `ייצוא ${this.expoChapter.displayName}`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calculateUnitDisplay() {
      this.isLoading = true;
      CoursesService.resetQuestionsLabel(this.$route.params.id)
        .then(() => {
          // this.getAllExpoChapterQuestions(query);
          window.location.reload();
          this.isLoading = false;
        })
    },
    async updateQuestion(question) {
      Modal.open({
        component: DialogUpdateExpoQuestion,
        customClass: "entity-modal",
        props: {
          question,
          store: this.$store
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    deleteQuestions() {
      this.isLoading = true;
      CoursesService.deleteExpoChapterQuestions(this.$route.params.id)
        .then(() => {
          // this.tabs[0].getDataAsync();
          window.location.reload();
          this.isLoading = false;
        });
    },
    showStatistics() {
      this.isStatistics = !this.isStatistics;
      this.loadExpoQuestions();
    },
    getPrintExerciseUrl(id) {
      window.open(`/course/${this.course.id}/exercisePrint/${id}`, '_blank');
    },
    resetUserExercise(exercise) {
      this.$buefy.dialog.confirm({
        title: 'איפוס תרגול',
        message: 'האם לאפס את התרגול למשתמש?',
        confirmText: 'כן',
        cancelText: 'לא',
        type: 'is-danger',
        closeOnConfirm: false,
        hasIcon: true,
        onConfirm: (a, instance) => {
          const loading = Loading.open();
          LessonService.resetUserExercise(exercise.id, exercise.userId).then(() => {
            instance.close();
            this.$buefy.toast.open({
              type: "is-success",
              message: `התרגול אופס בהצלחה!`,
              duration: 4000,
            });
          }).catch(() => {
            this.$buefy.toast.open({
              type: "is-danger",
              message: `הפעולה נכשלה!`,
              duration: 8000,
            });
          }).finally(() => {
            loading.close();
          });
        },
      });
    },
    openSimulator() {
      window.open(`${process.env.VUE_APP_URL}?StudentId=${this.entityId}`, '_blank');
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    newRemark() {
      Modal.open({
        component: DialogAddRemark,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          onSuccess: this.loadUserRemarks
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonLaw() {
      Modal.open({
        component: DialogAddLaw,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonVerdict() {
      Modal.open({
        component: DialogAddVerdict,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonVideo() {
      Modal.open({
        component: DialogAddLessonVideo,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    loadExpoQuestions() {
      this.isLoading = true;
      CoursesService.getExpoChapterQuestion(this.entityId, {
        PageNumber: 1,
        PageSize: 999
      }, this.isStatistics).then((r) => {
        this.exercises = r.data.items;
      })
        .finally(() => {
          this.tab = 1;
          this.isLoading = false;
        });
    },
    updateExercise(exercise, exerciseId) {
      this.isLoading = true;
      UserService.updateUserExercise(this.entityId, exerciseId, this.course.id)
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: `התרגול תוחקר בהצלחה!`,
            duration: 4000,
          });
          exercise.wasResearched = !exercise.wasResearched;
        }).catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: `הפעולה נכשלה!`,
            duration: 8000,
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadUserRemarks() {
      this.isLoading = true;
      UserService.getUserRemarks(this.course.id, this.entityId)
        .then((r) => {
          this.studentRemarks = r.data;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadLaws() {
      this.isLoading = true;
      UserService.getAdminLaws(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.laws = r.data.items;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      UserService.getAdminVerdicts(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped lang="scss">
.Entity__Details__PageOptions__Actions {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  cursor: pointer;

  &:hover {
    background-color: #4d6782;
  }
}

.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #eef1f3;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  // margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

.button-close {
  background: white;
  color: #00427e;
  border: none;
  border: 1px solid #00427e;
  padding: 13px 30px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.link-button {
  background: #00427e;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

a.dropdown-item {
  padding-right: 1rem;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
