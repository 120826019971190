var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadUserExercises()
            }
          }
        },
        [_vm._v(" תרגולים ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 2 },
          on: {
            click: function($event) {
              return _vm.loadUserGroups()
            }
          }
        },
        [_vm._v(" קבוצות ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 3 },
          on: {
            click: function($event) {
              return _vm.loadUserRemarks()
            }
          }
        },
        [
          _vm._v(" יומן מלווה לתלמיד "),
          _vm.remarksCount
            ? _c("span", { staticClass: "remarks__count" }, [
                _vm._v(_vm._s(_vm.remarksCount))
              ])
            : _vm._e()
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.openSimulator }
                        },
                        [_vm._v("הפניה לסימולטור")]
                      )
                    ]
                  ),
                  !_vm.isLoading && _vm.exercises && _vm.exercises.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v("התרגולים של "),
                          _c("b", [_vm._v(_vm._s(_vm.userName))]),
                          _vm._v(" בקורס " + _vm._s(_vm.course.name))
                        ]),
                        _vm.lessonsWithExercises.length
                          ? _c(
                              "div",
                              { staticClass: "exercise__lessons" },
                              [
                                _vm._l(_vm.lessonsWithExercises, function(
                                  exerciseLesson,
                                  idx
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: idx,
                                      staticClass: "exercise_lesson_btn",
                                      style: {
                                        backgroundColor: _vm.getLessonColor(
                                          exerciseLesson.btnColor
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.filterExercisesByLessonId(
                                            exerciseLesson.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(exerciseLesson.name) + " "
                                        ),
                                        _c("span", { staticClass: "badge" }, [
                                          _vm._v(
                                            _vm._s(exerciseLesson.exerciseCount)
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "exercise_lesson_btn",
                                    staticStyle: {
                                      "background-color": "white",
                                      "margin-right": "auto"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.filterExercisesByLessonId(
                                          null
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _vm._v("לכל התרגולים בכל היחידות")
                                    ])
                                  ]
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "auto" } },
                          [
                            _vm._v(
                              _vm._s(_vm.exercises.length) + ' רשומות סה"כ'
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(0),
                            _vm._l(_vm.exercises, function(exercise, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.exerciseName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(Math.floor(exercise.grade)))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.madadTakinEditor))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.starPercent))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        exercise.starGrade != "NaN"
                                          ? exercise.starGrade
                                          : "---"
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.grayPercent))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.grayGrade))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.ahuzon))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseDate(exercise.endDateTime)
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.getPrintExerciseUrl(
                                            exercise.exerciseId,
                                            true
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "הדפס פתרון"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "green" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "file" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.getPrintExerciseUrl(
                                            exercise.exerciseId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "הדפס שאלון"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            { staticClass: "updateItem" },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "file" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "column-item" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: exercise.wasResearched,
                                          expression: "exercise.wasResearched"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          exercise.wasResearched
                                        )
                                          ? _vm._i(
                                              exercise.wasResearched,
                                              null
                                            ) > -1
                                          : exercise.wasResearched
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateExercise(
                                            exercise,
                                            exercise.exerciseId
                                          )
                                        },
                                        change: function($event) {
                                          var $$a = exercise.wasResearched,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  exercise,
                                                  "wasResearched",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  exercise,
                                                  "wasResearched",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              exercise,
                                              "wasResearched",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    })
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.resetUserExercise(exercise)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "איפוס תרגול"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "red" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "delete" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 2
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "new",
                          attrs: { type: "is-primary" },
                          on: {
                            click: function($event) {
                              return _vm.editAllStudentsExpirationDate(
                                _vm.studentGroups
                              )
                            }
                          }
                        },
                        [_vm._v("עריכת תאריך תפוגה לתלמידים")]
                      )
                    ],
                    1
                  ),
                  !_vm.isLoading &&
                  _vm.studentGroups &&
                  _vm.studentGroups.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "קבוצות המשויכות לתלמיד " +
                              _vm._s(_vm.userName) +
                              " בקורס " +
                              _vm._s(_vm.course.name)
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(1),
                            _vm._l(_vm.studentGroups, function(group, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(group.groupName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseExpirationDate(
                                          group.groupStudentExpirationDate
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeStudentFromGroup(
                                            group
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "מחיקה"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "red" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "delete" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editStudentExpirationDate(
                                            group
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "עריכה"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "blue" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "pen" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 3
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "new", on: { click: _vm.newRemark } },
                        [_vm._v("הוספת הערה")]
                      )
                    ]
                  ),
                  !_vm.isLoading &&
                  _vm.studentRemarks &&
                  _vm.studentRemarks.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "יומן מלווה לתלמיד " +
                              _vm._s(_vm.userName) +
                              " בקורס " +
                              _vm._s(_vm.course.name)
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(2),
                            _vm._l(_vm.studentRemarks, function(remark, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(remark.byUserName))
                                  ]),
                                  _c("div", {
                                    staticClass: "column-item",
                                    domProps: {
                                      innerHTML: _vm._s(remark.contentToDisplay)
                                    }
                                  }),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(_vm.parseDate(remark.dateCreated))
                                    )
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" תרגול ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" טווח תקין ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוז כוכביות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון כוכביות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוז אפורות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון אפורות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוזון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" פתרון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שאלון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תוחקר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" איפוס ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" שם קבוצה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך תפוגה ")]),
      _c(
        "div",
        { staticClass: "column", staticStyle: { "text-align": "center" } },
        [_vm._v(" מחיקת קבוצה מתלמיד ")]
      ),
      _c(
        "div",
        { staticClass: "column", staticStyle: { "text-align": "center" } },
        [_vm._v(" עריכת תאריך תפוגה ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" על ידי ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" נושא ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }