import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getCourses = (activeOnly) => axios.get(`${BASE_URL}/api/admin/courses/search?activeOnly=${activeOnly}`);

const getTracks = () => axios.get(`${BASE_URL}/api/admin/course/tracks`);

const getCourse = (courseId, data, activeOnly, cancelToken) => axios.post(`${BASE_URL}/api/admin/course/${courseId}?activeOnly=${activeOnly}`, data, {
    cancelToken
});

const getUsersByCourse = (courseId, data, cancelToken) => axios.post(`${BASE_URL}/api/admin/users/search/${courseId}`, data, {
    cancelToken
});

const getTeachersByCourse = (courseId) => axios.get(`${BASE_URL}/api/admin/users/SearchTeachersInCourse/${courseId}`);

const getRequestMessageTypes = (courseId) => axios.get(`${BASE_URL}/api/Admin/requests/Course/${courseId}/MessageTypes`);

const getRequestsByCourse = (courseId, data, cancelToken) => axios.post(`${BASE_URL}/api/admin/requests/course/${courseId}/search`, data, {
    cancelToken
});

const getLastWeekCountRequests = (userId, courseId) => axios.get(`${BASE_URL}/api/admin/requests/${userId}/course/${courseId}/LastWeekCountRequests`);

const updateRequest = (requestId, courseId, data) => axios.put(`${BASE_URL}/api/admin/requests/${requestId}/course/${courseId}`, data);

const getRequestResponses = (requestId, courseId) => axios.get(`${BASE_URL}/api/admin/requests/${requestId}/course/${courseId}/responses`);

const responseToRequest = (requestId, courseId, data) => axios.post(`${BASE_URL}/api/Admin/requests/${requestId}/Course/${courseId}/Responses`, data);

const deleteResponse = (requestId, courseId, responseId) => axios.delete(`${BASE_URL}/api/admin/requests/${requestId}/course/${courseId}/responses/${responseId}`);

const editResponse = (requestId, courseId, responseId, data) => axios.put(`${BASE_URL}/api/admin/requests/${requestId}/course/${courseId}/responses/${responseId}`, data);

const getRequestLogs = (requestId, courseId) => axios.get(`${BASE_URL}/api/admin/requests/${requestId}/course/${courseId}/logs`);

const updateRequestHistory = (requestId, courseId, data) => axios.post(`${BASE_URL}/api/admin/requests/${requestId}/course/${courseId}/logs`, data);

const getRequestAdmins = (requestId, courseId) => axios.get(`${BASE_URL}/api/admin/requests/${requestId}/course/${courseId}/admins`);

const getUser = (userId) => axios.get(`${BASE_URL}/api/admin/users/search/user/${userId}`);

const updateUser = (data) => axios.put(`${BASE_URL}/api/admin/users`, data);

const getCategories = () => axios.get(`${BASE_URL}/api/admin/exercises/categories`);

const getLessonCategories = () => axios.get(`${BASE_URL}/api/admin/lessons/categories`);

const getVideoTypes = () => axios.get(`${BASE_URL}/api/admin/videoTypes`);

const getMeyumanot = () => axios.get(`${BASE_URL}/api/admin/QuestionsBank/Answers/Skills`);

const getExplanations = () => axios.get(`${BASE_URL}/api/admin/QuestionsBank/Answers/Explanations`);

const getExerciseSubjects = () => axios.get(`${BASE_URL}/api/admin/QuestionsBank/Subjects`);

const getExerciseTypes = () => axios.get(`${BASE_URL}/api/admin/exercises/types`);

const getLawCollections = () => axios.get(`${BASE_URL}/api/lawcollections`);

const getVerdictSummaries = () => axios.get(`${BASE_URL}/api/verdictsummaries`);

const getVerdictCollections = () => axios.get(`${BASE_URL}/api/verdictcollections`);

const getLaws = () => axios.get(`${BASE_URL}/api/laws`);

const getLawClauses = (lawId = 4) => axios.get(`${BASE_URL}/api/laws/${lawId}/clauses`);

const getCourseExpoChapters = (courseId, data) => axios.post(`${BASE_URL}/api/admin/expochapters/${courseId}/search`, data);

const getExpoChapterQuestion = (expoChapterId, data, isStatistics = false) => axios.post(`${BASE_URL}/api/admin/expochapters/${expoChapterId}/questions/search?statistics=${isStatistics}`, data);

const resetQuestionsLabel = (expoChapterId) => axios.post(`${BASE_URL}/api/admin/expochapters/${expoChapterId}/questions/reset`);

const deleteExpoChapterQuestions = (expoChapterId) => axios.delete(`${BASE_URL}/api/admin/expochapters/${expoChapterId}/questions/delete`);

const exportExpoChapterQuestions = (expoChapterId, data) => axios.post(`${BASE_URL}/api/admin/expochapters/${expoChapterId}/questions/xls`, data, {
    responseType: 'blob',
});

const updateExpoChapter = (expoChapterId, data) => axios.put(`${BASE_URL}/api/admin/expochapters/update/${expoChapterId}`, data);

const createExpoChapter = (courseId, data) => axios.post(`${BASE_URL}/api/admin/expochapters/${courseId}`, data);

const updateExpoChapterQuestion = (expoChapterId, data) => axios.put(`${BASE_URL}/api/admin/expochapters/update/${expoChapterId}/question`, data);

const createExpoChapterQuestion = (expoChapterId, data) => axios.post(`${BASE_URL}/api/admin/expochapters/create/${expoChapterId}/question`, data);

const exportUsers = (courseId, data) => axios.post(`${BASE_URL}/api/admin/users/${courseId}/xls`, data, {
    responseType: 'blob',
});

const importUsers = (courseIds, trackIds, file) => axios.post(`${BASE_URL}/api/admin/users/import?courseIds=${courseIds}&trackIds=${trackIds}`, file, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

const importQuestionsToExpoChapter = (expoChapterId, file) => axios.post(`${BASE_URL}/api/admin/expoChapters/${expoChapterId}/import`, file, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

const downloadUsersFormat = (data) => axios.post(`${BASE_URL}/api/admin/users/format/xls`, data, {
    responseType: 'blob',
})

const downloadExpoFormat = (data) => axios.post(`${BASE_URL}/api/admin/expoChapters/format/xls`, data, {
    responseType: 'blob',
})

const getCourseRegions = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/regions`, data);

const updateLecturesRegion = (lecturesRegionId, data) => axios.put(`${BASE_URL}/api/admin/course/regions/${lecturesRegionId}`, data);

const createLecturesRegion = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/regions/create`, data);

const getCourseHalls = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/halls`, data);

const updateLecturesHall = (lecturesHallId, data) => axios.put(`${BASE_URL}/api/admin/course/Halls/${lecturesHallId}`, data);

const createLecturesHall = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/Halls/create`, data);

const getCourseTeachers = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/teachers`, data);

const updateLecturesTeacher = (lecturesTeacherId, data) => axios.put(`${BASE_URL}/api/admin/course/Teachers/${lecturesTeacherId}`, data);

const getTrackSubjects = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/trackSubjects`, data);

const createLecturesTeacher = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/Teachers/create`, data);

const getCourseFeedbacksStatements = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/FeedbacksStatements`, data);

const updateLecturesFeedbackStatement = (lecturesFeedbackStatementId, data) => axios.put(`${BASE_URL}/api/admin/course/FeedbacksStatements/${lecturesFeedbackStatementId}`, data);

const createLecturesFeedbackStatement = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/FeedbacksStatements/create`, data);

const getCourseLectures = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/lectures`, data);

const exportCouresLectures = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/lectures/xls`, data, {
    responseType: 'blob',
});

const getCourseLectureUsers = (courseId, lectureId) => axios.get(`${BASE_URL}/api/admin/course/${courseId}/lectures/${lectureId}/users`);

const exportCourseLectureUsers = (courseId, lectureId) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/lectures/${lectureId}/users/xls`, {}, {
    responseType: 'blob',
});

const removeStudendFromLecture = (lectureId, studentId) => axios.delete(`${BASE_URL}/api/admin/course/lectures/${lectureId}/removeStudent/${studentId}`);

const updateLecture = (lectureId, data) => axios.put(`${BASE_URL}/api/admin/course/lectures/${lectureId}`, data);

const createLecture = (lectureTrackSubjectId, data) => axios.post(`${BASE_URL}/api/admin/course/lectureTrackSubjectId/${lectureTrackSubjectId}/create`, data);

const addLectureStudent = (lectureId, studentId) => axios.post(`${BASE_URL}/api/admin/course/lectures/${lectureId}/addStudent/${studentId}`);

const getCourseLearningTracks = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/learningtracks`, data);

const updateLecturesLearningTrack = (lecturesLearningTrackId, data) => axios.put(`${BASE_URL}/api/admin/course/learningtracks/${lecturesLearningTrackId}`, data);

const createLecturesLearningTrack = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/learningtracks/create`, data);

const getCourseLecturesLearningTrackSubjects = (courseId, lectureLearningTrackId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/learningtrack/${lectureLearningTrackId}/learningtracksubjects`, data);

const updateLectureTrackSubject = (lectureTrackId, lectureTrackSubjectId, data) => axios.put(`${BASE_URL}/api/admin/course/lectureLearningTrackId/${lectureTrackId}/learningTrackSubject/${lectureTrackSubjectId}`, data);

const createLectureTrackSubject = (courseId, lectureTrackId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/lectureTrackId/${lectureTrackId}/LearningTrackSubject/Create`, data);

const createExerciseFromExpo = (data) => axios.post(`${BASE_URL}/api/admin/exercises/fromExposureExercise`, data);

const getCourseReports = (data) => axios.post(`${BASE_URL}/api/Admin/Reports/v2/Search`, data);

const importCourseReports = (courseId, date, testUserId, data) => axios.post(`${BASE_URL}/api/Admin/Reports/v2/AddToCourse/${courseId}?date=${date}&testUserId=${testUserId || ''}`, data);

const deleteReports = (data) => axios.post(`${BASE_URL}/api/admin/reports/v2/delete`, data);

const changeCourseStatus = (courseId, status) => axios.put(`${BASE_URL}/api/admin/courses/${courseId}/changeStatus/${status}`);

export default {
    getCourses,
    getTracks,
    getCourse,
    getUsersByCourse,
    getTeachersByCourse,
    getRequestMessageTypes,
    getRequestsByCourse,
    getLastWeekCountRequests,
    updateRequest,
    getRequestResponses,
    responseToRequest,
    deleteResponse,
    editResponse,
    getRequestLogs,
    updateRequestHistory,
    getRequestAdmins,
    getUser,
    updateUser,
    getCategories,
    getLessonCategories,
    getVideoTypes,
    getMeyumanot,
    getExplanations,
    getExerciseSubjects,
    getExerciseTypes,
    getLawCollections,
    getVerdictSummaries,
    getVerdictCollections,
    getLaws,
    getLawClauses,
    getCourseExpoChapters,
    getExpoChapterQuestion,
    resetQuestionsLabel,
    deleteExpoChapterQuestions,
    exportExpoChapterQuestions,
    updateExpoChapter,
    createExpoChapter,
    updateExpoChapterQuestion,
    createExpoChapterQuestion,
    exportUsers,
    importUsers,
    importQuestionsToExpoChapter,
    downloadUsersFormat,
    downloadExpoFormat,
    getCourseRegions,
    updateLecturesRegion,
    createLecturesRegion,
    getCourseHalls,
    updateLecturesHall,
    createLecturesHall,
    getCourseTeachers,
    updateLecturesTeacher,
    getTrackSubjects,
    createLecturesTeacher,
    getCourseFeedbacksStatements,
    updateLecturesFeedbackStatement,
    createLecturesFeedbackStatement,
    getCourseLectures,
    exportCouresLectures,
    getCourseLectureUsers,
    exportCourseLectureUsers,
    removeStudendFromLecture,
    updateLecture,
    createLecture,
    addLectureStudent,
    getCourseLearningTracks,
    updateLecturesLearningTrack,
    createLecturesLearningTrack,
    getCourseLecturesLearningTrackSubjects,
    updateLectureTrackSubject,
    createLectureTrackSubject,
    createExerciseFromExpo,
    getCourseReports,
    importCourseReports,
    deleteReports,
    changeCourseStatus
};
