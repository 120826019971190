<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>תרגולים</template>
      <template #button>
        <a @click="addExercise" class="link-button">
          הוספת תרגול ליחידת לימוד
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import LessonService from "@/services/LessonService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import ExerciseEntity from "@/views/ExerciseEntity.vue";
import DialogAddExercise from '@/components/DialogAddExercise.vue';
import TableActions from "@/services/TableActionsService";
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import { saveAs } from "file-saver";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    courseId() {
      return this.$store.state.auth.course.id;
    },
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("exercises");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: ExerciseEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי תרגול",
        recordUrl: "exercise",
        recordTitle: "עריכת תרגול"
      };
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.LessonId, checked: true },
        { ...lessonsFields.Name, checked: true },
        { ...lessonsFields.SubTitle, checked: true },
        { ...lessonsFields.ExerciseName, checked: true },
        { ...lessonsFields.CategoryName, checked: true },
        { ...lessonsFields.ExerciseType, checked: true },
        { ...lessonsFields.SortOrder, checked: true },
        { ...lessonsFields.Status, checked: true },
        { ...lessonsFields.Entity, checked: true },
      ];

      this.tabs = [
        {
          id: "AllExercises",
          isPrimary: true,
          title: "כל התרגולים",
          getDataAsync: this.getAllExercises,
          perPage: 100,
          fields,
          filters: [
            { ...reportsfilters.Lessons, selected: this.$route.params.LessonIds },
            { ...reportsfilters.Users, selected: this.$route.params.UserIds },
          ],
          sortField: "sortOrder",
          sortOrder: "desc",
          checkableRows: true,
          tableActions: [
            TableActions.setExerciseStatus,
            TableActions.setExerciseType,
            TableActions.archiveExercises,
          ],
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש לפי שם תרגול",
          },
          sideViewEntity,
        },
        {
          id: "ArchivedExercises",
          isPrimary: true,
          title: "ארכיון",
          getDataAsync: this.getArchivedExercises,
          perPage: 500,
          fields,
          filters: [
            { ...reportsfilters.Lessons, selected: this.$route.params.LessonIds },
            { ...reportsfilters.Users, selected: this.$route.params.UserIds },
          ],
          sortField: "sortOrder",
          sortOrder: "desc",
          checkableRows: true,
          tableActions: [
            TableActions.setExerciseStatus,
            TableActions.setExerciseType,
            TableActions.unArchiveExercises,
          ],
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש לפי שם תרגול",
          },
          // sideViewEntity,
        },
      ];
    },
    addExercise() {
      Modal.open({
        component: DialogAddExercise,
        props: {
          store: this.$store,
        },
        events: {
          customEvent: () => {
            this.customSearch();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    customSearch() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    onNewEntitySave() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    getAllExercises({ ...rest }) {
      return LessonService.getAdminExercises(this.courseId, rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getArchivedExercises({ ...rest }) {
      const newQuery = {
        ...rest,
        IsArchived: true
      };
      return LessonService.getAdminExercises(this.courseId, newQuery).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #00427e;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
