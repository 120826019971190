<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>משתמשים</template>
      <template #button>
        <a @click="addUser" class="link-button">
          משתמש חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import UserEntity from "@/views/UserEntity.vue";
import DialogAddUser from '@/components/DialogAddUser.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("users");
      const sideViewEntity = {
        component: UserEntity,
        preview: true,
        readOnly: false,
        title: "פרטי דוח",
        recordUrl: "admin/reports",
        hideRecord: true
      };
      const fields = [
        { ...reportsFields.Id, checked: true },
        { ...reportsFields.PrivateName, checked: true },
        { ...reportsFields.LastName, checked: true },
        { ...reportsFields.UserName, checked: true },
        { ...reportsFields.Phone, checked: true },
        { ...reportsFields.Address, checked: true },
        { ...reportsFields.Status, checked: true },
        { ...reportsFields.CreateDate, checked: true },
        { ...reportsFields.UserTypeId, checked: true },
      ];

      this.tabs = [
        {
          id: "AllUsersAdmin",
          title: "כל המשתמשים",
          getDataAsync: this.getAllUsers,
          perPage: 100,
          fields,
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש משתמש",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllUsers({ cancelToken, ...rest }) {
      return AdminService.getUsers(
        {
          ...rest,
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    loadUsers() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    addUser() {
      Modal.open({
        component: DialogAddUser,
        props: {
          reportNumbers: [{ Id: 1, reportNumber: 123 }, { Id: 2, reportNumber: 3456 }],
          store: this.$store
        },
        events: {
          customEvent: () => {
            this.loadUsers();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #00427e;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
