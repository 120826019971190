var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Entity__View" },
    [
      _c(
        "div",
        { staticClass: "Entity__View__Head" },
        [
          _vm._t("header", [
            _c("div", { staticClass: "Entity__View__Head__Right" }, [
              _c(
                "div",
                { staticClass: "Entity__View__Head__Title" },
                [_vm._t("title")],
                2
              ),
              _c(
                "div",
                { staticClass: "Entity__View__Head__Counter" },
                [
                  _vm._t("counter", [_vm._v(_vm._s(_vm.selectedTab))], {
                    tab: _vm.selectedTab
                  })
                ],
                2
              )
            ]),
            _c(
              "div",
              { staticClass: "Entity__View__Head__Left" },
              [_vm._t("button")],
              2
            )
          ])
        ],
        2
      ),
      _c("div", { staticClass: "Entity__View__Tabs" }, [
        _c(
          "div",
          { staticClass: "Entity__View__Tabs__Menu" },
          _vm._l(_vm.tabs, function(tab) {
            return _c(
              "div",
              {
                key: tab.id,
                staticClass: "Entity__View__Tabs__Menu__Item",
                class: { selected: _vm.isSelected(tab) },
                on: {
                  click: function($event) {
                    return _vm.selectTab(tab)
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "Entity__View__Tabs__Menu__Item__Icon",
                    class: { pinned: _vm.pinned },
                    on: {
                      click: function($event) {
                        _vm.pinned = !_vm.pinned
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/pin.svg"), alt: "" }
                    })
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "Entity__View__Tabs__Menu__Item__Icon__Text" },
                  [_vm._v(_vm._s(tab.title))]
                ),
                tab.count
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "Entity__View__Tabs__Menu__Item__Icon__Count"
                      },
                      [_vm._v(_vm._s(tab.count))]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "Entity__View__Tabs__Content" }, [
          _c(
            "div",
            { staticClass: "Entity__View__Table is-flex-direction-column" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "\n            Entity__View__Table__Filters\n            is-flex is-justify-content-flex-end\n            mb-3\n          "
                },
                [
                  _c("div", { staticClass: "is-flex" }, [
                    _c(
                      "div",
                      { staticClass: "mr-2" },
                      [
                        _c(
                          "b-tooltip",
                          { attrs: { label: "בחירת עמודות" } },
                          [
                            _c(
                              "b-dropdown",
                              {
                                staticClass: "dropdown-fields",
                                attrs: {
                                  "aria-role": "menu",
                                  scrollable: "",
                                  "max-height": "400",
                                  "trap-focus": "",
                                  multiple: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "b-button",
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "table-settings" }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.selectedTab.selectedFields,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedTab,
                                      "selectedFields",
                                      $$v
                                    )
                                  },
                                  expression: "selectedTab.selectedFields"
                                }
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "search-sticky",
                                    attrs: {
                                      custom: "",
                                      "aria-role": "listitem",
                                      focusable: ""
                                    }
                                  },
                                  [
                                    _c("b-input", {
                                      attrs: {
                                        autofocus: "",
                                        placeholder: "חפש שדה..."
                                      },
                                      model: {
                                        value: _vm.columnField,
                                        callback: function($$v) {
                                          _vm.columnField = $$v
                                        },
                                        expression: "columnField"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._l(_vm.filteredPageList, function(item) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !item.isHidden,
                                          expression: "!item.isHidden"
                                        }
                                      ],
                                      key: item.Id,
                                      attrs: {
                                        paddingless: "",
                                        value: item.Id,
                                        "aria-role": "listitem"
                                      }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "drop-down-field-item",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("input", {
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: _vm.isCheckedColumn(
                                                  item.Id
                                                )
                                              }
                                            }),
                                            _c("span", {
                                              staticClass: "checkmark"
                                            })
                                          ]),
                                          _vm._v(
                                            " " + _vm._s(item.Display) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass:
                                      "search-sticky-bottom filters_actions",
                                    attrs: {
                                      custom: "",
                                      "aria-role": "listitem",
                                      focusable: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-info is-light" },
                                        on: { click: _vm.resetColumns }
                                      },
                                      [_vm._v("איפוס עמודות")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-info" },
                                        on: { click: _vm.saveColumns }
                                      },
                                      [_vm._v("שמור")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.selectedTab.excelExport
                      ? _c(
                          "div",
                          { staticClass: "mr-2" },
                          [
                            _c("b-loading", {
                              attrs: {
                                "is-full-page": true,
                                "can-cancel": false
                              },
                              model: {
                                value: _vm.selectedTab.exportIsLoading,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.selectedTab,
                                    "exportIsLoading",
                                    $$v
                                  )
                                },
                                expression: "selectedTab.exportIsLoading"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              { attrs: { label: "פעולות" } },
                              [
                                _c(
                                  "b-dropdown",
                                  {
                                    staticClass:
                                      "Entity__View__Table__Filters__Actions__Button",
                                    attrs: {
                                      disabled: _vm.selectedTab.exportIsLoading,
                                      "aria-role": "list"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "trigger",
                                          fn: function() {
                                            return [
                                              _c(
                                                "b-button",
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      icon: "ellipsis-v",
                                                      pack: "fas"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      310690421
                                    )
                                  },
                                  _vm._l(_vm.selectedTab.excelExport, function(
                                    action
                                  ) {
                                    return _c(
                                      "b-dropdown-item",
                                      {
                                        key: action.title,
                                        attrs: { "aria-role": "listitem" },
                                        on: {
                                          click: function($event) {
                                            return action.action({
                                              loading: _vm.setExcelLoading,
                                              query: _vm.fullQuery,
                                              getData: _vm.getData,
                                              setFields: _vm.setFields
                                            })
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(action.title) + " ")]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "Entity__View__Table__Filters__Actions is-flex"
                    },
                    [
                      _vm.selectedTab.tableActions
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticClass:
                                    "Entity__View__Table__Filters__Actions__Button",
                                  attrs: {
                                    disabled: !_vm.haveSelectedRows,
                                    "aria-role": "list"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "trigger",
                                        fn: function(ref) {
                                          var active = ref.active
                                          return [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "is-primary",
                                                  "icon-right": active
                                                    ? "menu-up"
                                                    : "menu-down"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " פעולות על שורות מסומנות "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.selectedTab
                                                              .selectedRows
                                                              .length,
                                                          expression:
                                                            "selectedTab.selectedRows.length"
                                                        }
                                                      ]
                                                    },
                                                    [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            _vm.selectedTab
                                                              .selectedRows
                                                              .length
                                                          ) +
                                                          ") "
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    358236199
                                  )
                                },
                                _vm._l(_vm.selectedTab.tableActions, function(
                                  action
                                ) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: action.Id,
                                      attrs: { "aria-role": "listitem" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onTableActionClick(
                                            action,
                                            _vm.selectedTab.selectedRows
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: action.icon }
                                      }),
                                      _vm._v(" " + _vm._s(action.Label) + " ")
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "Entity__View__Table__Filters__Actions__FastSearch",
                          class: { "mr-5": _vm.selectedTab.tableActions }
                        },
                        [
                          _vm.selectedTab.fastSearch
                            ? _c("b-input", {
                                attrs: {
                                  "custom-class": "fast-search-input",
                                  icon: "magnify",
                                  "icon-right": "close-circle",
                                  "icon-right-clickable": "",
                                  "icon-clickable": "",
                                  placeholder:
                                    _vm.selectedTab.fastSearchOptions &&
                                    _vm.selectedTab.fastSearchOptions.label
                                      ? _vm.selectedTab.fastSearchOptions.label
                                      : "חיפוש מהיר..."
                                },
                                on: {
                                  "icon-click": _vm.searchFastIconClick,
                                  "icon-right-click": _vm.clearFastSearch
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.searchFastIconClick($event)
                                  }
                                },
                                model: {
                                  value: _vm.fastSearch,
                                  callback: function($$v) {
                                    _vm.fastSearch = $$v
                                  },
                                  expression: "fastSearch"
                                }
                              })
                            : _vm._e(),
                          _vm.selectedTab.tableFilters
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mr-2 is-flex is-align-items-center"
                                },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: "סינון טבלה לפי פרמטרים נוספים"
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "is-flex is-align-items-center mr-5",
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.showSearchMenu()
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              size: "is-small",
                                              icon: "tune"
                                            }
                                          }),
                                          _vm._v(" מסננים נוספים ")
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm.selectedTab.searched || _vm.queryExists
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "is-flex mr-5",
                                          attrs: {
                                            disabled: _vm.selectedTab.loading,
                                            href: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clearSearch()
                                            }
                                          }
                                        },
                                        [_vm._v("נקה חיפוש")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-table",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.selectedTab.items,
                    loading: _vm.selectedTab.loading,
                    total: _vm.selectedTab.total,
                    checkable: _vm.selectedTab.checkableRows,
                    "checkbox-position": "left",
                    "checked-rows": _vm.selectedTab.selectedRows,
                    "is-row-checkable": function() {
                      return _vm.selectedTab.checkableRows
                    },
                    "backend-sorting": "",
                    "sticky-checkbox": "",
                    "sticky-header": true,
                    "default-sort-direction": _vm.selectedTab.sortOrder,
                    "default-sort": _vm.selectedTab.sortField,
                    "sort-icon": "arrow-down",
                    "row-class": function(row, index) {
                      return _vm.addRowClass(row)
                    },
                    paginated: true,
                    "per-page": _vm.selectedTab.items.length
                  },
                  on: {
                    "update:checkedRows": function($event) {
                      return _vm.$set(_vm.selectedTab, "selectedRows", $event)
                    },
                    "update:checked-rows": function($event) {
                      return _vm.$set(_vm.selectedTab, "selectedRows", $event)
                    },
                    sort: _vm.onSort,
                    click: _vm.onRowClick
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.selectedTab.OriginalAmountSum
                        ? {
                            key: "bottom-left",
                            fn: function() {
                              return [
                                _c("div", { staticClass: "indicators" }, [
                                  _c("div", [
                                    _c("b", [_vm._v("סכום מקור:")]),
                                    _vm._v(
                                      " ₪" +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.selectedTab.OriginalAmountSum
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("div", [
                                    _c("b", [_vm._v("יתרה לתשלום")]),
                                    _vm._v(
                                      " ₪" +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.selectedTab.CurrentAmountSum
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm.haveSelectedRows
                                    ? _c("div", { staticClass: "marked" }, [
                                        _c("b", [_vm._v("סכום מקור:")]),
                                        _vm._v(
                                          " ₪" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.originalAmount
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.haveSelectedRows
                                    ? _c("div", { staticClass: "marked" }, [
                                        _c("b", [_vm._v("יתרה לתשלום")]),
                                        _vm._v(
                                          " ₪" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.currentAmount
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                _vm._l(_vm.selectedFields, function(field) {
                  return _c("b-table-column", {
                    key: _vm.selectedTab.id + "_" + field.Id,
                    attrs: {
                      visible: _vm.isColumnVisible(field),
                      width: field.Width,
                      field: field.Id,
                      label: field.Display,
                      "custom-key": field.Id,
                      centered: field.centeredHeader,
                      sortable: field.Sortable
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(field.Type, {
                                tag: "div",
                                attrs: {
                                  items: _vm.selectedTab.items,
                                  search: function() {
                                    return _vm.getData(_vm.selectedTab)
                                  },
                                  data: props,
                                  item: _vm.mapField(props.row, field.mapItem),
                                  value: _vm.mapField(
                                    props.row[field.Id],
                                    field.map,
                                    props.row
                                  ),
                                  field: field,
                                  align: field.align
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              ),
              !_vm.noPaging
                ? _c(
                    "div",
                    { staticClass: "Entity__View__Table__Pagination" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          total: _vm.selectedTab.total,
                          "range-before": 2,
                          "range-after": 2,
                          order: "is-centered",
                          size: "is-small",
                          "per-page": _vm.selectedTab.perPage,
                          "aria-next-label": "דף הבא",
                          "aria-previous-label": "דף קודם",
                          "aria-page-label": "דף",
                          "aria-current-label": "דף נוכחי"
                        },
                        on: { change: _vm.onPageChange },
                        model: {
                          value: _vm.selectedTab.page,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedTab, "page", $$v)
                          },
                          expression: "selectedTab.page"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "Items__Per__Page" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                position: "is-top-left",
                                "aria-role": "menu"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "Items__Per__Page__Button"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedTab.perPage
                                                ) +
                                                " פריטים בעמוד "
                                            ),
                                            _c(
                                              "span",
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "menu-down" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                237538968
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changePerPage(20)
                                    }
                                  }
                                },
                                [_vm._v("20 פריטים בעמוד")]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changePerPage(50)
                                    }
                                  }
                                },
                                [_vm._v("50 פריטים בעמוד")]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changePerPage(100)
                                    }
                                  }
                                },
                                [_vm._v("100 פריטים בעמוד")]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changePerPage(500)
                                    }
                                  }
                                },
                                [_vm._v("500 פריטים בעמוד")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c(
        "Overlay",
        { attrs: { open: _vm.showNewEntityMenu } },
        [
          _c(
            "SideMenu",
            [
              _c(_vm.selectedTab.newEntityComponent, {
                tag: "component",
                attrs: { isAdminArea: _vm.selectedTab.isAdminArea },
                on: {
                  onSave: _vm.onNewEntitySave,
                  onCancel: _vm.onNewEntityCancel
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Overlay",
        { attrs: { open: _vm.searchMenuVisible } },
        [
          _c("SideMenu", [
            _c("div", { staticClass: "SideMenu__Header" }, [
              _c("h1", [_vm._v("מסננים")]),
              _c(
                "button",
                {
                  staticClass: "SideMenu__Header__Close__Icon",
                  on: {
                    click: function($event) {
                      _vm.searchMenuVisible = false
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "close", size: "is-small" } })],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "SideMenu__Content" },
              _vm._l(_vm.selectedTab.tableFilters, function(filter, index) {
                return _c(
                  "div",
                  {
                    key: _vm.selectedTab.Title + "_" + filter.Id + "_" + index
                  },
                  [
                    !filter.isHidden
                      ? _c(
                          filter.Type,
                          {
                            tag: "component",
                            attrs: {
                              clearable: filter.AllowEmpty,
                              options: filter.Options,
                              isManagedOnly: filter.isManagedOnly,
                              "options-async": filter.OptionsAsync,
                              isTransferOnly: filter.isTransferOnly,
                              filter: filter
                            },
                            model: {
                              value: filter.model,
                              callback: function($$v) {
                                _vm.$set(filter, "model", $$v)
                              },
                              expression: "filter.model"
                            }
                          },
                          [
                            _c(
                              "span",
                              { class: { "is-danger": filter.IsNot } },
                              [_vm._v(_vm._s(filter.Display))]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "SideMenu__Footer" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "" },
                  on: {
                    click: function($event) {
                      _vm.searchMenuVisible = false
                    }
                  }
                },
                [_vm._v(" סגור ")]
              ),
              _c(
                "button",
                {
                  staticClass: "create",
                  attrs: { type: "" },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                },
                [_vm._v("חפש")]
              )
            ])
          ])
        ],
        1
      ),
      _vm.selectedTab.sideViewEntity || _vm.extrasEntity
        ? _c(
            "Overlay",
            {
              attrs: {
                open: _vm.showRowEntity || _vm.showExtrasEntity,
                transparent: true
              }
            },
            [
              _vm.showRowEntity
                ? _c(
                    "SideMenu",
                    { staticClass: "RowEntity", attrs: { width: "500px" } },
                    [
                      _c("div", { staticClass: "SideMenu__Header" }, [
                        _c("h1", [
                          _vm._v(_vm._s(_vm.selectedTab.sideViewEntity.title))
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "SideMenu__Header__Close__Icon",
                            on: { click: _vm.closeRowEntity }
                          },
                          [
                            _c("b-icon", {
                              attrs: { icon: "close", size: "is-small" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm.selectedItem
                        ? _c(
                            "div",
                            { staticClass: "SideMenu__Content Entity-Preview" },
                            [
                              _c(_vm.selectedTab.sideViewEntity.component, {
                                tag: "component",
                                attrs: {
                                  bookLessonId:
                                    _vm.selectedItem.lessonId || null,
                                  preview:
                                    _vm.selectedTab.sideViewEntity.preview,
                                  "read-only":
                                    _vm.selectedTab.sideViewEntity.readOnly,
                                  id: _vm.mapSideViewEntityId(_vm.selectedItem)
                                },
                                on: {
                                  onSave: _vm.onNewEntitySave,
                                  "emit-log": _vm.emitLog
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "SideMenu__Footer" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "close",
                              attrs: { type: "" },
                              on: { click: _vm.closeRowEntity }
                            },
                            [_vm._v(" סגור ")]
                          ),
                          _vm.selectedTab.sideViewEntity.deletable
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    loading: _vm.isDeletionInProgress,
                                    type: "is-danger"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedTab.sideViewEntity.deletable.action(
                                        {
                                          model: _vm.selectedItem,
                                          loading: _vm.setDeletionLoading,
                                          close: _vm.closeRowEntity
                                        }
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" מחק ")]
                              )
                            : _vm._e(),
                          !_vm.selectedTab.sideViewEntity.hideRecord &&
                          _vm.mapSideViewEntityId(_vm.selectedItem)
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "show-entity-btn",
                                  attrs: {
                                    to:
                                      _vm.selectedTab.sideViewEntity
                                        .customRecordUrl ||
                                      "/" +
                                        _vm.selectedTab.sideViewEntity
                                          .recordUrl +
                                        "/" +
                                        _vm.mapSideViewEntityId(
                                          _vm.selectedItem
                                        )
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTab.sideViewEntity
                                        .recordTitle || "הצג רשומה"
                                    )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedTab.sideViewEntity.modal
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "show-entity-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(
                                        _vm.selectedTab.sideViewEntity.modal,
                                        _vm.selectedItem
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTab.sideViewEntity.recordTitle
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.showExtrasEntity
                ? _c(
                    "SideMenu",
                    { staticClass: "RowEntity", attrs: { width: "500px" } },
                    [
                      _c("div", { staticClass: "SideMenu__Header" }, [
                        _c("h1", [_vm._v(_vm._s(_vm.extrasEntity.title))]),
                        _c(
                          "button",
                          {
                            staticClass: "SideMenu__Header__Close__Icon",
                            on: { click: _vm.hideExtrasEntity }
                          },
                          [
                            _c("b-icon", {
                              attrs: { icon: "close", size: "is-small" }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "SideMenu__Content Entity-Preview" },
                        [
                          _c(_vm.extrasEntity.component, {
                            tag: "component",
                            attrs: {
                              preview: _vm.extrasEntity.preview,
                              "read-only": _vm.extrasEntity.readOnly,
                              id: _vm.extrasEntity.Id
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "SideMenu__Footer" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "" },
                              on: { click: _vm.hideExtrasEntity }
                            },
                            [_vm._v("סגור")]
                          ),
                          !_vm.extrasEntity.hideRecord
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "show-record",
                                  attrs: {
                                    to:
                                      _vm.extrasEntity.customRecordUrl ||
                                      "/" +
                                        _vm.extrasEntity.recordUrl +
                                        "/" +
                                        _vm.extrasEntity.Id
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.extrasEntity.recordTitle ||
                                        "הצג רשומה"
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }