<template>
  <div class="LayoutInApp">
    <div v-if="!isExercisePrint" id="nav">
      <div style="display: flex; align-items: center; padding: 0 5px;" class="logo">
        <img src="@/assets/simLogo.png" class="img-responsive" alt="" />
        <div v-if="!isInAdminRoutes" @click="isSideMenu = !isSideMenu" style="cursor: pointer;">
          <b-icon style="color: white;" pack="fas" icon="bars"></b-icon>
        </div>
      </div>
      <div class="links" v-if="user">
        <router-link to="/lessons">יחידות לימוד</router-link>
        <!-- <router-link to="/videos">וידאו</router-link> -->
        <router-link to="/expoChapters">פרקי שאלות חשיפה</router-link>
        <router-link to="/exercises">תרגולים</router-link>
        <router-link to="/courseMessages">לוח הודעות</router-link>
        <!-- <router-link to="/laws">חקיקה</router-link> -->
        <!-- <router-link to="/verdicts">פסיקה</router-link> -->
        <!-- <router-link to="/books">ספרים</router-link> -->
        <router-link to="/users">משתמשים</router-link>
        <router-link to="/reports">דוחות</router-link>
        <router-link to="/requests">מרכז פניות</router-link>
        <router-link to="/groups">קבוצות</router-link>
        <b-dropdown position="is-bottom-left" aria-role="list" animation="">
          <template #trigger>
            <div>
              <a @click.prevent :class="{ 'router-link-active': isRouteActive('/lectures') }">
                ניהול הרצאות
                <b-icon icon="chevron-down" size="is-small" />
              </a>
            </div>
          </template>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/lecturesLearningTracks">מסלולים</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/lecturesRegions">אזורים</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/lecturesHalls">אולמות</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/lecturesTeachers">מרצים</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/lecturesFeedbacksStatements">הגדרות ומשובים</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/lectures">ניהול הוראה וקורסים</router-link>
          </b-dropdown-item>
        </b-dropdown>
        <router-link to="/exerciseAnswers">תשובות לתרגולים</router-link>
      </div>
      <b-dropdown aria-role="list" animation="">

        <template #trigger>
          <div class="profile">
            <div class="avatar" v-if="user">
              <div class="icon" v-if="profileIcon">
                <img :src="profileIcon" class="img-responsive" alt="" />
              </div>
              <span>
                <b-icon icon="chevron-down" />
                {{ user }}
              </span>
            </div>
            <div v-if="!user">
              <span class="is-flex is-align-items-center avatar-placeholder">
                Admin
                <b-icon icon="chevron-down" />
              </span>
            </div>
          </div>
        </template>

        <b-dropdown-item v-if="user" @click="goToProfile" aria-role="listitem">הגדרות</b-dropdown-item>
        <b-dropdown-item v-if="user" @click="signoutProfile" aria-role="listitem">צא
          מקורס</b-dropdown-item>
        <b-dropdown-item @click="signout" aria-role="listitem">יציאה</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="LayoutInApp__Component">
      <router-view />
    </div>
    <div v-if="isSideMenu && !isInAdminRoutes" class="side_menu_container">
      <div class="Side_Menu">
        <transition name="fade">
          <div class="Side_Menu__Menu">
            <div class="Side_Menu_Header">
              <h1>אדמיניסטרציה</h1>
              <div style="color:  black; cursor: pointer;" @click="isSideMenu = false" >
                <b-icon pack="fa" icon="times"></b-icon>
              </div>
            </div>
            <b-menu>
              <b-menu-list>
                <b-menu-item label="ניהול קורסים" tag="router-link" to="/admin/profiles"></b-menu-item>
                <b-menu-item label="ניהול משתמשים" tag="router-link" to="/admin/users"></b-menu-item>
                <b-menu-item label="בנק שאלות" tag="router-link" to="/admin/questionsbank"></b-menu-item>
                <b-menu-item label="בנק וידאו" tag="router-link" to="/admin/videosbank"></b-menu-item>
                <b-menu-item label="בנק ספרים" tag="router-link" to="/admin/booksbank"></b-menu-item>
                <b-menu-item label="קופונים" tag="router-link" to="/admin/coupons"></b-menu-item>
                <b-menu-item>
                  <template #label="props">
                    פעולות
                    <b-icon class="is-pulled-left" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
                  </template>
                  <b-menu-item label="עדכון חוקים חסרים לתרגולים" tag="router-link"
                    to="/admin/action/updateexerciselaws"></b-menu-item>
                  <b-menu-item label="עריכת מקבצי חקיקה" tag="router-link"
                    to="/admin/action/updatelawcollections"></b-menu-item>
                  <b-menu-item label="שכפול יחידה" tag="router-link" to="/admin/action/unitReplication"></b-menu-item>
                  <b-menu-item label="ניהול תקצירי פסיקה" tag="router-link"
                    to="/admin/action/verdictReplication"></b-menu-item>
                  <b-menu-item label="יצירת קובץ תשובות" tag="router-link"
                    to="/admin/action/createanswersfiles"></b-menu-item>
                  <b-menu-item label="עריכת מקבצי פסיקה" tag="router-link"
                    to="/admin/action/verdictscollections"></b-menu-item>
                  <b-menu-item label="ניהול רשימת חוקים" tag="router-link"
                    to="/admin/action/manageLawsCollections"></b-menu-item>
                  <b-menu-item label="עדכון אורכים ותמונות חסרות לוידאו" tag="router-link"
                    to="/admin/action/updateVideoContent"></b-menu-item>
                </b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "LayoutInApp",
  data() {
    return {
      otherProfiles: [],
      isSideMenu: false
    };
  },
  created() {
    this.$store.dispatch("auth/loadStorage");
  },
  computed: {
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isExercisePrint() {
      return this.$route.name == 'ExercisePrint';
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    user() {
      return this.$store.state.auth?.course?.name;
    },
    isInAdminRoutes() {
      return this.$route.name?.includes('Admin');
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated();
    },
    isUserProfile() {
      return this.$store.state.auth.profileId;
    },
    profileIcon() {
      return this.$store.state.profile?.ImageUrl;
    },
    isAdminWithoutProfileId() {
      return !this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    isAdminWithProfileId() {
      return this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True";
    }
  },
  methods: {
    ...mapMutations("auth", ["setProfileId", "setProfile", "setCourse"]),

    userId() {
      return this.$store.state.profile?.Id;
    },
    signoutProfile() {
      this.setCourse();
      window.location.href = this.$router.resolve({ name: "admin" }).href;
    },
    isRouteActive(route) {
      return this.$route.matched.some((r) => r.path === route);
    },
    goToProfile() {
      this.$router.push({ path: "/course" });
    },
    signout() {
      this.$auth.logout().then(() => {
        this.$router.replace({ path: "/login" });
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },
    async loadProfile() {
      await this.$store.dispatch("profile/get");
      if (this.isAdmin) {
        await this.$store.dispatch("profile/getSettings");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.side_menu_container {
  height: 400px;
  width: 300px;
  position: absolute;
  top: 50px;
  z-index: 10;
}

.Side_Menu {
  display: flex;

  .Side_Menu__Menu {
    flex-basis: 300px;
    background-color: #f5f8fa;
    min-height: calc(100vh - 50px);
    padding: 20px;

    .Side_Menu_Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h1 {
      font-size: 25px;
      padding: 5px 10px;
    }

    ::v-deep a {
      color: inherit;
      padding: 10px 10px;
      font-size: 16px;
      width: 100%;
      display: block;
      border-radius: 3px;

      &.router-link-exact-active {
        background-color: #eaf0f6;
        font-weight: 500;
      }

      &:hover {
        background-color: #eaf0f6;
      }
    }
  }
}

::v-deep .menu-list a {
  text-decoration: auto;
}

::v-deep {
  .menu-list a.is-active {
    background: inherit;
  }
}

#nav {
  padding: 0;
  margin: 0;
  height: 50px;
  background-color: #2e3f50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 5;
  top: 0;

  ::v-deep {
    .dropdown:active {
      transform: scale(0.98);
    }
  }

  .logo {
    height: 35px;
    width: 200px;
  }

  .dropdown:hover {
    background-color: #253342;
  }

  .links {
    display: flex;
    width: 100%;
    min-width: 730px;
    justify-content: start;
    height: 100%;
    align-items: center;

    a {
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 100%;

      &:active {
        transform: scale(0.98);
      }

      &:hover {
        background-color: #253342;
      }
    }

    ::v-deep .dropdown {
      padding: 0;

      .dropdown-content {
        width: 100%;
      }

      .dropdown-trigger {
        height: 100%;
        margin: 0;
        display: flex;

        .icon {
          margin-inline-start: 4px;
        }
      }

      .dropdown-item {
        background-color: #2e3f50;
        height: unset;
        padding: 0;
        margin: 0;
        border-radius: 0;

        a {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .dropdown {
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: white;
    height: 100%;
    cursor: pointer;
    position: relative;
    z-index: 2;

    .dropdown-item {
      color: black;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    min-width: 120px;

    .avatar {
      display: flex;
      color: white;
      min-width: 230px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: start;
      font-size: 14px;

      &>span {
        display: flex;
        align-items: center;
      }

      .icon {
        width: 30px;
        height: 25px;
        border-radius: 50%;
        margin-inline-start: 10px;

        span {
          text-align: center;
          display: block;
          width: 100%;
        }
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
    padding: 7px 25px;
    border-radius: 3px;

    &.active {
      // color: white;
      // background-color: #41b883;
      cursor: pointer;
    }

    &.router-link-active {
      // color: #42b983;
      color: #00afd8;
      background-color: #253342;
    }
  }
}

.LayoutInApp__Component {
  height: calc(100vh - 50px);

  &>div {
    height: 100%;
  }
}

.avatar-placeholder {
  .b-skeleton:last-of-type {
    margin: 0;
    margin-right: 10px;
  }
}

@media screen and (max-width: 900px) {
  #nav {
    overflow-x: auto;
    max-width: 900px;
    overflow-y: hidden;

    .profile {
      padding: 0;

      .avatar {
        width: 120px;
        justify-content: center;
      }
    }
  }
}
</style>
