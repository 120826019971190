<template>
    <div style="height: 100%;" class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">ניהול פנייה {{ row.id }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section style="overflow: auto;" class="modal-card-body">
                <template v-if="isLoading">
                    <div class="loading">
                        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
                    </div>
                </template>
                <div class="DialogChat__Component__Loading">
                    <div style="width: 50%;">
                        <div style="display: flex; gap: 10px;">
                            <div><strong>מספר פנייה:</strong> {{ row.id }}</div>
                            <div><strong>תאריך פתיחה:</strong> {{ parseDate(row.createdDate) }}</div>
                            <b-tooltip v-if="studentRemarks.length" position="is-bottom" style="margin-right: auto;" type="is-white" :label="studentRemarks">
                                <b-icon style="color: #a94442;" icon="info-circle" pack="fas"></b-icon>
                            </b-tooltip>
                        </div>
                        <div style="display: flex; gap: 10px;">
                            <div><strong>שם התלמיד:</strong> <a :href="`user/${row.userId}`" target="_blank"
                                    style="text-decoration: underline; color: #0065ff; cursor: pointer;">{{ row.fullname
                                    }}</a></div>
                            <div><strong>טלפון:</strong> {{ row.phone }}</div>
                            <div><strong>מייל:</strong> {{ row.email }}</div>
                            <div v-if="groupsIndication" v-show="groupsIndication.isInEscort || groupsIndication.isInInterval" class="group_indication">
                                <span v-show="groupsIndication.isInEscort" style="border-left: 1px solid black;" class="group_box">ל</span>
                                <span v-show="groupsIndication.isInInterval" class="group_box">א</span>
                            </div>
                        </div>
                        <div style="display: flex; gap: 10px;">
                            <div><strong>כמות פניות במענה המקצועי השבוע:</strong>{{ lastWeekRequestsCount }}</div>
                        </div>
                        <div style="display: flex; gap: 10px; align-items: center; justify-content: space-between;">
                            <div><strong>מקור הפנייה:</strong> {{ row.sentFromPage }}</div>
                            <div style="display: flex; align-items: center; gap: 5px;"><strong>סטטוס:</strong>
                                <FieldInlineSelect class="input_select" label="" :hideHover="true" @onChange="onChange"
                                    :filterable="false" :clearable="false" :options="[{ Value: 1, Text: 'חדשה' },
                                    { Value: 2, Text: 'בטיפול' },
                                    { Value: 3, Text: 'הסתיימה' }]" v-model="row.status" />
                            </div>

                        </div>
                        <div style="display: flex; align-items: center; margin-top: 5px; gap: 10px; justify-content: space-between;">
                            <div style="display: flex; width: 80%; align-items: center;">
                                <strong>בנושא:</strong>
                                <FieldInlineSelect class="input_select" style="width: 80%;" :hideHover="true"
                                    @onChange="onChange" :filterable="false" :clearable="false"
                                    :optionsAsync="getRequestMessageTypes" v-model="row.messagesInCourseTypesId" />
                            </div>
                            <div style="min-width: 109px;"><strong>מספר שאלה: {{ row.questionNumber }}</strong></div>
                        </div>
                        <p>* התלמיד יכול להגיב רק על פנייה בסטטוס בטיפול שהתגובה האחרונה היא של צוות הקורס</p>

                        <div>
                            <div class="responses">
                                <div :class="response.isAdminResponse ? 'admin-response' : 'user-response'"
                                    v-for="(response, idx) in responses" class="response" :key="response.id">
                                    <div class="response-seen">
                                        <b-icon v-if="response.isViewedByStudent" :title="'נצפה'" pack="fa"
                                            icon="eye"></b-icon>
                                    </div>
                                    <div v-if="isEditModal !== idx">
                                        <div v-html="response.text"></div>
                                        <div style="font-size: 14px;">{{ parseDate(response.createdDate) }}</div>
                                    </div>
                                    <textarea v-else type="text" v-model="response.text" name="" id=""> </textarea>
                                    <div class="response-buttons">
                                        <div style="cursor: pointer;" v-if="response.isAdminResponse"
                                            @click="deleteMessage(response.id)">
                                            <b-icon :title="'מחק'" class="delete" pack="fa" icon=""></b-icon>
                                        </div>
                                        <div style="cursor: pointer;" v-if="response.isAdminResponse"
                                            @click="openEditModal(idx)">
                                            <b-icon :title="'ערוך'" class="pen" pack="fa" icon="pen"></b-icon>
                                        </div>
                                        <div style="cursor: pointer; margin-right: auto;" v-if="isEditModal === idx"
                                            @click="editResponse(response.id, response.text)">
                                            <b-icon :title="'ערוך'" class="save" pack="fa" icon="save"></b-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ActivityComment__Component__Box__Content">
                                <!-- <div v-html="newMessage"></div> -->
                                <div style="display: flex; align-items: center; gap: 10px;"
                                    class="ActivityComment__Component__Box__Content__Edit">
                                    <!-- <NewActivityEditor style="width: 90%;" :content="newMessage" ref="editor" :placeholder="'תגובה...'"
                                        animation="''" /> -->
                                    <ckeditor :editor="editor" placeholder="'חומרי עזר'" v-model="newMessage"
                                        :config="editorConfig">
                                    </ckeditor>
                                    <b-button :loading="isLoading" type="is-primary" @click="save">שלח</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 50%;">
                        <div style="display: flex; align-items: center;"><strong>תיוג צוות פניות:</strong>
                            <v-select style="width: 80%;" dir="rtl" append-to-body label="Text"
                                v-model="row.taggedAdmins" :options="adminUsers" :clearable="true" :multiple="true"
                                @input="onChange" :reduce="(item) => item.Value"
                                :getOptionLabel="(item) => `${item.Text}`" :searchable="true" :close-on-select="false"
                                :placeholder="'בחר ערך מהרשימה'">
                                <div slot="no-options">לא נמצאו ערכים דומים</div>
                            </v-select>
                        </div>
                        <div><strong>היסטוריית טיפול:</strong></div>
                        <div style="display: flex;align-items: center;">
                            <FieldInlineTextArea style="flex: 1;" label="כתיבת תגובה פנימית" v-model="newComment" />
                            <b-button @click="sendNewComment">שמור</b-button>
                        </div>
                        <div v-if="requestHistoryModel && requestHistoryModel.length" class="DialogChat__Container">
                            <div class="StudentGroupsWidget__Component" v-for="history in requestHistoryModel"
                                :key="history.id">
                                <p><span style="font-weight: bold;">בתאריך {{ parseDate(history.createDate) }}</span>:
                                    {{ history.byUser
                                    }} {{ history.text }}</p>
                            </div>
                        </div>
                        <b-checkbox style="padding: 10px 0;" @input="onChange" v-model="row.allowedPublication"
                            :true-value="true" :false-value="false">
                            לפרסום
                        </b-checkbox>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <!-- <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" /> -->
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
// import LessonService from "../services/LessonService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
import CoursesService from "../services/CoursesService";
import NewActivityEditor from "./NewActivityEditor.vue";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import UserService from "../services/UserService";
import LessonService from "../services/LessonService";

const {
    ClassicEditor,
    Alignment,
    Autoformat,
    Base64UploadAdapter,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Bookmark,
    CloudServices,
    Code,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Markdown,
    MediaEmbed,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline
} = CKEDITOR;

export default {
    name: "DialogChat",
    props: ["reportNumbers", "reports", "onSuccess", "store", "id", "row"],
    components: {
        NewActivityEditor,
        FieldInlineSelect,
        FieldInlineTextArea,
        vSelect
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
        requestId() {
            return this.row.id || this.store.state.request?.id;
        },
    },
    created() {
        this.loadRequest();
        this.getUserRemarks();
        this.getUserGroupsIndication();
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            responses: null,
            newMessage: '',
            isEditModal: null,
            adminUsers: [],
            requestHistoryModel: null,
            lastWeekRequestsCount: null,
            newComment: null,
            studentRemarks: [],
            groupsIndication: null,
            editor: ClassicEditor,
            editorConfig: {
                language: 'he',
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'link',
                        'insertTable',
                        'highlight',
                        '|',
                        'alignment',
                        '|',
                        'bulletedList',
                        'numberedList',
                        'todoList',
                        'outdent',
                        'indent'
                    ],
                    shouldNotGroupWhenFull: false
                },
                plugins: [
                    Alignment,
                    Autoformat,
                    Base64UploadAdapter,
                    AutoImage,
                    AutoLink,
                    Autosave,
                    BalloonToolbar,
                    BlockQuote,
                    Bold,
                    Bookmark,
                    CloudServices,
                    Code,
                    Essentials,
                    FontBackgroundColor,
                    FontColor,
                    FontFamily,
                    FontSize,
                    GeneralHtmlSupport,
                    Heading,
                    Highlight,
                    HorizontalLine,
                    HtmlComment,
                    HtmlEmbed,
                    ImageBlock,
                    ImageCaption,
                    ImageInline,
                    ImageInsertViaUrl,
                    ImageResize,
                    ImageStyle,
                    ImageTextAlternative,
                    ImageToolbar,
                    ImageUpload,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    LinkImage,
                    List,
                    ListProperties,
                    Markdown,
                    MediaEmbed,
                    Paragraph,
                    PasteFromMarkdownExperimental,
                    PasteFromOffice,
                    RemoveFormat,
                    SpecialCharacters,
                    SpecialCharactersArrows,
                    SpecialCharactersCurrency,
                    SpecialCharactersEssentials,
                    SpecialCharactersLatin,
                    SpecialCharactersMathematical,
                    SpecialCharactersText,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TableToolbar,
                    TextTransformation,
                    TodoList,
                    Underline
                ],
                balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
                fontFamily: {
                    supportAllValues: true
                },
                fontSize: {
                    options: [10, 12, 14, 'default', 18, 20, 22],
                    supportAllValues: true
                },
                heading: {
                    options: [
                        {
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^.*$/,
                            styles: true,
                            attributes: true,
                            classes: true
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'imageStyle:inline',
                        'imageStyle:wrapText',
                        'imageStyle:breakText',
                        '|',
                        'resizeImage'
                    ]
                },
                licenseKey: 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjcxMzkxOTksImp0aSI6IjAzMzQyMjlmLTMxYmUtNGU2MC1hNjgyLTIzOGU5YWE1M2MxMCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCJdLCJ2YyI6IjY5N2VlNDZmIn0.j0Qq4l_jAjCruUz_bUcn9aWq2HwWwi-ffnXv4z6aYIvjG5tapML2zQCf2ArFDo5s9qFnawNwpkE9_rXOa7gjUA',
                link: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    decorators: {
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                menuBar: {
                    isVisible: true
                },
                placeholder: '',
                style: {
                    definitions: [
                        {
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Title',
                            element: 'h2',
                            classes: ['document-title']
                        },
                        {
                            name: 'Subtitle',
                            element: 'h3',
                            classes: ['document-subtitle']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                        {
                            name: 'Side quote',
                            element: 'blockquote',
                            classes: ['side-quote']
                        },
                        {
                            name: 'Marker',
                            element: 'span',
                            classes: ['marker']
                        },
                        {
                            name: 'Spoiler',
                            element: 'span',
                            classes: ['spoiler']
                        },
                        {
                            name: 'Code (dark)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-dark']
                        },
                        {
                            name: 'Code (bright)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-bright']
                        }
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                }
            },
            model: {
                name: null,
                status: 1
            }
        };
    },
    methods: {
        loadRequest() {
            this.getResponses();
            this.getAdminUsers();
            this.getLogs();
            this.getLastWeekCountRequests();
        },
        getUserRemarks() {
            UserService.getUserRemarks(this.courseId, this.row.userId)
                .then((r) => {
                    this.studentRemarks = r.data.map(r => r.contentToDisplay).join(',');
                });
        },
        getUserGroupsIndication() {
            LessonService.getUserGroupsIndication(this.courseId, this.row.userId)
                .then((r) => {
                    this.groupsIndication = r.data;
                });
        },
        onChange() {
            this.isLoading = true;
            CoursesService.updateRequest(this.row.id, this.courseId, {
                ...this.row,
            })
                .then(() => {
                    this.$buefy.toast.open({
                        type: "is-success",
                        message: "נשמר בהצלחה!",
                        duration: 4000,
                    });
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        type: "is-danger",
                        message: "קרתה תקלה, השמירה לא התבצעה!",
                        duration: 8000,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getResponses() {
            this.isLoading = true;
            CoursesService.getRequestResponses(this.requestId, this.courseId)
                .then((r) => {
                    this.responses = r.data.content;
                    this.isLoading = false;
                });
        },
        getAdminUsers() {
            CoursesService.getTeachersByCourse(this.courseId).then((r) => this.adminUsers = r.data.map((i) => ({ Value: i.id, Text: `${i.privateName} ${i.lastName}` }))
            );
        },
        getLogs() {
            CoursesService.getRequestLogs(this.row.id, this.store.state.auth.course.id)
                .then((res) => {
                    this.requestHistoryModel = res.data.content.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
                });
        },
        getLastWeekCountRequests() {
            CoursesService.getLastWeekCountRequests(this.row.userId, this.store.state.auth.course.id)
                .then((res) => {
                    this.lastWeekRequestsCount = res.data;
                });
        },
        getRequestMessageTypes() {
            return () =>
                CoursesService.getRequestMessageTypes(this.store.state.auth.course.id).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
        },
        sendNewComment() {
            this.isLoading = true;
            if (this.newComment) {
                CoursesService.updateRequestHistory(this.row.id, this.store.state.auth.course.id, {
                    Text: this.newComment
                }).then(() => {
                    this.newComment = null;
                    this.loadRequest();
                    Toast.open({
                        type: "is-success",
                        message: "התגובה התווספה בהצלחה!",
                        duration: 8000,
                    });
                    this.isLoading = false;
                })
            }
        },
        deleteMessage(id) {
            this.isLoading = true;
            CoursesService.deleteResponse(this.requestId, this.courseId, id)
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "המחיקה בוצעה!",
                        duration: 4000,
                    });
                    this.getResponses();
                });
        },
        editResponse(id, text) {
            this.isLoading = true;
            CoursesService.editResponse(this.requestId, this.courseId, id, {
                Text: text
            })
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "ההודעה עודכנה בהצלחה!",
                        duration: 4000,
                    });
                    this.getResponses();
                    this.isEditModal = null;
                });
        },
        openEditModal(idx) {
            if (this.isEditModal === idx) this.isEditModal = null;
            else this.isEditModal = idx;
        },
        save() {
            // const body = this.$refs.editor.getHTML();
            // this.newMessage = body;
            if (this.newMessage) {
                this.isLoading = true;
                CoursesService.responseToRequest(this.requestId, this.courseId, {
                    Text: this.newMessage
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        // this.$emit("close");
                        // this.$refs.editor.clearEditor();
                        this.responses = [];
                        this.getResponses();
                        this.newMessage = null;
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.DialogChat__Component__Loading {
    display: flex;
    gap: 15px;
    max-height: 684px;
}

.input_select {
    padding: 0 0 4px 0;
    background: none;
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 4px;
    white-space: normal;
}

.DialogChat__Container {
    height: 355px;
    overflow: auto;
}
</style>

<style scoped lang="scss">
::v-deep .ck.ck-editor {
    width: 100%;
}

.group_box {
    padding: 5px;
}

.group_indication {
    border: 1px solid black;
    margin-right: auto;
    align-items: center;
    display: flex;
}

.responses {
    display: flex;
    flex-direction: column;
    height: 355px;
    overflow: auto;
    margin-bottom: 10px;
}

// ::v-deep .ActivityComment__Component__Box__Content__Edit .ProseMirror {
//     overflow: auto !important;
//     max-height: 100px !important;
// }
.modal-content {
    max-width: 1400px !important;
    margin-left: 400px !important;
    height: 800px !important;

    .modal-card {
        width: 100%;
        height: 100%;
    }
}

.response {
    display: flex;
    flex-direction: column;
}

.user-response {
    position: relative;
    background-color: #9ddaf8;
    border-radius: 0.4em;
    padding: 10px;
    margin-top: 20px;
    width: 95%;
}

.admin-response {
    position: relative;
    background: #cecece;
    border-radius: 0.4em;
    padding: 10px;
    margin-top: 20px;
    width: 95%;
    align-self: end;
}

.response-seen {
    align-self: flex-end;
    gap: 5px;
    display: flex;
}

.response-buttons {
    display: flex;
    gap: 5px;
}

.NewTask__Component__Editor_Inner {
    background-color: #edfaff;
    padding: 0;
    border: 2px solid #2fc9e8;
    border-radius: 3px;
    margin-bottom: 10px;
    ;
}
</style>
