<template>
    <transition name="slideUp">
        <div v-if="open" class="PendingChangesDialog__Component" :class="getDirection">
            <b-button :disabled="isLoading"
                :loading="isLoading" class="save" @click="$emit('onSave')">שמירה</b-button>
            <b-button :disabled="isLoading"
                 class="cancel" @click="$emit('onCancel')">ביטול</b-button>
            <p>שונו {{amount}} שדות</p>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'EntitySaveDialog',
    props: ['direction', 'amount', 'onSave', 'onCancel', 'open', 'isLoading'],
    computed: {
        getDirection() {
            return this.direction ?? 'bottom';
        },
    },
};
</script>

<style lang="scss" scoped>
    .slideUp-enter-active, .slideUp-leave-active {
    transition: all .3s;
    }
    .slideUp-enter, .slideUp-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(50px);
    }
    .slideUp-enter-to {
        transform: translateY(0px);
    }
    .PendingChangesDialog__Component {
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 4;
        background: white;
        display: flex;
        justify-content: start;
        align-items: center;
        background-color: #f5f8fa;
        border-top: 1px solid #cbd6e2;
        padding: 1rem 3.25rem 1rem 140px;

        .save {
            background: #0c2949;
            border-radius: 3px;
            padding: 5px 30px;
            color: white;
            &:hover, &:active {
                background: #00427e;
                color: white;
            }
            margin: 0 10px;
        }
        .cancel {
            background: white;
            border-radius: 3px;
            padding: 5px 30px;
            color: #0c2949;
            border: 1px solid #0c2949;
            &:hover, &:active {
                color: #0c2949;
                background: white;
                border: 1px solid #0c2949;
            }
        }
        p {
            margin-inline-start:  30px;
        }
    }
</style>
