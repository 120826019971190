import { getField, updateField } from 'vuex-map-fields';
import CoursesService from '@/services/CoursesService';

const getDefaultState = function () {
    return {
        address: null,
        capacity: null,
        capacityYellowLine: null,
        count: null,
        createdUserId: null,
        dateCreated: null,
        day: null,
        editorNote: null,
        endTime: null,
        hallName: null,
        id: null,
        instractionsNote: null,
        lecturesHallsId: null,
        lecturesLearningTrackSubjectsId: null,
        lecturesTeachersId: null,
        startTime: null,
        status: null,
        subjectName: null,
        teacherName: null,
        trackName: null,
        userNote: null,
        isWebinarRegistrationRequired: null,
        webinarId: null,
        webinarUrl: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        address: state.address,
        capacity: state.capacity,
        capacityYellowLine: state.capacityYellowLine,
        count: state.count,
        createdUserId: state.createdUserId,
        dateCreated: state.dateCreated,
        day: state.day,
        editorNote: state.editorNote,
        endTime: state.endTime,
        hallName: state.hallName,
        id: state.id,
        instractionsNote: state.instractionsNote,
        lecturesHallsId: state.lecturesHallsId,
        lecturesLearningTrackSubjectsId: state.lecturesLearningTrackSubjectsId,
        lecturesTeachersId: state.lecturesTeachersId,
        startTime: state.startTime,
        status: state.status,
        subjectName: state.subjectName,
        teacherName: state.teacherName,
        trackName: state.trackName,
        userNote: state.userNote,
        isWebinarRegistrationRequired: state.isWebinarRegistrationRequired,
        webinarId: state.webinarId,
        webinarUrl: state.webinarUrl
    }),
};

const mutations = {
    setLecture(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadLecture({ commit }, data) {
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await CoursesService.getCourseLectures(course.id, {
            EntityId: data.id
        });
        commit('setLecture', res.data.items[0]);
        return res.data;
    },
    async loadLectureAdmin({ commit }, data) {
        const res = await CoursesService.getReportAdmin(data.id);
        commit('setLecture', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
