var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      {
        staticStyle: {
          cursor: "pointer",
          "text-align": "center",
          width: "70px"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.previewQuestion($event)
          }
        }
      },
      [
        _vm._t("default", [
          _c("b-icon", { attrs: { pack: "fa", icon: "eye" } })
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }