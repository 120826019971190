<template>
  <div class="" v-if="ready">
    <span v-if="loading" class="loading">
      <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
    </span>
    <div v-if="lectureTrackSubjects && lectureTrackSubjects.length" class="GroupLesson__Container">
      <div class="GroupLessonWidget__Component" v-for="lectureTrackSubject in lectureTrackSubjects"
        :key="lectureTrackSubject.id">
        <p>{{ lectureTrackSubject.name }}</p>
      </div>
    </div>
    <div class="link-btn">
      <a @click="showAll">הצג הכל</a>
    </div>
    <!-- <div v-if="!loading && !lectureTrackSubjects.length">אין נושאים משויכים למסלול</div> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import { ModalProgrammatic as Modal } from "buefy";
import CoursesService from "../../services/CoursesService";

export default {
  name: "GroupEscortsWidget",
  props: ["ready", "namespace", "fetch", "query"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      lectureTrackSubjects: null,
      lectureTrackSubjectsCount: null,
      loading: false
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["id"]),
    };
  },
  created() {
    // if (this.id) {
    //   CoursesService.getUserlectureTrackSubjects(this.id)
    //     .then((res) => {
    //       this.lectureTrackSubjects = res.data;
    //     });
    // }
  },
  computed: {
  },
  methods: {
    showAll() {
      this.$router.push({
        name: "LectureTrackSubjects",
        params: {
          id: this.id,
        },
      });
    },
    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    init() {
      if (this.id) {
        this.loading = true;
        CoursesService.getCourseLecturesLearningTrackSubjects(this.$store.state.auth.course.id, this.id, {
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        })
          .then((res) => {
            this.lectureTrackSubjects = res.data.items;
            this.lectureTrackSubjectsCount = res.data.totalResults;
          }).finally(() => {
            this.$emit('onReady', { titleCount: this.lectureTrackSubjectsCount });
            this.loading = false;
          });
        this.$emit("onReady", true);
      }
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.GroupLesson__Container {
  max-height: 400px;
  padding: 0 10px;
  overflow: auto;
}

.link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.GroupLessonWidget__Component {
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.green {
  background-color: #dff0d8;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
