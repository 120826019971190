var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("הוספת וידאו לבנק")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          [
            _c(
              "b-field",
              { attrs: { label: "הזן שם לוידאו" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.name,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "קטגוריה" } },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    label: "",
                    filterable: true,
                    clearable: true,
                    optionsAsync: _vm.getCategories
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.exerciseCategoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "exerciseCategoryId", $$v)
                    },
                    expression: "model.exerciseCategoryId"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "הזן שם שני לוידאו" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.name2,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name2", $$v)
                    },
                    expression: "model.name2"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "הזן שם שלישי לוידאו" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.name3,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name3", $$v)
                    },
                    expression: "model.name3"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "הזן שם רביעי לוידאו" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.name4,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name4", $$v)
                    },
                    expression: "model.name4"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "לינק ראשון לוידאו" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.link1,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "link1", $$v)
                    },
                    expression: "model.link1"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "לינק שני לוידאו" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.link2,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "link2", $$v)
                    },
                    expression: "model.link2"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "לינק שלישי לוידאו" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.link3,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "link3", $$v)
                    },
                    expression: "model.link3"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "תצוגה" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.thumbnailUrl,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "thumbnailUrl", $$v)
                    },
                    expression: "model.thumbnailUrl"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "HTML" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.htmlContent,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "htmlContent", $$v)
                    },
                    expression: "model.htmlContent"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "משך הוידאו" } },
              [
                _c("b-input", {
                  attrs: { type: "number" },
                  model: {
                    value: _vm.model.durationInSeconds,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "durationInSeconds", $$v)
                    },
                    expression: "model.durationInSeconds"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "אינטרקטיבי" } },
              [
                _c(
                  "b-checkbox",
                  {
                    staticClass: "checkbox",
                    staticStyle: { gap: "10px" },
                    attrs: { label: "", type: "is-link is-light" },
                    model: {
                      value: _vm.model.isInteractiveVideo,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "isInteractiveVideo", $$v)
                      },
                      expression: "model.isInteractiveVideo"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.model.isInteractiveVideo ? " כן" : "לא") +
                        " "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "סטטוס" } },
              [
                _c(
                  "b-checkbox",
                  {
                    staticClass: "checkbox",
                    staticStyle: { gap: "10px" },
                    attrs: { label: "", type: "is-link is-light" },
                    model: {
                      value: _vm.model.status,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "status", $$v)
                      },
                      expression: "model.status"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.model.status ? " פעיל" : "לא פעיל") + " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }