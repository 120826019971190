var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Field__Component",
      staticStyle: { height: "30px" },
      style: { backgroundColor: _vm.setClassByStatus }
    },
    [
      _c(
        "div",
        [
          _vm._t("default", [
            _c("span", {
              staticClass: "dot",
              style: { backgroundColor: _vm.setClassByStatus }
            }),
            _vm._v(_vm._s(_vm.getRequestStatus))
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }