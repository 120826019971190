<template>
  <div class="Profile__Page">
    <transition name="fade">
      <div class="Profile__Page__Menu">
        <h1>אדמיניסטרציה</h1>
        <b-menu>
          <b-menu-list>
            <b-menu-item label="ניהול קורסים" tag="router-link" to="/admin/profiles"></b-menu-item>
            <b-menu-item label="ניהול משתמשים" tag="router-link" to="/admin/users"></b-menu-item>
            <b-menu-item label="בנק שאלות" tag="router-link" to="/admin/questionsbank"></b-menu-item>
            <b-menu-item label="בנק וידאו" tag="router-link" to="/admin/videosbank"></b-menu-item>
            <b-menu-item label="בנק ספרים" tag="router-link" to="/admin/booksbank"></b-menu-item>
            <b-menu-item label="קופונים" tag="router-link" to="/admin/coupons"></b-menu-item>
            <b-menu-item>
              <template #label="props">
                פעולות
                <b-icon class="is-pulled-left" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
              </template>
              <b-menu-item label="עדכון חוקים חסרים לתרגולים" tag="router-link"
                to="/admin/action/updateexerciselaws"></b-menu-item>
              <b-menu-item label="עריכת מקבצי חקיקה" tag="router-link"
                to="/admin/action/updatelawcollections"></b-menu-item>
              <b-menu-item label="שכפול יחידה" tag="router-link" to="/admin/action/unitReplication"></b-menu-item>
              <b-menu-item label="ניהול תקצירי פסיקה" tag="router-link"
                to="/admin/action/verdictReplication"></b-menu-item>
              <b-menu-item label="יצירת קובץ תשובות" tag="router-link"
                to="/admin/action/createanswersfiles"></b-menu-item>
              <b-menu-item label="עריכת מקבצי פסיקה" tag="router-link"
                to="/admin/action/verdictscollections"></b-menu-item>
              <b-menu-item label="ניהול רשימת חוקים" tag="router-link"
                to="/admin/action/manageLawsCollections"></b-menu-item>
              <b-menu-item label="עדכון אורכים ותמונות חסרות לוידאו" tag="router-link"
                to="/admin/action/updateVideoContent"></b-menu-item>
            </b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </transition>
    <div class="Profile__Page__Content">
      <router-view v-if="ready" />
    </div>
  </div>
</template>

<script>
import "vue-select/src/scss/vue-select.scss";
import store from '../store';

export default {
  name: "Admin",
  async created() {
    this.ready = true;
    // if (this.profileId) {
    //   store.commit("auth/setCourse", null);
    //   window.location.href = this.$router.resolve({ name: "admin" }).href;
    // }
  },
  computed: {
    profileId() {
      return this.$store.state.auth.course?.id;
    },
  },
  data() {
    return {
      ready: false
    };
  },
};
</script>
<style lang="scss" scoped>
.Profile__Page {
  display: flex;

  .Profile__Page__Menu {
    flex-basis: 300px;
    background-color: #f5f8fa;
    min-height: calc(100vh - 50px);
    padding: 20px;

    h1 {
      font-size: 25px;
      padding: 5px 10px;
    }

    ::v-deep a {
      color: inherit;
      padding: 10px 10px;
      font-size: 16px;
      width: 100%;
      display: block;
      border-radius: 3px;

      &.router-link-exact-active {
        background-color: #eaf0f6;
        font-weight: 500;
      }

      &:hover {
        background-color: #eaf0f6;
      }
    }
  }

  .Profile__Page__Content {
    flex: 1;
    border-right: 1px solid #c0c0c09e;
    overflow-x: auto;
    width: calc(100% - 300px);
  }
}

::v-deep .menu-list a {
  text-decoration: auto;
}

::v-deep {
  .menu-list a.is-active {
    background: inherit;
  }
}
</style>
