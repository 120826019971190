<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p v-if="studentIds" class="modal-card-title">עריכת תאריך תפוגה לכל התלמידים בקבוצה {{ group.name }}</p>
                <p v-else class="modal-card-title">עריכת תאריך תפוגה ל{{ student.privateName }} בקבוצה {{ group.name }} </p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <FieldInlineDate label="" v-model="studentExpirationDate" />
            </section>
            <footer class="modal-card-foot">
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import LessonService from "../services/LessonService";
import moment from "moment";

export default {
    name: "DialogVideoPreview",
    props: ["studentIds", "student", "group"],
    components: {
        FieldInlineDate
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    created() {
        if (!this.studentIds) {
            this.studentExpirationDate = this.student.groupStudentExpirationDate;
        }
    },
    data() {
        return {
            isLoading: false,
            studentExpirationDate: null,
        };
    },
    methods: {
        save() {
            this.isLoading = true;
            const date = this.studentExpirationDate ? moment(this.studentExpirationDate).format("YYYY-MM-DD") : null;
            const req = this.studentIds ? LessonService.updateGroupStudentsExpirationDate(this.group.id, date, this.studentIds) : LessonService.updateGroupStudentExpirationDate(this.student.id, date, [this.group.id]);
            req
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "הפעולה בוצעה!",
                        duration: 4000,
                    });
                    this.$emit("close");
                    this.$emit('customEvent');
                    // window.location.reload();
                }).catch(() => {
                    Toast.open({
                        type: "is-danger",
                        message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                        duration: 8000,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">
.modal-card {
    // height: 600px;
}
</style>
