<template>
  <div class="Profile__General__Page">
    <div class="Profile__General__Page__Header">
      <h1>הגדרות לקורס {{ course.name }}</h1>
      <div class="loading">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </div>
    </div>
    <b-field style="margin-top: 10px;" label="סטטוס קורס">
      <b-checkbox v-model="model.status" label="" type="is-link is-light" class="checkbox">
        {{ model.status ? ' פעיל' : 'לא פעיל' }}
      </b-checkbox>
    </b-field>
    <div class="Profile__General__Page__Form">
      <b-field label="על הקורס">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.description" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="סילבוס">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.syllabus" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="פתיח">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.openingRemarks" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="קבצים">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.courseFilesContent" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="תנאי שימוש">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.termsOfUser" :config="editorConfig"></ckeditor>
        </div>
      </b-field>
      <b-field label="ניהול משפטי מוטיבציה">
        <b-checkbox v-model="model.showMotivationText">
          הצגת משפטי מוטיבציה בעמוד הבית של הקורס
        </b-checkbox>
      </b-field>
    </div>
    <b-button class="save-btn" type="is-info" @click="updateCourse">
      שמור
    </b-button>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AdminService from "../services/AdminService";

const {
    ClassicEditor,
    Alignment,
    Autoformat,
    Base64UploadAdapter,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Bookmark,
    CloudServices,
    Code,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Markdown,
    MediaEmbed,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline
} = CKEDITOR;

export default {
  name: "ProfileGeneral",
  data() {
    return {
      isLoading: false,
      model: {
        description: '',
        syllabus: '',
        openingRemarks: '',
        courseFilesContent: '',
        termsOfUser: '',
        ShowMotivationText: null
      },
      editor: ClassicEditor,
      editorConfig: {
                language: 'he',
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'link',
                        'insertTable',
                        'highlight',
                        '|',
                        'alignment',
                        '|',
                        'bulletedList',
                        'numberedList',
                        'todoList',
                        'outdent',
                        'indent'
                    ],
                    shouldNotGroupWhenFull: false
                },
                plugins: [
                    Alignment,
                    Autoformat,
                    Base64UploadAdapter,
                    AutoImage,
                    AutoLink,
                    Autosave,
                    BalloonToolbar,
                    BlockQuote,
                    Bold,
                    Bookmark,
                    CloudServices,
                    Code,
                    Essentials,
                    FontBackgroundColor,
                    FontColor,
                    FontFamily,
                    FontSize,
                    GeneralHtmlSupport,
                    Heading,
                    Highlight,
                    HorizontalLine,
                    HtmlComment,
                    HtmlEmbed,
                    ImageBlock,
                    ImageCaption,
                    ImageInline,
                    ImageInsertViaUrl,
                    ImageResize,
                    ImageStyle,
                    ImageTextAlternative,
                    ImageToolbar,
                    ImageUpload,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    LinkImage,
                    List,
                    ListProperties,
                    Markdown,
                    MediaEmbed,
                    Paragraph,
                    PasteFromMarkdownExperimental,
                    PasteFromOffice,
                    RemoveFormat,
                    SpecialCharacters,
                    SpecialCharactersArrows,
                    SpecialCharactersCurrency,
                    SpecialCharactersEssentials,
                    SpecialCharactersLatin,
                    SpecialCharactersMathematical,
                    SpecialCharactersText,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TableToolbar,
                    TextTransformation,
                    TodoList,
                    Underline
                ],
                balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
                fontFamily: {
                    supportAllValues: true
                },
                fontSize: {
                    options: [10, 12, 14, 'default', 18, 20, 22],
                    supportAllValues: true
                },
                heading: {
                    options: [
                        {
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^.*$/,
                            styles: true,
                            attributes: true,
                            classes: true
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'imageStyle:inline',
                        'imageStyle:wrapText',
                        'imageStyle:breakText',
                        '|',
                        'resizeImage'
                    ]
                },
                licenseKey: 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjcxMzkxOTksImp0aSI6IjAzMzQyMjlmLTMxYmUtNGU2MC1hNjgyLTIzOGU5YWE1M2MxMCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCJdLCJ2YyI6IjY5N2VlNDZmIn0.j0Qq4l_jAjCruUz_bUcn9aWq2HwWwi-ffnXv4z6aYIvjG5tapML2zQCf2ArFDo5s9qFnawNwpkE9_rXOa7gjUA',
                link: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    decorators: {
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                menuBar: {
                    isVisible: true
                },
                placeholder: '',
                style: {
                    definitions: [
                        {
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Title',
                            element: 'h2',
                            classes: ['document-title']
                        },
                        {
                            name: 'Subtitle',
                            element: 'h3',
                            classes: ['document-subtitle']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                        {
                            name: 'Side quote',
                            element: 'blockquote',
                            classes: ['side-quote']
                        },
                        {
                            name: 'Marker',
                            element: 'span',
                            classes: ['marker']
                        },
                        {
                            name: 'Spoiler',
                            element: 'span',
                            classes: ['spoiler']
                        },
                        {
                            name: 'Code (dark)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-dark']
                        },
                        {
                            name: 'Code (bright)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-bright']
                        }
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                }
            },
    }
  },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    }
  },
  created() {
    this.isLoading = true;
    AdminService.getCourseSettings(this.course.id)
      .then((r) => {
        this.model = r.data;
        this.isLoading = false;
      });
  },
  methods: {
    updateCourse() {
      this.isLoading = true;
      AdminService.updateCourseGeneralSettings(this.course.id, this.model)
        .then((r) => {
          this.model = r.data;
          Toast.open({
            type: "is-success",
            message: "הפרטים נשמרו בהצלחה!",
            position: "is-top",
            duration: 4000,
          });
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "הפעולה לא התבצעה. יש לנסות שוב.",
            position: "is-bottom",
            duration: 8000,
          });
        }).finally(() => {
          this.isLoading = false;
        });
    },
    save() {
      this.isLoading = true;
      this.updateGeneral(this.model);
      this.saveProfile()
        .then(() => {
          Toast.open({
            type: "is-success",
            message: "הפרטים נשמרו בהצלחה!",
            position: "is-top",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "הפעולה לא התבצעה. יש לנסות שוב.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.item {
  max-height: 400px;
  overflow: auto;
}

.Profile__General__Page {
  padding: 20px 40px;

  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;

    h1 {
      font-size: 30px;
    }
  }

  .Profile__General__Page__Form {
    margin-top: 20px;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    gap: 30px;

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      ::v-deep {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }

        input {
          background-color: #f5f8fa;
          box-shadow: none;
          border: 1px solid #cbd6e2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }
    }

    .Profile__General__Page__Form__Actions {
      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom 0.2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }

        &:active {
          transform: scale(0.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
