<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת הרצאה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="הזן תאריך התחלה">
                        <FieldInlineDateTime label="" position="is-bottom-left" v-model="model.startTime" />
                    </b-field>
                    <b-field label="הזן תאריך סיום">
                        <FieldInlineDateTime label="" position="is-bottom-left" v-model="model.endTime" />
                    </b-field>
                    <b-field label="סטטוס">
                        <b-select v-model="model.status">
                            <option value="1">1</option>
                            <option value="0">0</option>
                        </b-select>
                    </b-field>
                    <b-checkbox @input="onChange" style="margin-top: 10px;" v-model="model.isWebinarRegistrationRequired">
                        נדרשת הרשמה לוובינר
                    </b-checkbox>
                    <b-field label="מזהה לוובינר">
                        <FieldInlineText type="number" v-model="model.webinarId" />
                    </b-field>
                    <b-field label="קישור לוובינר">
                        <FieldInlineText :readOnly="model.isWebinarRegistrationRequired" v-model="model.webinarUrl" />
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import CoursesService from "../services/CoursesService";

export default {
    name: "DialogAddLecture",
    props: ["store", "lectureTrackSubjectId"],
    components: {
        FieldInlineDateTime,
        FieldInlineText
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                status: 1,
                startTime: null,
                endTime: null,
                isWebinarRegistrationRequired: true,
                webinarUrl: null,
                webinarId: null
            }
        };
    },
    methods: {
        save() {
            if (this.model.startTime) {
                this.isLoading = true;
                CoursesService.createLecture(this.lectureTrackSubjectId, {
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() {
            if (this.model.isWebinarRegistrationRequired) {
                this.model.webinarUrl = null;
            }
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    },
};
</script>

<style lang="scss"></style>
