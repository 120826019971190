var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", {}, [
        _vm.loading
          ? _c(
              "span",
              { staticClass: "loading" },
              [
                _c("ClipLoader", {
                  attrs: { color: "#2e3f50", loading: _vm.loading, size: 20 }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c("FieldInlineTextArea", {
              staticStyle: { flex: "1" },
              attrs: { label: "כתיבת תגובה פנימית" },
              model: {
                value: _vm.newComment,
                callback: function($$v) {
                  _vm.newComment = $$v
                },
                expression: "newComment"
              }
            }),
            _c("b-button", { on: { click: _vm.sendNewComment } }, [
              _vm._v("שמור")
            ])
          ],
          1
        ),
        _vm.requestHistoryModel && _vm.requestHistoryModel.length
          ? _c(
              "div",
              { staticClass: "StudentGroups__Container" },
              _vm._l(_vm.requestHistoryModel, function(history) {
                return _c(
                  "div",
                  {
                    key: history.id,
                    staticClass: "StudentGroupsWidget__Component"
                  },
                  [
                    _c("p", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          "בתאריך " + _vm._s(_vm.parseDate(history.createDate))
                        )
                      ]),
                      _vm._v(
                        ": " +
                          _vm._s(history.byUser) +
                          " " +
                          _vm._s(history.text)
                      )
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        !_vm.loading && !_vm.requestHistoryModel.length
          ? _c("div", [_vm._v("אין היסטוריית טיפול לפנייה")])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }