import axios from "axios";

const BASE_URL = process.env.VUE_APP_APIURL;

const getUsers = (data, cancelToken) =>
  axios.post(`${BASE_URL}/api/admin/users/search`, data, {
    cancelToken
  });

const getQuestionsInBank = (data, cancelToken) =>
  axios.post(`${BASE_URL}/api/Admin/QuestionsBank/AdvancedSearch`, data, {
    cancelToken
  });

const getExerciseQuestionsInBank = (data, cancelToken) =>
  axios.post(`${BASE_URL}/api/Admin/QuestionsBank/Exercise/AdvancedSearch`, data, {
    cancelToken
  });

const exportExerciseQuestion = data =>
  axios.post(`${BASE_URL}/api/admin/questionsBank/xls`, data, {
    responseType: "blob"
  });

const updateQuestion = (questionId, data) =>
  axios.put(`${BASE_URL}/api/admin/questionsBank/${questionId}/Content`, data);

const createQuestion = data => axios.post(`${BASE_URL}/api/admin/QuestionsBank/Content`, data);

const duplicateQuestion = questionId =>
  axios.post(`${BASE_URL}/api/admin/QuestionsBank/${questionId}/Duplicate`);

const getQuestionDetails = questionId =>
  axios.get(`${BASE_URL}/api/Admin/QuestionsBank/${questionId}/Content`);

const getQuestionStudentAnswers = questionId =>
  axios.get(`${BASE_URL}/api/Admin/QuestionsBank/${questionId}/StudentAnswers`);

const getVideosInBank = (data, cancelToken) =>
  axios.post(`${BASE_URL}/api/admin/Videos/Search`, data, {
    cancelToken
  });

const getBooksInBank = (data, cancelToken) =>
  axios.post(`${BASE_URL}/api/admin/Books/Search`, data, {
    cancelToken
  });

const addBookInBank = data => axios.post(`${BASE_URL}/api/admin/books`, data);

const updateBookInBank = (bookId, data) => axios.put(`${BASE_URL}/api/admin/books/${bookId}`, data);

const updateVideoInBank = (videoId, data) =>
  axios.put(`${BASE_URL}/api/admin/videos/${videoId}`, data);

const updateQuestionInBank = (questionId, data) =>
  axios.put(`${BASE_URL}/api/admin/questionsbank/${questionId}/details`, data);

const addVideoInBank = data => axios.post(`${BASE_URL}/api/admin/videos`, data);

const getAcademicInstitudes = () =>
  axios.get(`${BASE_URL}/api/admin/questionsbank/academicInstitutions`);

const getAuthors = () => axios.get(`${BASE_URL}/api/admin/questionsbank/authors`);

const getQuestionCategories = () => axios.get(`${BASE_URL}/api/admin/questionsbank/categories`);

const updateExercisesLaws = () => axios.put(`${BASE_URL}/api/admin/Exercises/All/LawText`);

const updateExerciseLaws = exerciseId =>
  axios.put(`${BASE_URL}/api/admin/Exercises/${exerciseId}/LawText`);

const updateVideosDuration = () => axios.put(`${BASE_URL}/api/Admin/Videos/Durations/All`);

const updateVideoDuration = videoId =>
  axios.put(`${BASE_URL}/ api/Admin/Videos/Durations/${videoId}`);

const updateVideosThumbnails = () => axios.put(`${BASE_URL}/api/Admin/Videos/Thumbnails/All`);

const updateVideoThumbnails = videoId =>
  axios.put(`${BASE_URL}/api/Admin/Videos/Thumbnails/${videoId}`);

const getLawCollection = (searchLawId = "") =>
  axios({
    url: `${BASE_URL}/api/lawcollections/${searchLawId}`,
    method: "get"
  });

const getAdminLawCollection = lawId => axios.get(`${BASE_URL}/api/admin/lawsCollection/${lawId}`);

const updateLawCollection = (lawId, data) =>
  axios.put(`${BASE_URL}/api/admin/lawscollection/${lawId}`, data);

const createLawCollection = data => axios.post(`${BASE_URL}/api/lawCollections`, data);

const getLaws = (searchLawId = "") =>
  axios({
    url: `${BASE_URL}/api/laws/${searchLawId}`,
    method: "get"
  });

const getAdminLaws = data => axios.post(`${BASE_URL}/api/admin/lawscollection/searchAll`, data);

const updateLaw = (lawId, data) => axios.put(`${BASE_URL}/api/laws/${lawId}`, data);

const createLaw = data => axios.post(`${BASE_URL}/api/laws`, data);

const getVerdictSummaries = (searchVerdictId = "") =>
  axios({
    url: `${BASE_URL}/api/verdictsummaries/${searchVerdictId}`,
    method: "get"
  });

const getAdminVerdictSummaries = data =>
  axios.post(`${BASE_URL}/api/Admin/VerdictsCollection/Summaries/SearchAll`, data);

const updateVerdictSummary = (verdictId, data) =>
  axios.put(`${BASE_URL}/api/verdictsummaries/${verdictId}`, data);

const createVerdictSummary = data => axios.post(`${BASE_URL}/api/verdictsummaries`, data);

const updateVerdictCollection = (verdictId, data) =>
  axios.put(`${BASE_URL}/api/verdictcollections/${verdictId}`, data);

const getVerdictCollections = (searchVerdictId = "") =>
  axios({
    url: `${BASE_URL}/api/verdictcollections/${searchVerdictId}`,
    method: "get"
  });

const getAdminVerdictCollections = data =>
  axios.post(`${BASE_URL}/api/Admin/VerdictsCollection/SearchAll`, data);

const createVerdictCollection = data => axios.post(`${BASE_URL}/api/verdictcollections`, data);

const getLawClauses = lawId => axios.get(`${BASE_URL}/api/Laws/${lawId}/Clauses`);

const updateLawClauses = lawId =>
  axios.put(`${BASE_URL}/api/admin/Laws/${lawId}/ClauseDynamicIds?createNew=true`);

const getCourseSettings = courseId =>
  axios.get(`${BASE_URL}/api/admin/courses/${courseId}/GeneralSettings`);

const updateCourseGeneralSettings = (courseId, data) =>
  axios.put(`${BASE_URL}/api/admin/courses/${courseId}`, data);

const getAdminCourseRequest = courseId =>
  axios.get(`${BASE_URL}/api/admin/courses/${courseId}/messageTypes`);

const getAdminCourseCalendar = courseId =>
  axios.get(`${BASE_URL}/api/admin/courses/${courseId}/calendar`);

const getAdminCourseMessages = (courseId, data) =>
  axios.post(`${BASE_URL}/api/admin/courses/${courseId}/CourseMessage`, data);

const editCourseMessage = (courseId, courseMessageId, data) =>
  axios.put(`${BASE_URL}/api/admin/courses/${courseId}/courseMessages/${courseMessageId}`, data);

const addCourseMessage = (courseId, data) =>
  axios.post(`${BASE_URL}/api/admin/courses/${courseId}/courseMessages`, data);

const getCourseNavigation = courseId =>
  axios.get(`${BASE_URL}/api/admin/courses/${courseId}/navigation`);

const changeCourseNavigation = (courseId, data) =>
  axios.put(`${BASE_URL}/api/admin/courses/${courseId}/navigation`, data);

const editCourseMessageType = (courseId, courseMessageTypeId, data) =>
  axios.put(
    `${BASE_URL}/api/admin/courses/${courseId}/courseMessageTypes/${courseMessageTypeId}`,
    data
  );

const addCourseMessageType = (courseId, data) =>
  axios.post(`${BASE_URL}/api/admin/courses/${courseId}/courseMessageTypes`, data);

const duplicateCourse = (courseId, data) => axios.post(`${BASE_URL}/api/admin/courses/${courseId}/duplicateCourse`, data);

const changeCourseName = (courseId, data) => axios.post(`${BASE_URL}/api/admin/courses/${courseId}/changeName`, data);

const getVerdictQuotes = verdictId =>
  axios.get(`${BASE_URL}/api/quotes/documents/${verdictId}/relatedDocuments`);

const getVerdictFile = documentId =>
  axios.get(`${BASE_URL}/api/quotes/Documents/${documentId}/Html`);

const getVerdictFiles = (quoterDocId, quotedDocId) =>
  axios.get(`${BASE_URL}/api/quotes/Documents/${quoterDocId}/Quoted/${quotedDocId}/Html`);

const hashQuotes = (data) => axios.post(`${BASE_URL}/api/quotes/Hash`, data);

const deleteQuote = (quoteId) => axios.delete(`${BASE_URL}/api/quotes/${quoteId}/disconnect`);

const getAdminCoupons = data => axios.post(`${BASE_URL}/api/admin/AcademyCoupons/Search`, data);

const updateCoupon = (couponId, data) =>
  axios.put(`${BASE_URL}/api/admin/AcademyCoupons/${couponId}`, data);

const createCoupon = data => axios.post(`${BASE_URL}/api/admin/AcademyCoupons`, data);

const getAnswersFile = (courseId) => axios.get(`${BASE_URL}/api/admin/answers/${courseId}`);

const downloadAnswersFile = (data) => axios.post(`${BASE_URL}/api/admin/answers/download`, data, {
  responseType: 'blob',
  'Content-Type': 'application/json; charset=utf-8',
});

const duplicateCourseMessage = (courseId, data) => axios.post(`${BASE_URL}/api/admin/courses/${courseId}/courseMessages/duplicate`, data);

export default {
  getUsers,
  getQuestionsInBank,
  getExerciseQuestionsInBank,
  exportExerciseQuestion,
  updateQuestion,
  createQuestion,
  duplicateQuestion,
  getQuestionDetails,
  getQuestionStudentAnswers,
  getVideosInBank,
  getBooksInBank,
  updateVideoInBank,
  updateBookInBank,
  updateQuestionInBank,
  addVideoInBank,
  addBookInBank,
  getAcademicInstitudes,
  getAuthors,
  getQuestionCategories,
  updateExercisesLaws,
  updateExerciseLaws,
  getLawCollection,
  getAdminLawCollection,
  updateLawCollection,
  createLawCollection,
  getLaws,
  getAdminLaws,
  updateLaw,
  createLaw,
  getVerdictSummaries,
  getAdminVerdictSummaries,
  updateVerdictSummary,
  createVerdictSummary,
  updateVerdictCollection,
  getVerdictCollections,
  getAdminVerdictCollections,
  createVerdictCollection,
  updateVideosDuration,
  updateVideoDuration,
  updateVideosThumbnails,
  updateVideoThumbnails,
  getLawClauses,
  updateLawClauses,
  getCourseSettings,
  updateCourseGeneralSettings,
  getAdminCourseRequest,
  getAdminCourseCalendar,
  getAdminCourseMessages,
  editCourseMessage,
  addCourseMessage,
  getCourseNavigation,
  changeCourseNavigation,
  editCourseMessageType,
  addCourseMessageType,
  duplicateCourse,
  changeCourseName,
  getVerdictQuotes,
  getVerdictFile,
  getVerdictFiles,
  hashQuotes,
  deleteQuote,
  getAdminCoupons,
  updateCoupon,
  createCoupon,
  getAnswersFile,
  downloadAnswersFile,
  duplicateCourseMessage
};
